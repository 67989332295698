.b-bg-video {
	position: absolute;
	top: 0;
	left: 0;

	opacity: .1;

	width: 100%;
	height: 100%;

	object-fit: cover; }

/*----------- images -----------*/

$sprite:							url( ../../media/img/sprite.png ) !default;
$sprite_x2:							url( ../../media/img/sprite_x2.png ) !default;
$sprite-size:						100px 100px;

/*----------- images (end) -----------*/





/*----------- fonts -----------*/

$font-path:							"../fonts" !default;

/*----------- fonts (end) -----------*/





/*----------- usage -----------*/

$_use_buttons:						false;
$_use_common:						true;
$_use_forms:						false;
$_use_root:							true;
$_use_tables:						false;
$_use_typography:					false;

$_use_container:					true;
$_use_float:						true;
$_use_grid:							true;
$_use_modifiers:					true;

/*----------- usage (end) -----------*/





/*----------- typography -----------*/

$font-family:						'Montserrat', sans-serif;
$font-family-base:					$font-family;

$font-size-xxxl:					3.6rem;
$font-size-xxl:						3rem;
$font-size-xl:						2.4rem;
$font-size-lg:						2rem;
$font-size-xm:						1.8rem;
$font-size-md:						1.6rem;
$font-size-sm:						1.4rem;
$font-size-xs:						1.3rem;
$font-size-xxs:						1.2rem;
$font-size-xxxs:					1rem;
$font-size-base:					$font-size-md;

$font-size-h1:						3.6rem;
$font-size-h2:						3rem;
$font-size-h3:						2.4rem;
$font-size-h4:						1.8rem;
$font-size-h5:						$font-size-base;
$font-size-h6:						1.2rem;

$font-size-body:					$font-size-base;
$font-size-main:					10px;

$l_s:								.06em;

$mb-h1:								20px;
$mb-h2:								15px;
$mb-h3:								10px;
$mb-h4:								7px;
$mb-h5:								5px;
$mb-h6:								5px;

$line-height-base:					1.35;
$line-height-medium:				1.5;
$line-height-computed:				floor( $font-size-base * $line-height-base ); // ~20px

$headings-font-family:				inherit;
$font-weight-base:					400;
$font-weight-medium:				500;
$headings-font-weight:				$font-weight-base;
$headings-line-height:				$line-height-base;
$headings-color:					inherit;

/*----------- typography (end) -----------*/





/*----------- common -----------*/

$n-col:								12;
$p_horiz-col:						15px;
$p_horiz-col_tb:					10px;
$v_align-col:						top;

$w-min:								320px;
$h-min:								100vh;
$img:								'../../media/img';

$m-col:								$p_horiz-col * 2;
$m-col_tb:							$p_horiz-col_tb * 2;

$brd_w:								1px;

$w-container:						1230px;
$w-container_tb:					100%;
$w-container_full_w:				100%;



$p-b-modal__box-wrapper:			40px;
$anim_dur-b-modal:					.2s;

$m_vert-b-box:						60px;
$m_vert-b-box_tb:					40px;

$p_vert-b-box:						50px;
$p_vert-b-box_tb:					50px;
$p_vert-b-box_mb:					50px;

$p_vert-b-box_md:					50px;

$p_horiz-b-box:						40px;
$p_horiz-b-box_tb:					30px;
$p_horiz-b-box_mb:					30px;

$brd_rd-b-box:						30px;

$w-b-box_xl:						1460px;
$w-b-box_md:						570px;
$w-b-box_sm:						445px;



$anim_dur-b-modal:					.2s;

$z_i-b-header:						9;

$z_i-b-modal:						$z_i-b-header + 1;
$z_i-b-modal__box:					1;

/*----------- common (end) -----------*/





/*----------- media -----------*/

$md-desk_md:						1440px;
$md-desk_sm:						1250px;
$md-tablet:							$w-container;
$md-mobile:							640px;
$md-mobile_sm:						320px;

$md-b-box_xl:						$w-b-box_xl;
$md-b-box_md:						$w-b-box_md;
$md-b-box_sm:						$w-b-box_sm;

/*----------- media (end) -----------*/





/*----------- link -----------*/

$brd_w-link:						1px;

/*----------- link (end) -----------*/





/*----------- form -----------*/

$m_b-label:							20px;
$f_sz-label:						$font-size-base;
$f_w-label:							700;

$h-input:							56px;
$h-textarea:						110px;

$brd_w-input:						0;
$brd_rd-input:						7px;

$p_vert-input:						7px;
$p_horiz-input:						20px - $brd_w-input;

$f_sz-input:						14px;
$l_s-input:							.06em;

$w-checkbox:						14px;
$h-checkbox:						$w-checkbox;
$brd_w-checkbox:					1px;
$brd_w-checkbox_checked:			5px;
$brd_rd-checkbox:					2px;

$w-radio:							14px;
$h-radio:							$w-radio;
$brd_w-radio:						1px;
$brd_w-radio_checked:				5px;
$brd_rd-radio:						50%;

$m-row-input:						20px;
$m-row-input-mb:					$m-row-input;
$m-form-body:						30px;

/*----------- form (end) -----------*/






/*----------- table -----------*/

$p_vert-td:							10px;
$p_horiz-td:						10px;

/*----------- table (end) -----------*/






/*----------- button -----------*/

$w-btn:								auto;
$w-btn_lg:							auto;
$w-btn_sm:							auto;
$w-btn_xs:							auto;



$h-btn:								auto;
$h-btn_wanted:						56px;
$h-btn_lg:							auto;
$h-btn_lg_wanted:					64px;
$h-btn_sm:							auto;
$h-btn_sm_wanted:					52px;
$h-btn_xs:							auto;
$h-btn_xs_wanted:					36px;



$brd_w-btn:							0;
$brd_w-btn_lg:						0;
$brd_w-btn_sm:						0;
$brd_w-btn_xs:						0;



$brd_rd-btn:						10px;
$brd_rd-btn_lg:						10px;
$brd_rd-btn_sm:						10px;
$brd_rd-btn_xs:						10px;



$f_sz-btn:							15px;
$f_sz-btn_lg:						16px;
$f_sz-btn_sm:						14px;
$f_sz-btn_xs:						13px;



$f_w-btn:							700;
$l_s-btn:							.04em;



$lh-btn:							round( $f_sz-btn * 1.5 );
$lh-btn_lg:							round( $f_sz-btn_lg * 1.5 );
$lh-btn_sm:							round( $f_sz-btn_sm * 1.5 );
$lh-btn_xs:							round( $f_sz-btn_xs * 1.5 );



$p_vert-btn:						( $h-btn_wanted - $lh-btn - $brd_w-btn * 2 )/2;
$p_horiz-btn:						25px;

$p_vert-btn_lg:						( $h-btn_lg_wanted - $lh-btn_lg - $brd_w-btn_lg * 2 )/2;
$p_horiz-btn_lg:					30px;

$p_vert-btn_sm:						( $h-btn_sm_wanted - $lh-btn_sm - $brd_w-btn_sm * 2 )/2;
$p_horiz-btn_sm:					20px;

$p_vert-btn_xs:						( $h-btn_xs_wanted - $lh-btn_xs - $brd_w-btn_xs * 2 )/2;
$p_horiz-btn_xs:					15px;



$per-btn_hover:						5%;
$per-btn_active:					5%;

/*----------- button (end) -----------*/

/*----------- main -----------*/

$black:							#000;
$grey-base:						$black;
$grey-darker:					#1A2133; // 15%
$grey-dark:						lighten( $grey-base, 20% ); // #333
$grey:							lighten( $grey-base, 33.5% ); // #555
$grey-light:					#838CA3; // 58%
$grey-lighter:					lighten( $grey-base, 90% ); // #eee
$grey-lightest:					#F3F5FB; // 97%
$white:							#fff;

$brand-primary:					#415DDD; // blue
$brand-success:					#58c067; // green
$brand-info:					#69c1df; // cyan
$brand-warning:					#f5bf53; // yellow
$brand-danger:					#ed6161; // red

/*----------- main (end) -----------*/





/*----------- typography -----------*/

$c-text-base:					$black;
$c-h:							$black;

/*----------- typography (end) -----------*/





/*----------- common -----------*/

$bg_c-body:						$white;
$brd_c:							$grey-lighter;

$b_sh_c:						#0A2466;

$bg_overlay:					rgba( $grey-darker, .6 );

/*----------- common (end) -----------*/





/*----------- link -----------*/

$c-link:							$brand-primary;
$brd_c-link:						transparent;
$c-link_hover:						darken( $c-link, 10% );
$brd_c-link_hover:					$c-link_hover;

/*----------- link (end) -----------*/





/*----------- form -----------*/

$c-form-error: 						$brand-danger;
$c-label: 							$c-text-base;

$bg-input: 							rgba( #2A43B2, .55 );
$brd_c-input: 						transparent;

$c-input: 							$white;
$c-input_disabled:					rgba( $white, .7 );

$c-placeholder:						$white;

$brd_c-form-error:					transparent;

$bg-checkbox: 						$white;
$brd_c-checkbox: 					$brd_c;
$bg-checkbox_checked:				$white;
$brd_c-checkbox_checked:			$brand-primary;

$bg-radio: 							$white;
$brd_c-radio: 						$grey-lighter;
$bg-radio_checked:					$white;
$brd_c-radio_checked:				$brand-primary;

/*----------- form (end) -----------*/





/*----------- table -----------*/

$table_even:						darken( $white, 5% );
$table_odd:							darken( $white, 5% );

$table_hr:							darken( $white, 8% );

/*----------- table (end) -----------*/





/*----------- button -----------*/

$c-btn-default:						$brand-primary;
$c-btn-filled:						$white;
$c-btn-disabled:					$white;
$c-btn-outline:						$brand-primary;

$c-btn-link:						$c-link;
$c-btn-link_hover:					$c-btn-link;

$bg_c-btn-default:					$white;
$bg_c-btn-primary:					$brand-primary;
$bg_c-btn-success:					$brand-success;
$bg_c-btn-info:						$brand-info;
$bg_c-btn-warning:					$brand-warning;
$bg_c-btn-danger:					$brand-danger;
$bg_c-btn-disabled:					darken( $white, 20% );
$bg_c-btn-outline:					transparent;

$brd-c-btn-link:					transparent;
$brd-c-btn-link_hover:				fadeout( $c-btn-link, 20% );

/*----------- button (end) -----------*/

.b-testimonials {
	position: relative; // for &::before and &::after

	display: flex; // to collapse margins inside
	flex-direction: column;

	margin-top: -130px;
	margin-bottom: 240px;
	padding-top: 130px; // for the anchor

	@include md-desk_sm {
		margin-bottom: 110px; }

	@include md-mobile {
		margin-bottom: 70px; }

	&::before,
	&::after {
		content: '';
		position: absolute;
		pointer-events: none; }

	&::before {
		top: -30px;
		left: 50%;

		width: 100%;
		min-width: 1600px;
		height: 316px;

		background: url( '#{$img}/b-testimonials/bg-min.png' ) center / 100% 100% no-repeat;

		transform: translateX( -50% );

		@include md-retina {
			background-image: url( '#{$img}/b-testimonials/bg@2x-min.png' ); } }

	&::after {
		top: 76px;
		left: calc( 50% - 763px );

		width: 1311px;
		height: 647px;

		background: url( '#{$img}/b-testimonials/bg_2-min.png' ) center / cover no-repeat;

		@include md-retina {
			background-image: url( '#{$img}/b-testimonials/bg_2@2x-min.png' ); } }

	&__box {
		position: relative; // for &::before

		display: flex;
		flex-direction: column;

		width: 100%;
		max-width: 440px;
		min-height: 100%;

		border-radius: 30px;
		padding: 30px 30px 20px;

		background: $white;

		@include md-mobile {
			border-radius: 20px;
			padding: 20px;
			text-align: center; }

		&::before {
			content: '';

			position: absolute;
			left: 30px;
			bottom: 0;
			z-index: -1;

			width: calc( 100% - 60px );
			height: calc( 100% - 30px );

			border-radius: 30px;

			background: $white;
			box-shadow: 0 20px 80px rgba( $b_sh_c, .12 ); } }

	&__box-main {
		flex-grow: 1; }

	&__box-photo {
		width: 100px;
		height: 100px;

		border-radius: 50%; }

	&__box-heading {
		margin: -5px 0 -6px;

		color: $grey-darker;
		font-size: 16px;
		line-height: 22px;
		font-weight: 600;
		letter-spacing: .04em; }

	&__box-subheading {
		margin: -6px 0;

		color: $grey-light;
		font-size: 14px;
		line-height: 22px;
		font-weight: 400;
		font-style: italic;
		letter-spacing: .02em; }

	&__box-text {
		margin: -8px 0;

		color: $grey-light;
		font-size: 13px;
		line-height: 25px;
		font-weight: 400; }

	&__box-minor-note {
		margin: -6px 0;

		opacity: .5;

		color: $grey-light;
		font-size: 14px;
		line-height: 22px;
		font-weight: 600;
		font-style: italic;
		text-align: right;
		letter-spacing: .02em;

		@include md-mobile {
			text-align: center; } }

	&__box-logo {
		display: flex;
		align-items: center;
		justify-content: flex-end;

		min-width: 26px;

		@include md-mobile {
			justify-content: center; } } }

.b-machine-learning {
	position: relative; // for &::before

	display: flex; // to collapse margins inside
	flex-direction: column;

	margin-bottom: 260px;

	text-align: right;

	@include md-desk_sm {
		flex-direction: column-reverse;
		margin-bottom: 110px; }

	@include md-mobile {
		margin-bottom: 70px; }

	&::before {
		content: '';

		position: absolute;
		top: 28px;
		left: calc( 50% - 716px );

		width: 1421px;
		height: 336px;

		background: url( '#{$img}/b-machine-learning/bg-min.png' ) center / cover no-repeat;

		pointer-events: none;

		@include md-retina {
			background-image: url( '#{$img}/b-machine-learning/bg@2x-min.png' ); }

		@include md-desk_sm {
			display: none; } }

	&__device {
		content: '';

		position: absolute;
		z-index: 1; // to be over &&::before and &&::after

		top: calc( 50% - 229px );
		left: calc( 50% - 595px );

		width: 630px;
		height: 481px;

		object-fit: cover;

		@include md-desk_sm {
			position: relative;
			top: auto;
			left: auto;

			max-width: 90%;
			height: auto;

			margin: -5px auto -15px; }

		@include md-mobile {
			margin: 0 auto; } }

	&__content {
		position: relative;
		z-index: 1; // to be over &&::before and &&::after

		display: flex; // to collapse margins inside
		flex-direction: column;

		max-width: 470px;

		margin-left: auto;

		@include md-desk_sm {
			max-width: 570px;
			margin: 0 auto 50px;
			text-align: center; } } }

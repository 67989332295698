.b-services {
	position: relative; // for &::before and &::after

	display: flex; // to collapse margins inside
	flex-direction: column;

	margin-top: -130px;
	margin-bottom: 180px;
	padding-top: 130px; // for the anchor

	@include md-desk_sm {
		margin-bottom: 60px; }

	@include md-mobile {
		margin-bottom: 20px; }

	&_last {
		margin-bottom: 0; }

	&::before,
	&::after {
		content: '';
		position: absolute;
		pointer-events: none; }

	&::before {
		top: 375px;
		left: 50%;

		width: 100%;
		min-width: 1600px;
		height: 422px;

		background: url( '#{$img}/b-services/bg-min.png' ) center / 100% 100% no-repeat;

		transform: translateX( -50% );

		@include md-retina {
			background-image: url( '#{$img}/b-services/bg@2x-min.png' ); } }

	&::after {
		top: 99px;
		left: calc( 50% - 657px );

		width: 1334px;
		height: 559px;

		background: url( '#{$img}/b-services/bg_2-min.png' ) center / cover no-repeat;

		@include md-retina {
			background-image: url( '#{$img}/b-services/bg_2@2x-min.png' ); } }

	&__content {
		position: relative;
		z-index: 1; } // to be over &&::before and &&::after

	&__heading {
		position: relative; // for &::before

		display: flex; // to collapse margins inside
		flex-direction: column;

		margin-bottom: 80px;

		text-align: center;

		&::before {
			content: '';

			position: absolute;
			top: -150px;
			right: 0;

			width: 180px;
			height: 360px;

			background: url( '#{$img}/b-services/bg.png' ) center / contain no-repeat;

			@include md-retina {
				background-image: url( '#{$img}/b-services/bg@2x.png' ); } } }

	&__list {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;

		margin: -$p_horiz-col;

		@include md-desk_sm {
			margin: -$p_horiz-col_tb; } }

	&__list-item {
		padding: $p_horiz-col;

		@include md-desk_sm {
			padding: $p_horiz-col_tb; }

		@include md-mobile {
			max-width: 100%; } }

	&__box {
		position: relative; // for &::before

		width: 470px;
		max-width: 100%;
		min-height: 100%;

		border-radius: 40px;
		padding: 45px 20px 40px 70px;

		background: $white;

		@include md-mobile {
			padding-left: 20px; }

		&::before,
		&::after {
			content: '';
			position: absolute; }

		&::before {
			left: 50%;
			bottom: 0;
			z-index: -1;

			width: calc( 100% - 50px );
			height: 250px;

			border-radius: 40px;

			background: $white;
			box-shadow: 0 40px 70px rgba( $b_sh_c, .07 );

			transform: translateX( -50% ); }

		&::after {
			left: 38px;
			top: 27px;

			width: 120px;
			height: 135px;

			background: top right / contain no-repeat;

			@include md-mobile {
				left: -12px; } }

		&_team::after {
			background-image: url( '#{$img}/b-services/icon_1.png' );

			@include md-retina {
				background-image: url( '#{$img}/b-services/icon_1@2x.png' ); } }

		&_design::after {
			background-image: url( '#{$img}/b-services/icon_2.png' );

			@include md-retina {
				background-image: url( '#{$img}/b-services/icon_2@2x.png' ); } }

		&_development::after {
			background-image: url( '#{$img}/b-services/icon_3.png' );

			@include md-retina {
				background-image: url( '#{$img}/b-services/icon_3@2x.png' ); } }

		&_company::after {
			background-image: url( '#{$img}/b-services/icon_4.png' );

			@include md-retina {
				background-image: url( '#{$img}/b-services/icon_4@2x.png' ); } }

		&_growth::after {
			background-image: url( '#{$img}/b-services/icon_5.png' );

			@include md-retina {
				background-image: url( '#{$img}/b-services/icon_5@2x.png' ); } }

		&_applications::after {
			background-image: url( '#{$img}/b-services/icon_6.png' );

			@include md-retina {
				background-image: url( '#{$img}/b-services/icon_6@2x.png' ); } } }

	&__box-heading {
		position: relative; // for &::before
		display: flex; // to collapse margins inside

		flex-direction: column;

		margin-bottom: 40px;
		padding-left: 90px;

		&_last {
			margin-bottom: 0; } }

	&__box-icon {
		position: absolute;
		top: 12px;
		left: 10px;

		width: 35px;
		height: 35px;

		border-radius: 50%;

		background-color: $white;

		&::before {
			content: '';

			position: absolute;
			left: -10px;
			bottom: 0;

			width: 55px;
			height: 55px;

			border-radius: 50%;

			background: $white;
			box-shadow: 0px 40px 60px rgba( $b_sh_c, .25 ); }

		&::after {
			position: absolute;
			color: $grey-darker; }

		&_team {

			@extend .i-team;

			&::after {
				top: -1px;
				left: 5px;
				font-size: 17px; } }

		&_design {

			@extend .i-design;

			&::after {
				top: -6px;
				left: 4px;
				font-size: 27px; } }

		&_development {

			@extend .i-code-2;

			&::after {
				top: 0px;
				left: 4px;
				font-size: 15px; } }

		&_company {

			@extend .i-company;

			&::after {
				top: -5px;
				left: 9px;
				font-size: 23px; } }

		&_growth {

			@extend .i-growth;

			&::after {
				top: -3px;
				left: 7px;
				font-size: 21px; } }

		&_applications {

			@extend .i-application;

			&::after {
				top: -3px;
				left: 7px;
				font-size: 21px; } } }

	&__box-heading-text {
		margin: -8px 0 -9px;

		color: $grey-darker;
		font-size: 16px;
		line-height: 28px;
		font-weight: 600;
		letter-spacing: .04em; }

	&__box-text {
		margin: -12px 0;

		color: $grey-darker;
		font-size: 15px;
		line-height: 35px;
		letter-spacing: .04em; } }

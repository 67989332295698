._z_i_2 {
	z-index: 2; }


._op_0 {
	opacity: 0; }



._flex_stretch {
	flex-grow: 1 !important; }

._flex_shrink_none {
	flex-shrink: 0 !important; }

._flex_dir_row_reverse {
	flex-direction: row-reverse !important; }

._flex_dir_just_end {
	justify-content: flex-end !important; }

._f_col {
	flex-direction: column !important; }

._f_col_mb {

	@include md-mobile {
		flex-direction: column !important; } }

._f_wrap {
	flex-wrap: wrap !important; }

._align_center {
	align-items: center !important; }

._align_baseline {
	align-items: baseline !important; }

._just_cont_end {
	justify-content: flex-end !important; }

._just_cont_between {
	justify-content: space-between !important; }

._just_cont_center {
	justify-content: center !important; }

._just_cont_center_mb {

	@include md-mobile {
		justify-content: center !important; } }



@for $i from 0 through 80 {

	._m_t_#{ $i * 5 } {
		margin-top: ( $i * 5px ) !important; } }

@for $i from 0 through 80 {

	._m_t_#{ $i * 5 }_dsm {

		@include md-desk_sm {
			margin-top: ( $i * 5px ) !important; } } }

@for $i from 0 through 80 {

	._m_t_#{ $i * 5 }_tb {

		@include md-tablet {
			margin-top: ( $i * 5px ) !important; } } }

@for $i from 0 through 80 {

	._m_t_#{ $i * 5 }_mb {

		@include md-mobile {
			margin-top: ( $i * 5px ) !important; } } }

@for $i from 0 through 80 {

	._m_b_#{ $i * 5 } {
		margin-bottom: ( $i * 5px ) !important; } }

@for $i from 0 through 80 {

	._m_t_#{ $i * 5 }_dsm {

		@include md-desk_sm {
			margin-bottom: ( $i * 5px ) !important; } } }

@for $i from 0 through 80 {

	._m_b_#{ $i * 5 }_tb {

		@include md-tablet {
			margin-bottom: ( $i * 5px ) !important; } } }

@for $i from 0 through 80 {

	._m_b_#{ $i * 5 }_mb {

		@include md-mobile {
			margin-bottom: ( $i * 5px ) !important; } } }

@for $i from 0 through 80 {

	._p_t_#{ $i * 5 } {
		padding-top: ( $i * 5px ) !important; } }

@for $i from 0 through 80 {

	._m_t_#{ $i * 5 }_dsm {

		@include md-desk_sm {
			padding-top: ( $i * 5px ) !important; } } }

@for $i from 0 through 80 {

	._p_t_#{ $i * 5 }_tb {

		@include md-tablet {
			padding-top: ( $i * 5px ) !important; } } }

@for $i from 0 through 80 {

	._p_t_#{ $i * 5 }_mb {

		@include md-mobile {
			padding-top: ( $i * 5px ) !important; } } }

@for $i from 0 through 80 {

	._p_b_#{ $i * 5 } {
		padding-bottom: ( $i * 5px ) !important; } }

@for $i from 0 through 80 {

	._m_t_#{ $i * 5 }_dsm {

		@include md-desk_sm {
			padding-bottom: ( $i * 5px ) !important; } } }

@for $i from 0 through 80 {

	._p_b_#{ $i * 5 }_tb {

		@include md-tablet {
			padding-bottom: ( $i * 5px ) !important; } } }

@for $i from 0 through 80 {

	._p_b_#{ $i * 5 }_mb {

		@include md-mobile {
			padding-bottom: ( $i * 5px ) !important; } } }

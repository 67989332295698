.b-text {
	margin: -10px 0 -11px;

	color: $grey-darker;
	font-size: 16px;
	line-height: 32px;
	letter-spacing: 0;

	@include md-mobile {
		margin: -11px 0 -12px;
		font-size: 14px;
		line-height: 34px; } }

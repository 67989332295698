.b-list-blocks {
	display: flex; // to collapse margins inside
	flex-wrap: wrap;
	flex-direction: column;

	&__inner {
		display: flex; }

	@for $i from 1 through 50 {

		&_#{ $i * 2 } > &__inner {
			margin: -#{ $i }px; } }

	@for $i from 1 through 50 {

		&_#{ $i * 2 }_tb > &__inner {

			@include md-tablet {
				margin: -#{ $i }px; } } }

	@for $i from 1 through 50 {

		&_#{ $i * 2 }_mb > &__inner {

			@include md-mobile {
				margin: -#{ $i }px; } } }

	&_p > &__inner > &__item {
		margin: 0 !important; }

	@for $i from 1 through 50 {

		&_#{ $i * 2 } > &__inner > &__item {
			margin: #{ $i }px; }

		&_p#{&}_#{ $i * 2 } > &__inner > &__item {
			padding: #{ $i }px; } }

	@for $i from 1 through 50 {

		&_#{ $i * 2 }_tb > &__inner > &__item {

			@include md-tablet {
				margin: #{ $i }px; } }

		&_p#{&}_#{ $i * 2 }_tb > &__inner > &__item {

			@include md-tablet {
				padding: #{ $i }px; } } }

	@for $i from 1 through 50 {

		&_#{ $i * 2 }_mb > &__inner > &__item {

			@include md-mobile {
				margin: #{ $i }px; } }

		&_p#{&}_#{ $i * 2 }_mb > &__inner > &__item {

			@include md-mobile {
				padding: #{ $i }px; } } } }

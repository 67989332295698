.b-contact {
	position: relative; // for &::before

	padding: 220px 60px 30px;

	background: linear-gradient(31.76deg, #3B56D7 13.3%, #5B79F5 82.7%);

	@include md-mobile {
		padding: 180px 20px 20px; }

	&::before,
	&::after {
		content: '';

		position: absolute;
		z-index: 1; } // to be above &__video

	&::before {
		top: -195px;
		left: 50%;

		width: 100%;
		min-width: 1600px;
		height: 365px;

		background: url( '#{$img}/b-contact/bg.png' ) center / 100% 100% no-repeat;

		transform: translateX( -50% );

		@include md-retina {
			background-image: url( '#{$img}/b-contact/bg@2x.png' ); } }

	&::after {
		top: -203px;
		left: calc( 50% - 673px );

		width: 1187px;
		height: 197px;

		background: url( '#{$img}/b-contact/bg_2.png' ) center / cover no-repeat;

		@include md-retina {
			background-image: url( '#{$img}/b-contact/bg_2@2x.png' ); } }

	&__content {
		position: relative;
		z-index: 1; // to be over &&::before

		max-width: 680px;

		margin: 0 auto 70px;

		@include md-desk_sm {
			margin-bottom: 60px;
			text-align: center; }

		@include md-mobile {
			margin-bottom: 50px; }

		&_last {
			margin-bottom: 0; } }

	&__form {
		max-width: 450px;
		margin: 0 auto; }

	&__heading {
		display: flex; // to collapse margins inside
		flex-direction: column;

		margin-bottom: 55px;

		text-align: center;

		@include md-mobile {
			margin-bottom: 45px; } }

	&__text {
		margin: -12px 0;

		color: rgba( $white, .7 );
		font-size: 15px;
		line-height: 35px;
		letter-spacing: .04em;

		@include md-mobile {
			margin: -10px 0 -11px;
			font-size: 14px;
			line-height: 32px; } }

	&__copyright {
		position: relative;
		z-index: 1; // to be over &&::before

		margin: -9px 0 -8px;

		color: rgba( $white, .4 );
		font-size: 13px;
		line-height: 26px;
		letter-spacing: .04em;

		text-align: center; } }


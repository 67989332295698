.b-heading-2 {
	margin-bottom: 40px;

	&_last {
		margin-bottom: 0; }

	&__title {
		margin: -2px 0 21px;

		color: $grey-darker;
		font-size: 22px;
		line-height: 32px;
		font-weight: 700;
		letter-spacing: .04em;

		&_last {
			margin-bottom: -9px; } }

	&__subtitle {
		margin: -9px 0 -10px;

		color: $grey-light;
		font-size: 15px;
		line-height: 30px;
		letter-spacing: .04em; } }

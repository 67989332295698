.i-code,
.i-test,
.i-filling,
.i-brain,
.i-team,
.i-design,
.i-code-2,
.i-company,
.i-growth,
.i-application {

	@include font-sprite; }


.i-code {

	&::after {
		content: '\e900'; } }

.i-test {

	&::after {
		content: '\e901'; } }

.i-filling {

	&::after {
		content: '\e902'; } }

.i-brain {

	&::after {
		content: '\e903'; } }

.i-team {

	&::after {
		content: '\e904'; } }

.i-design {

	&::after {
		content: '\e905'; } }

.i-code-2 {

	&::after {
		content: '\e906'; } }

.i-company {

	&::after {
		content: '\e907'; } }

.i-growth {

	&::after {
		content: '\e908'; } }

.i-application {

	&::after {
		content: '\e909'; } }

/* settings */
/* settings */
/*----------- main -----------*/
/*----------- main (end) -----------*/
/*----------- typography -----------*/
/*----------- typography (end) -----------*/
/*----------- common -----------*/
/*----------- common (end) -----------*/
/*----------- link -----------*/
/*----------- link (end) -----------*/
/*----------- form -----------*/
/*----------- form (end) -----------*/
/*----------- table -----------*/
/*----------- table (end) -----------*/
/*----------- button -----------*/
/*----------- button (end) -----------*/
/*----------- images -----------*/
/*----------- images (end) -----------*/
/*----------- fonts -----------*/
/*----------- fonts (end) -----------*/
/*----------- usage -----------*/
/*----------- usage (end) -----------*/
/*----------- typography -----------*/
/*----------- typography (end) -----------*/
/*----------- common -----------*/
/*----------- common (end) -----------*/
/*----------- media -----------*/
/*----------- media (end) -----------*/
/*----------- link -----------*/
/*----------- link (end) -----------*/
/*----------- form -----------*/
/*----------- form (end) -----------*/
/*----------- table -----------*/
/*----------- table (end) -----------*/
/*----------- button -----------*/
/*----------- button (end) -----------*/
/* settings */
/*----------- main -----------*/
/*----------- main (end) -----------*/
/*----------- typography -----------*/
/*----------- typography (end) -----------*/
/*----------- common -----------*/
/*----------- common (end) -----------*/
/*----------- link -----------*/
/*----------- link (end) -----------*/
/*----------- form -----------*/
/*----------- form (end) -----------*/
/*----------- table -----------*/
/*----------- table (end) -----------*/
/*----------- button -----------*/
/*----------- button (end) -----------*/
/*----------- images -----------*/
/*----------- images (end) -----------*/
/*----------- fonts -----------*/
/*----------- fonts (end) -----------*/
/*----------- usage -----------*/
/*----------- usage (end) -----------*/
/*----------- typography -----------*/
/*----------- typography (end) -----------*/
/*----------- common -----------*/
/*----------- common (end) -----------*/
/*----------- media -----------*/
/*----------- media (end) -----------*/
/*----------- link -----------*/
/*----------- link (end) -----------*/
/*----------- form -----------*/
/*----------- form (end) -----------*/
/*----------- table -----------*/
/*----------- table (end) -----------*/
/*----------- button -----------*/
/*----------- button (end) -----------*/
/* main */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@font-face {
  font-family: 'icomoon';
  src: url("../fonts/sprite/icomoon.eot?femdu8");
  src: url("../fonts/sprite/icomoon.eot?femdu8#iefix") format("embedded-opentype"), url("../fonts/sprite/icomoon.ttf?femdu8") format("truetype"), url("../fonts/sprite/icomoon.woff?femdu8") format("woff"), url("../fonts/sprite/icomoon.svg?femdu8#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; }

/* core */
/* mixins */
/**
 * this style blurs the images in Safari
 * and some elements in Chrome (23.02.2015)
 */
/**
 * extend .-ol-clfx from
 * core -> snippets -> float.less
 */
/* base */
*:root {
  /*----------- colors -----------*/
  /*----------- main -----------*/
  --black:					$black;
  --grey-darker:				$grey-darker;
  --grey-dark:				$grey-dark;
  --grey:						$grey;
  --grey-light:				$grey-light;
  --grey-lighter:				$grey-lighter;
  --white:					$white;
  --brand-primary:			$brand-primary;
  --brand-success:			$brand-success;
  --brand-info:				$brand-info;
  --brand-warning:			$brand-warning;
  --brand-danger:				$brand-danger;
  /*----------- main (end) -----------*/
  /*----------- common -----------*/
  --bg_c-body:				$bg_c-body;
  --brd_c:					$brd_c;
  /*----------- common (end) -----------*/
  /*----------- colors (end) -----------*/
  /*----------- options -----------*/
  /*----------- media -----------*/
  --md-desk_md:				$md-desk_md;
  --md-desk_sm:				$md-desk_sm;
  --md-tablet:				$md-tablet;
  --md-mobile:				$md-mobile;
  --md-mobile_sm:				$md-mobile_sm;
  /*----------- media (end) -----------*/
  /*----------- options (end) -----------*/ }

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
hr,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
button,
fieldset, form, label, legend, textarea, input, select,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  vertical-align: baseline;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  font-smoothing: antialiased; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

iframe, svg {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote::before, blockquote::after,
q::before, q::after {
  content: '';
  content: none; }

img,
video {
  display: block;
  max-width: 100%; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

th {
  text-align: left; }

/* remember to define focus styles! */
*:focus {
  outline: 0; }

/* remember to highlight inserts somehow! */
ins {
  text-decoration: none; }

/* remove indent in Firefox */
button,
input,
textarea,
select,
a {
  background-color: transparent;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
a {
  letter-spacing: inherit;
  word-spacing: inherit;
  cursor: pointer; }

button {
  display: block;
  overflow: visible;
  color: inherit; }

button,
input[type='submit'],
input[type='reset'],
input[type='file'] {
  text-align: left; }

input {
  word-break: normal; }
  input[type="checkbox"], input[type="radio"] {
    cursor: pointer; }
  input[type="color"] {
    border: none;
    outline: none;
    -webkit-appearance: none; }
    input[type="color"]::-webkit-color-swatch-wrapper {
      padding: 0; }
    input[type="color"]::-moz-color-swatch-wrapper {
      padding: 0; }
    input[type="color"]::-webkit-color-swatch {
      border: none; }
    input[type="color"]::-moz-color-swatch {
      border: none; }

input,
textarea,
select {
  display: block;
  -moz-appearance: none;
  -webkit-appearance: none; }

label {
  cursor: pointer; }

a {
  color: inherit;
  text-decoration: inherit; }

mark {
  background-color: transparent;
  color: inherit; }

pre {
  white-space: pre-wrap; }

/* remove indent in Firefox */
@-moz-document url-prefix() {
  button::-moz-focus-inner,
  input[type="reset"]::-moz-focus-inner,
  input[type="button"]::-moz-focus-inner,
  input[type="submit"]::-moz-focus-inner,
  input[type="file"] > input[type="button"]::-moz-focus-inner {
    margin: 0;
    border: 0;
    padding: 0; } }

*::selection {
  background: #415DDD;
  color: #fff; }

* {
  box-sizing: border-box; }

*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  font-variant-ligatures: no-common-ligatures !important;
  font-size: 10px;
  text-size-adjust: 100%;
  line-height: 1.2;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  word-wrap: break-word;
  word-break: normal;
  word-break: break-word; }

body {
  position: relative;
  min-width: 320px;
  min-height: 100vh;
  overflow-x: hidden;
  overflow-y: visible;
  background-color: #fff;
  color: #000;
  font-family: "Montserrat", sans-serif;
  font-size: 1.6rem;
  line-height: 1.35;
  font-weight: 400;
  -webkit-overflow-scrolling: touch; }
  @media only screen and (max-width: 640px) {
    body {
      min-height: auto; } }
  body._no_scroll {
    height: 100%;
    overflow: hidden; }
  body._mobile_test {
    width: 320px; }

/* snippets */
.-ol-container {
  position: relative;
  width: 100%;
  max-width: 1230px;
  margin: 0 auto;
  padding: 0 30px; }
  @media only screen and (max-width: 1230px) {
    .-ol-container {
      width: 100%;
      max-width: none;
      padding: 0 20px; } }
  .-ol-container_full_w {
    width: 100%;
    max-width: none;
    padding: 0 30px; }
    @media only screen and (max-width: 1230px) {
      .-ol-container_full_w {
        padding: 0 20px; } }

.-ol-clfx::before, .-ol-clfx::after {
  content: "";
  display: table; }

.-ol-clfx::after {
  clear: both; }

.-ol-row,
.-ol-row-float,
.-ol-row-inline,
.-ol-row-table {
  margin-left: -15px;
  margin-right: -15px; }
  @media only screen and (max-width: 1230px) {
    .-ol-row,
    .-ol-row-float,
    .-ol-row-inline,
    .-ol-row-table {
      margin-left: -10px;
      margin-right: -10px; } }

.-ol-row {
  position: relative;
  display: flex;
  flex-wrap: wrap; }

.-ol-row-inline {
  position: relative;
  font-size: 0 !important;
  letter-spacing: 0 !important; }
  .-ol-row-inline > .-ol-col {
    display: inline-block;
    vertical-align: top;
    font-size: 1.6rem;
    letter-spacing: 0.06em; }
  .-ol-row-inline > .-ol-col_recovery {
    font-size: 1.6rem;
    letter-spacing: 0.06em; }

.-ol-row-table {
  position: relative;
  display: table;
  height: 100%;
  width: calc( 100% + 30px);
  table-layout: fixed; }
  .-ol-row-table > .-ol-col_table {
    display: table-cell;
    vertical-align: top; }
  .-ol-row-table > .-ol-col_table_major {
    width: 100%; }

.-ol-row-float {
  position: relative; }
  .-ol-row-float::before, .-ol-row-float::after {
    content: "";
    display: table; }
  .-ol-row-float::after {
    clear: both; }
  .-ol-row-float > [class*='-ol-col'] {
    float: left; }

.-ol-row_without_inside_space {
  margin: 0; }

[class*=-ol-col-] {
  position: relative;
  vertical-align: top;
  padding: 0 15px; }
  @media only screen and (max-width: 1230px) {
    [class*=-ol-col-] {
      width: 100%;
      padding: 0 10px; } }

.-ol-col_without_inside_space {
  padding: 0; }

.-ol-col_valign_m {
  vertical-align: middle !important; }

.-ol-col_valign_b {
  vertical-align: bottom !important; }

[class*=-ol-col-offset] {
  margin-left: 0; }

@media only screen and (min-width: 1231px) {
  .-ol-col-0 {
    width: 0%; }
  .-ol-col-offset-0 {
    margin-left: 0%; } }

@media only screen and (min-width: 1231px) {
  .-ol-col-1 {
    width: 8.33333%; }
  .-ol-col-offset-1 {
    margin-left: 8.33333%; } }

@media only screen and (min-width: 1231px) {
  .-ol-col-2 {
    width: 16.66667%; }
  .-ol-col-offset-2 {
    margin-left: 16.66667%; } }

@media only screen and (min-width: 1231px) {
  .-ol-col-3 {
    width: 25%; }
  .-ol-col-offset-3 {
    margin-left: 25%; } }

@media only screen and (min-width: 1231px) {
  .-ol-col-4 {
    width: 33.33333%; }
  .-ol-col-offset-4 {
    margin-left: 33.33333%; } }

@media only screen and (min-width: 1231px) {
  .-ol-col-5 {
    width: 41.66667%; }
  .-ol-col-offset-5 {
    margin-left: 41.66667%; } }

@media only screen and (min-width: 1231px) {
  .-ol-col-6 {
    width: 50%; }
  .-ol-col-offset-6 {
    margin-left: 50%; } }

@media only screen and (min-width: 1231px) {
  .-ol-col-7 {
    width: 58.33333%; }
  .-ol-col-offset-7 {
    margin-left: 58.33333%; } }

@media only screen and (min-width: 1231px) {
  .-ol-col-8 {
    width: 66.66667%; }
  .-ol-col-offset-8 {
    margin-left: 66.66667%; } }

@media only screen and (min-width: 1231px) {
  .-ol-col-9 {
    width: 75%; }
  .-ol-col-offset-9 {
    margin-left: 75%; } }

@media only screen and (min-width: 1231px) {
  .-ol-col-10 {
    width: 83.33333%; }
  .-ol-col-offset-10 {
    margin-left: 83.33333%; } }

@media only screen and (min-width: 1231px) {
  .-ol-col-11 {
    width: 91.66667%; }
  .-ol-col-offset-11 {
    margin-left: 91.66667%; } }

@media only screen and (min-width: 1231px) {
  .-ol-col-12 {
    width: 100%; }
  .-ol-col-offset-12 {
    margin-left: 100%; } }

@media only screen and (max-width: 1250px) {
  .-ol-col-dm-0 {
    width: 0%; }
  .-ol-col-dm-offset-0 {
    margin-left: 0%; } }

@media only screen and (max-width: 1250px) {
  .-ol-col-dm-1 {
    width: 8.33333%; }
  .-ol-col-dm-offset-1 {
    margin-left: 8.33333%; } }

@media only screen and (max-width: 1250px) {
  .-ol-col-dm-2 {
    width: 16.66667%; }
  .-ol-col-dm-offset-2 {
    margin-left: 16.66667%; } }

@media only screen and (max-width: 1250px) {
  .-ol-col-dm-3 {
    width: 25%; }
  .-ol-col-dm-offset-3 {
    margin-left: 25%; } }

@media only screen and (max-width: 1250px) {
  .-ol-col-dm-4 {
    width: 33.33333%; }
  .-ol-col-dm-offset-4 {
    margin-left: 33.33333%; } }

@media only screen and (max-width: 1250px) {
  .-ol-col-dm-5 {
    width: 41.66667%; }
  .-ol-col-dm-offset-5 {
    margin-left: 41.66667%; } }

@media only screen and (max-width: 1250px) {
  .-ol-col-dm-6 {
    width: 50%; }
  .-ol-col-dm-offset-6 {
    margin-left: 50%; } }

@media only screen and (max-width: 1250px) {
  .-ol-col-dm-7 {
    width: 58.33333%; }
  .-ol-col-dm-offset-7 {
    margin-left: 58.33333%; } }

@media only screen and (max-width: 1250px) {
  .-ol-col-dm-8 {
    width: 66.66667%; }
  .-ol-col-dm-offset-8 {
    margin-left: 66.66667%; } }

@media only screen and (max-width: 1250px) {
  .-ol-col-dm-9 {
    width: 75%; }
  .-ol-col-dm-offset-9 {
    margin-left: 75%; } }

@media only screen and (max-width: 1250px) {
  .-ol-col-dm-10 {
    width: 83.33333%; }
  .-ol-col-dm-offset-10 {
    margin-left: 83.33333%; } }

@media only screen and (max-width: 1250px) {
  .-ol-col-dm-11 {
    width: 91.66667%; }
  .-ol-col-dm-offset-11 {
    margin-left: 91.66667%; } }

@media only screen and (max-width: 1250px) {
  .-ol-col-dm-12 {
    width: 100%; }
  .-ol-col-dm-offset-12 {
    margin-left: 100%; } }

@media only screen and (max-width: 1230px) {
  .-ol-col-tb-0 {
    width: 0%; }
  .-ol-col-tb-offset-0 {
    margin-left: 0%; } }

@media only screen and (max-width: 1230px) {
  .-ol-col-tb-1 {
    width: 8.33333%; }
  .-ol-col-tb-offset-1 {
    margin-left: 8.33333%; } }

@media only screen and (max-width: 1230px) {
  .-ol-col-tb-2 {
    width: 16.66667%; }
  .-ol-col-tb-offset-2 {
    margin-left: 16.66667%; } }

@media only screen and (max-width: 1230px) {
  .-ol-col-tb-3 {
    width: 25%; }
  .-ol-col-tb-offset-3 {
    margin-left: 25%; } }

@media only screen and (max-width: 1230px) {
  .-ol-col-tb-4 {
    width: 33.33333%; }
  .-ol-col-tb-offset-4 {
    margin-left: 33.33333%; } }

@media only screen and (max-width: 1230px) {
  .-ol-col-tb-5 {
    width: 41.66667%; }
  .-ol-col-tb-offset-5 {
    margin-left: 41.66667%; } }

@media only screen and (max-width: 1230px) {
  .-ol-col-tb-6 {
    width: 50%; }
  .-ol-col-tb-offset-6 {
    margin-left: 50%; } }

@media only screen and (max-width: 1230px) {
  .-ol-col-tb-7 {
    width: 58.33333%; }
  .-ol-col-tb-offset-7 {
    margin-left: 58.33333%; } }

@media only screen and (max-width: 1230px) {
  .-ol-col-tb-8 {
    width: 66.66667%; }
  .-ol-col-tb-offset-8 {
    margin-left: 66.66667%; } }

@media only screen and (max-width: 1230px) {
  .-ol-col-tb-9 {
    width: 75%; }
  .-ol-col-tb-offset-9 {
    margin-left: 75%; } }

@media only screen and (max-width: 1230px) {
  .-ol-col-tb-10 {
    width: 83.33333%; }
  .-ol-col-tb-offset-10 {
    margin-left: 83.33333%; } }

@media only screen and (max-width: 1230px) {
  .-ol-col-tb-11 {
    width: 91.66667%; }
  .-ol-col-tb-offset-11 {
    margin-left: 91.66667%; } }

@media only screen and (max-width: 1230px) {
  .-ol-col-tb-12 {
    width: 100%; }
  .-ol-col-tb-offset-12 {
    margin-left: 100%; } }

@media only screen and (max-width: 640px) {
  .-ol-col-mb-0 {
    width: 0%; }
  .-ol-col-mb-offset-0 {
    margin-left: 0%; } }

@media only screen and (max-width: 640px) {
  .-ol-col-mb-1 {
    width: 8.33333%; }
  .-ol-col-mb-offset-1 {
    margin-left: 8.33333%; } }

@media only screen and (max-width: 640px) {
  .-ol-col-mb-2 {
    width: 16.66667%; }
  .-ol-col-mb-offset-2 {
    margin-left: 16.66667%; } }

@media only screen and (max-width: 640px) {
  .-ol-col-mb-3 {
    width: 25%; }
  .-ol-col-mb-offset-3 {
    margin-left: 25%; } }

@media only screen and (max-width: 640px) {
  .-ol-col-mb-4 {
    width: 33.33333%; }
  .-ol-col-mb-offset-4 {
    margin-left: 33.33333%; } }

@media only screen and (max-width: 640px) {
  .-ol-col-mb-5 {
    width: 41.66667%; }
  .-ol-col-mb-offset-5 {
    margin-left: 41.66667%; } }

@media only screen and (max-width: 640px) {
  .-ol-col-mb-6 {
    width: 50%; }
  .-ol-col-mb-offset-6 {
    margin-left: 50%; } }

@media only screen and (max-width: 640px) {
  .-ol-col-mb-7 {
    width: 58.33333%; }
  .-ol-col-mb-offset-7 {
    margin-left: 58.33333%; } }

@media only screen and (max-width: 640px) {
  .-ol-col-mb-8 {
    width: 66.66667%; }
  .-ol-col-mb-offset-8 {
    margin-left: 66.66667%; } }

@media only screen and (max-width: 640px) {
  .-ol-col-mb-9 {
    width: 75%; }
  .-ol-col-mb-offset-9 {
    margin-left: 75%; } }

@media only screen and (max-width: 640px) {
  .-ol-col-mb-10 {
    width: 83.33333%; }
  .-ol-col-mb-offset-10 {
    margin-left: 83.33333%; } }

@media only screen and (max-width: 640px) {
  .-ol-col-mb-11 {
    width: 91.66667%; }
  .-ol-col-mb-offset-11 {
    margin-left: 91.66667%; } }

@media only screen and (max-width: 640px) {
  .-ol-col-mb-12 {
    width: 100%; }
  .-ol-col-mb-offset-12 {
    margin-left: 100%; } }

.-ol-gap-remover {
  margin-left: -15px;
  margin-right: -15px; }
  @media only screen and (max-width: 1230px) {
    .-ol-gap-remover {
      margin: 0; } }

@media only screen and (max-width: 1230px) {
  .-ol-gap-remover-tb {
    margin-left: -10px;
    margin-right: -10px; } }

@media only screen and (max-width: 640px) {
  .-ol-gap-remover-mb {
    margin-left: -10px;
    margin-right: -10px; } }

._pos_relative {
  position: relative !important; }

._pos_absolute {
  position: absolute !important; }

._block {
  display: block !important; }

@media only screen and (max-width: 1230px) {
  ._block_tb {
    display: block !important; } }

@media only screen and (max-width: 640px) {
  ._block_mb {
    display: block !important; } }

._flex {
  display: flex !important; }

@media only screen and (max-width: 1230px) {
  ._flex_tb {
    display: flex !important; } }

@media only screen and (max-width: 640px) {
  ._flex_mb {
    display: flex !important; } }

._inline_block {
  display: inline-block !important; }

@media only screen and (max-width: 1230px) {
  ._inline_block_tb {
    display: inline-block !important; } }

@media only screen and (max-width: 640px) {
  ._inline_block_mb {
    display: inline-block !important; } }

._inline {
  display: inline !important; }

@media only screen and (max-width: 1230px) {
  ._inline_tb {
    display: inline !important; } }

@media only screen and (max-width: 640px) {
  ._inline_mb {
    display: inline !important; } }

._only_tb {
  display: none !important; }
  @media only screen and (max-width: 1230px) {
    ._only_tb {
      display: block !important; } }

._only_inline_block_tb {
  display: none !important; }
  @media only screen and (max-width: 1230px) {
    ._only_inline_block_tb {
      display: inline-block !important; } }

._only_flex_tb {
  display: none !important; }
  @media only screen and (max-width: 1230px) {
    ._only_flex_tb {
      display: flex !important; } }

._only_inline_tb {
  display: none !important; }
  @media only screen and (max-width: 1230px) {
    ._only_inline_tb {
      display: inline !important; } }

._only_mb {
  display: none !important; }
  @media only screen and (max-width: 640px) {
    ._only_mb {
      display: block !important; } }

._only_inline_block_mb {
  display: none !important; }
  @media only screen and (max-width: 640px) {
    ._only_inline_block_mb {
      display: inline-block !important; } }

._only_flex_mb {
  display: none !important; }
  @media only screen and (max-width: 640px) {
    ._only_flex_mb {
      display: flex !important; } }

._only_inline_mb {
  display: none !important; }
  @media only screen and (max-width: 640px) {
    ._only_inline_mb {
      display: inline !important; } }

._none {
  display: none !important; }

@media only screen and (max-width: 1230px) {
  ._none_tb {
    display: none !important; } }

@media only screen and (max-width: 640px) {
  ._none_mb {
    display: none !important; } }

._bind {
  position: relative !important; }

._unbind {
  position: static !important; }

._flex_horiz_center {
  justify-content: center; }

._full_w {
  width: 100% !important; }

._full_h {
  height: 100% !important; }

._full_h_vh {
  height: 100vh !important; }

._min_h_auto {
  min-height: auto !important; }

._min_h_full_vh {
  min-height: 100vh !important; }

._hidden {
  overflow: hidden !important; }

._overflow_auto {
  overflow: auto !important; }

._block_center {
  margin-left: auto !important;
  margin-right: auto !important; }

._block_vert_center {
  margin-top: auto !important;
  margin-bottom: auto !important; }

._letter_spacing_inherit {
  letter-spacing: inherit; }

._text_center {
  text-align: center !important; }

._text_left {
  text-align: left !important; }

._text_right {
  text-align: right !important; }

._text_capitalize {
  text-transform: capitalize !important; }

._text_uppercase {
  text-transform: uppercase !important; }

._text_ellipsis {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal; }

._nowrap {
  white-space: nowrap; }

._cur_p {
  cursor: pointer !important; }

._events_none {
  pointer-events: none !important; }

/* project */
/* mixins */
/* base */
.-ol-btn {
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 0;
  border-style: solid;
  background-image: none;
  box-shadow: 0 30px 50px rgba(10, 36, 102, 0);
  font-weight: 700;
  letter-spacing: 0.04em;
  text-align: center;
  cursor: pointer;
  touch-action: manipulation;
  transition: all .2s;
  height: auto;
  min-height: auto;
  border-radius: 10px;
  border-width: 0;
  padding: 16.5px 25px;
  font-size: 15px;
  line-height: 23px;
  border-color: #415DDD;
  background: #415DDD;
  color: #fff;
  transition: all .2s;
  padding-top: 16px;
  padding-bottom: 17px;
  background: linear-gradient(90deg, #4460E0 0%, #37A6F1 100%); }
  .-ol-btn:hover {
    border-color: #2b4bd9;
    background: #2b4bd9;
    color: #fff; }
  .-ol-btn:active {
    border-color: #2441c7;
    background: #2441c7;
    color: #fff; }
  .-ol-btn:hover, .-ol-btn:active {
    background: linear-gradient(90deg, #4460E0 0%, #37A6F1 100%); }
  .-ol-btn:hover {
    box-shadow: 0 30px 50px rgba(10, 36, 102, 0.3); }
  .-ol-btn:active {
    box-shadow: 0 30px 50px rgba(10, 36, 102, 0); }
  .-ol-btn_default {
    border-color: #fff;
    background: #fff;
    color: #415DDD;
    transition: all .2s;
    box-shadow: 0 30px 50px rgba(10, 36, 102, 0.3); }
    .-ol-btn_default:hover {
      border-color: #f2f2f2;
      background: #f2f2f2;
      color: #415DDD; }
    .-ol-btn_default:active {
      border-color: #e6e6e6;
      background: #e6e6e6;
      color: #415DDD; }
  .-ol-btn_success {
    border-color: #58c067;
    background: #58c067;
    color: #fff;
    transition: all .2s; }
    .-ol-btn_success:hover {
      border-color: #46b956;
      background: #46b956;
      color: #fff; }
    .-ol-btn_success:active {
      border-color: #3fa64e;
      background: #3fa64e;
      color: #fff; }
  .-ol-btn_info {
    border-color: #69c1df;
    background: #69c1df;
    color: #fff;
    transition: all .2s; }
    .-ol-btn_info:hover {
      border-color: #54b8db;
      background: #54b8db;
      color: #fff; }
    .-ol-btn_info:active {
      border-color: #3fb0d6;
      background: #3fb0d6;
      color: #fff; }
  .-ol-btn_warning {
    border-color: #f5bf53;
    background: #f5bf53;
    color: #fff;
    transition: all .2s; }
    .-ol-btn_warning:hover {
      border-color: #f4b63b;
      background: #f4b63b;
      color: #fff; }
    .-ol-btn_warning:active {
      border-color: #f2ad23;
      background: #f2ad23;
      color: #fff; }
  .-ol-btn_danger {
    border-color: #ed6161;
    background: #ed6161;
    color: #fff;
    transition: all .2s; }
    .-ol-btn_danger:hover {
      border-color: #ea4a4a;
      background: #ea4a4a;
      color: #fff; }
    .-ol-btn_danger:active {
      border-color: #e83333;
      background: #e83333;
      color: #fff; }
  .-ol-btn_outline {
    border-color: #415DDD;
    background: transparent;
    color: #415DDD;
    transition: all .2s; }
    .-ol-btn_outline:hover {
      border-color: #2b4bd9;
      background: rgba(0, 0, 0, 0);
      color: #415DDD; }
    .-ol-btn_outline:active {
      border-color: #2441c7;
      background: rgba(0, 0, 0, 0);
      color: #415DDD; }
  .-ol-btn[disabled] {
    border-color: #cccccc;
    background: #cccccc;
    color: #fff;
    transition: all .2s;
    cursor: not-allowed;
    pointer-events: none; }
    .-ol-btn[disabled]:hover {
      border-color: #cccccc;
      background: #cccccc;
      color: #fff; }
    .-ol-btn[disabled]:active {
      border-color: #cccccc;
      background: #cccccc;
      color: #fff; }
  .-ol-btn_link {
    width: auto;
    height: auto;
    border: 0;
    border-radius: 0;
    padding: 0;
    border-color: transparent;
    border-bottom: 1px solid transparent;
    color: #415DDD; }
    .-ol-btn_link:hover {
      border-bottom-color: fadeout(#415DDD, 20%);
      color: #415DDD; }
  .-ol-btn_lg {
    height: auto;
    min-height: auto;
    border-radius: 10px;
    border-width: 0;
    padding: 20px 30px;
    font-size: 16px;
    line-height: 24px; }
  .-ol-btn_sm {
    box-shadow: 0 20px 50px rgba(10, 36, 102, 0.2);
    height: auto;
    min-height: auto;
    border-radius: 10px;
    border-width: 0;
    padding: 15.5px 20px;
    font-size: 14px;
    line-height: 21px;
    padding: 16px 20px 15px; }
  .-ol-btn_xs {
    height: auto;
    min-height: auto;
    border-radius: 10px;
    border-width: 0;
    padding: 8px 15px;
    font-size: 13px;
    line-height: 20px; }
  .-ol-btn_w_fix_md {
    width: 180px;
    max-width: 100%; }
  .-ol-btn_w_fix_xs {
    width: 150px;
    max-width: 100%; }
  .-ol-btn_min_w_initial {
    min-width: 0 !important; }

._modal_active {
  overflow: hidden; }

.-ol-input,
.-ol-textarea {
  display: block;
  width: 100%;
  padding: 7px 20px;
  border: 0 solid transparent;
  border-radius: 7px;
  background: rgba(42, 67, 178, 0.55);
  box-shadow: inset 4px 4px 12px rgba(10, 36, 102, 0.15);
  color: #fff;
  font-size: 14px;
  line-height: 42px;
  letter-spacing: 0.06em;
  text-overflow: ellipsis; }
  .-ol-input::-webkit-input-placeholder,
  .-ol-textarea::-webkit-input-placeholder {
    color: #fff;
    font-weight: inherit;
    letter-spacing: 0.06em; }
  .-ol-input::-moz-placeholder,
  .-ol-textarea::-moz-placeholder {
    color: #fff;
    font-weight: inherit;
    letter-spacing: 0.06em; }
  .-ol-input:-moz-placeholder,
  .-ol-textarea:-moz-placeholder {
    color: #fff;
    font-weight: inherit;
    letter-spacing: 0.06em; }
  .-ol-input:-ms-input-placeholder,
  .-ol-textarea:-ms-input-placeholder {
    color: #fff;
    font-weight: inherit;
    letter-spacing: 0.06em; }
  .-ol-input:hover::-webkit-input-placeholder,
  .-ol-textarea:hover::-webkit-input-placeholder {
    color: #fff;
    font-weight: inherit;
    letter-spacing: 0.06em; }
  .-ol-input:hover::-moz-placeholder,
  .-ol-textarea:hover::-moz-placeholder {
    color: #fff;
    font-weight: inherit;
    letter-spacing: 0.06em; }
  .-ol-input:hover:-moz-placeholder,
  .-ol-textarea:hover:-moz-placeholder {
    color: #fff;
    font-weight: inherit;
    letter-spacing: 0.06em; }
  .-ol-input:hover:-ms-input-placeholder,
  .-ol-textarea:hover:-ms-input-placeholder {
    color: #fff;
    font-weight: inherit;
    letter-spacing: 0.06em; }
  .-ol-input:focus::-webkit-input-placeholder,
  .-ol-textarea:focus::-webkit-input-placeholder {
    color: transparent;
    font-weight: inherit;
    letter-spacing: 0.06em; }
  .-ol-input:focus::-moz-placeholder,
  .-ol-textarea:focus::-moz-placeholder {
    color: transparent;
    font-weight: inherit;
    letter-spacing: 0.06em; }
  .-ol-input:focus:-moz-placeholder,
  .-ol-textarea:focus:-moz-placeholder {
    color: transparent;
    font-weight: inherit;
    letter-spacing: 0.06em; }
  .-ol-input:focus:-ms-input-placeholder,
  .-ol-textarea:focus:-ms-input-placeholder {
    color: transparent;
    font-weight: inherit;
    letter-spacing: 0.06em; }
  .-ol-input[readonly], .-ol-input[disabled],
  .-ol-textarea[readonly],
  .-ol-textarea[disabled] {
    color: #838CA3;
    cursor: default; }

.-ol-input {
  height: 56px; }

.-ol-textarea {
  height: 110px;
  resize: vertical;
  overflow: auto; }

.-ol-input-file {
  position: relative;
  overflow: hidden;
  width: 100%; }
  .-ol-input-file input {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    opacity: 0;
    cursor: pointer; }

.-ol-checkbox {
  position: relative;
  width: 14px;
  height: 14px; }
  .-ol-checkbox__input {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 0;
    overflow: hidden;
    width: 100%;
    height: 100%; }
  .-ol-checkbox__trigger {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid #e6e6e6;
    border-radius: 2px;
    background-color: #fff;
    cursor: pointer; }
  .-ol-checkbox__input:checked ~ .-ol-checkbox__trigger {
    border: 5px solid #415DDD;
    background-color: #fff; }
  .-ol-checkbox[disabled] {
    opacity: .55;
    cursor: default; }

.-ol-radio {
  position: relative;
  width: 14px;
  height: 14px; }
  .-ol-radio__input {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 0;
    overflow: hidden;
    width: 100%;
    height: 100%; }
    .-ol-radio__input[disabled] {
      cursor: default; }
  .-ol-radio__trigger {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid #e6e6e6;
    border-radius: 50%;
    background-color: #fff;
    cursor: pointer; }
  .-ol-radio__input:checked ~ .-ol-radio__trigger {
    border: 5px solid #415DDD;
    background-color: #fff; }
  .-ol-radio__input[disabled] ~ .-ol-radio__trigger {
    border-color: #e6e6e6;
    background-color: #e6e6e6; }

.-ol-label {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px; }
  .-ol-label_last {
    margin-bottom: 0; }

.-ol-label-text {
  display: block;
  color: #000;
  font-size: 1.6rem;
  font-weight: 700; }

.-ol-input-wrap {
  position: relative; }
  .-ol-input-wrap_disabled {
    opacity: .6; }
    .-ol-input-wrap_disabled .-ol-input,
    .-ol-input-wrap_disabled .-ol-textarea {
      color: #fff; }
    .-ol-input-wrap_disabled label {
      cursor: auto; }
  .-ol-input-wrap_error .-ol-input,
  .-ol-input-wrap_error .-ol-textarea {
    background-color: rgba(237, 97, 97, 0.45); }

.-ol-row-input {
  margin-bottom: 20px; }
  .-ol-row-input_last {
    margin-bottom: 0; }

@media only screen and (max-width: 640px) {
  .-ol-row-input-mb {
    margin-bottom: 20px; } }

.-ol-form-body {
  margin-bottom: 30px; }
  .-ol-form-body_last {
    margin-bottom: 0; }

.-ol-content h1,
.-ol-h1,
.-ol-content h2,
.-ol-h2,
.-ol-content h3,
.-ol-h3,
.-ol-content h4,
.-ol-h4,
.-ol-content h5,
.-ol-h5,
.-ol-content h6,
.-ol-h6 {
  color: #000;
  line-height: 1.35;
  font-weight: 400; }

.-ol-content h1,
.-ol-h1 {
  margin-bottom: 20px;
  font-size: 3.6rem; }

.-ol-content h2,
.-ol-h2 {
  margin-bottom: 15px;
  font-size: 3rem; }

.-ol-content h3,
.-ol-h3 {
  margin-bottom: 10px;
  font-size: 2.4rem; }

.-ol-content h4,
.-ol-h4 {
  margin-bottom: 7px;
  font-size: 1.8rem; }

.-ol-content h5,
.-ol-h5 {
  margin-bottom: 5px;
  font-size: 1.6rem; }

.-ol-content h6,
.-ol-h6 {
  margin-bottom: 5px;
  font-size: 1.2rem; }

.-ol-h-none {
  margin-bottom: 0;
  color: #000;
  font-family: "Montserrat", sans-serif;
  font-size: 1.6rem;
  line-height: 1.35;
  letter-spacing: 0.06em;
  font-weight: 400;
  word-spacing: 0;
  text-transform: none; }

.-ol-content a,
.-ol-link {
  display: inline;
  border-bottom: 1px solid transparent;
  color: #415DDD;
  line-height: inherit;
  letter-spacing: inherit;
  cursor: pointer;
  transition: all .2s; }
  .-ol-content a:hover,
  .-ol-link:hover {
    border-bottom-color: #2441c7;
    color: #2441c7; }

.-ol-content b,
.-ol-b,
.-ol-content strong,
.-ol-strong {
  font-weight: bold;
  letter-spacing: inherit; }

.-ol-content i,
.-ol-i,
.-ol-content em,
.-ol-em {
  font-style: italic;
  letter-spacing: inherit; }

.-ol-content sub,
.-ol-sub,
.-ol-content sup,
.-ol-sup {
  font-size: .6em; }

.-ol-content sub,
.-ol-sub {
  vertical-align: bottom; }

.-ol-content sup,
.-ol-sup {
  vertical-align: top; }

.i-code::after,
.i-test::after,
.i-filling::after,
.i-brain::after,
.i-team::after,
.b-services__box-icon_team::after,
.i-design::after,
.b-services__box-icon_design::after,
.i-code-2::after,
.b-services__box-icon_development::after,
.i-company::after,
.b-services__box-icon_company::after,
.i-growth::after,
.b-services__box-icon_growth::after,
.i-application::after,
.b-services__box-icon_applications::after {
  font-family: "icomoon" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  letter-spacing: 0;
  text-transform: none;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: greyscale;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga'; }

.i-code::after {
  content: '\e900'; }

.i-test::after {
  content: '\e901'; }

.i-filling::after {
  content: '\e902'; }

.i-brain::after {
  content: '\e903'; }


.i-team::after,
.b-services__box-icon_team::after {
  content: '\e904'; }


.i-design::after,
.b-services__box-icon_design::after {
  content: '\e905'; }


.i-code-2::after,
.b-services__box-icon_development::after {
  content: '\e906'; }


.i-company::after,
.b-services__box-icon_company::after {
  content: '\e907'; }


.i-growth::after,
.b-services__box-icon_growth::after {
  content: '\e908'; }


.i-application::after,
.b-services__box-icon_applications::after {
  content: '\e909'; }

/* snippets */
._z_i_2 {
  z-index: 2; }

._op_0 {
  opacity: 0; }

._flex_stretch {
  flex-grow: 1 !important; }

._flex_shrink_none {
  flex-shrink: 0 !important; }

._flex_dir_row_reverse {
  flex-direction: row-reverse !important; }

._flex_dir_just_end {
  justify-content: flex-end !important; }

._f_col {
  flex-direction: column !important; }

@media only screen and (max-width: 640px) {
  ._f_col_mb {
    flex-direction: column !important; } }

._f_wrap {
  flex-wrap: wrap !important; }

._align_center {
  align-items: center !important; }

._align_baseline {
  align-items: baseline !important; }

._just_cont_end {
  justify-content: flex-end !important; }

._just_cont_between {
  justify-content: space-between !important; }

._just_cont_center {
  justify-content: center !important; }

@media only screen and (max-width: 640px) {
  ._just_cont_center_mb {
    justify-content: center !important; } }

._m_t_0 {
  margin-top: 0px !important; }

._m_t_5 {
  margin-top: 5px !important; }

._m_t_10 {
  margin-top: 10px !important; }

._m_t_15 {
  margin-top: 15px !important; }

._m_t_20 {
  margin-top: 20px !important; }

._m_t_25 {
  margin-top: 25px !important; }

._m_t_30 {
  margin-top: 30px !important; }

._m_t_35 {
  margin-top: 35px !important; }

._m_t_40 {
  margin-top: 40px !important; }

._m_t_45 {
  margin-top: 45px !important; }

._m_t_50 {
  margin-top: 50px !important; }

._m_t_55 {
  margin-top: 55px !important; }

._m_t_60 {
  margin-top: 60px !important; }

._m_t_65 {
  margin-top: 65px !important; }

._m_t_70 {
  margin-top: 70px !important; }

._m_t_75 {
  margin-top: 75px !important; }

._m_t_80 {
  margin-top: 80px !important; }

._m_t_85 {
  margin-top: 85px !important; }

._m_t_90 {
  margin-top: 90px !important; }

._m_t_95 {
  margin-top: 95px !important; }

._m_t_100 {
  margin-top: 100px !important; }

._m_t_105 {
  margin-top: 105px !important; }

._m_t_110 {
  margin-top: 110px !important; }

._m_t_115 {
  margin-top: 115px !important; }

._m_t_120 {
  margin-top: 120px !important; }

._m_t_125 {
  margin-top: 125px !important; }

._m_t_130 {
  margin-top: 130px !important; }

._m_t_135 {
  margin-top: 135px !important; }

._m_t_140 {
  margin-top: 140px !important; }

._m_t_145 {
  margin-top: 145px !important; }

._m_t_150 {
  margin-top: 150px !important; }

._m_t_155 {
  margin-top: 155px !important; }

._m_t_160 {
  margin-top: 160px !important; }

._m_t_165 {
  margin-top: 165px !important; }

._m_t_170 {
  margin-top: 170px !important; }

._m_t_175 {
  margin-top: 175px !important; }

._m_t_180 {
  margin-top: 180px !important; }

._m_t_185 {
  margin-top: 185px !important; }

._m_t_190 {
  margin-top: 190px !important; }

._m_t_195 {
  margin-top: 195px !important; }

._m_t_200 {
  margin-top: 200px !important; }

._m_t_205 {
  margin-top: 205px !important; }

._m_t_210 {
  margin-top: 210px !important; }

._m_t_215 {
  margin-top: 215px !important; }

._m_t_220 {
  margin-top: 220px !important; }

._m_t_225 {
  margin-top: 225px !important; }

._m_t_230 {
  margin-top: 230px !important; }

._m_t_235 {
  margin-top: 235px !important; }

._m_t_240 {
  margin-top: 240px !important; }

._m_t_245 {
  margin-top: 245px !important; }

._m_t_250 {
  margin-top: 250px !important; }

._m_t_255 {
  margin-top: 255px !important; }

._m_t_260 {
  margin-top: 260px !important; }

._m_t_265 {
  margin-top: 265px !important; }

._m_t_270 {
  margin-top: 270px !important; }

._m_t_275 {
  margin-top: 275px !important; }

._m_t_280 {
  margin-top: 280px !important; }

._m_t_285 {
  margin-top: 285px !important; }

._m_t_290 {
  margin-top: 290px !important; }

._m_t_295 {
  margin-top: 295px !important; }

._m_t_300 {
  margin-top: 300px !important; }

._m_t_305 {
  margin-top: 305px !important; }

._m_t_310 {
  margin-top: 310px !important; }

._m_t_315 {
  margin-top: 315px !important; }

._m_t_320 {
  margin-top: 320px !important; }

._m_t_325 {
  margin-top: 325px !important; }

._m_t_330 {
  margin-top: 330px !important; }

._m_t_335 {
  margin-top: 335px !important; }

._m_t_340 {
  margin-top: 340px !important; }

._m_t_345 {
  margin-top: 345px !important; }

._m_t_350 {
  margin-top: 350px !important; }

._m_t_355 {
  margin-top: 355px !important; }

._m_t_360 {
  margin-top: 360px !important; }

._m_t_365 {
  margin-top: 365px !important; }

._m_t_370 {
  margin-top: 370px !important; }

._m_t_375 {
  margin-top: 375px !important; }

._m_t_380 {
  margin-top: 380px !important; }

._m_t_385 {
  margin-top: 385px !important; }

._m_t_390 {
  margin-top: 390px !important; }

._m_t_395 {
  margin-top: 395px !important; }

._m_t_400 {
  margin-top: 400px !important; }

@media only screen and (max-width: 1250px) {
  ._m_t_0_dsm {
    margin-top: 0px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_5_dsm {
    margin-top: 5px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_10_dsm {
    margin-top: 10px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_15_dsm {
    margin-top: 15px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_20_dsm {
    margin-top: 20px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_25_dsm {
    margin-top: 25px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_30_dsm {
    margin-top: 30px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_35_dsm {
    margin-top: 35px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_40_dsm {
    margin-top: 40px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_45_dsm {
    margin-top: 45px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_50_dsm {
    margin-top: 50px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_55_dsm {
    margin-top: 55px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_60_dsm {
    margin-top: 60px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_65_dsm {
    margin-top: 65px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_70_dsm {
    margin-top: 70px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_75_dsm {
    margin-top: 75px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_80_dsm {
    margin-top: 80px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_85_dsm {
    margin-top: 85px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_90_dsm {
    margin-top: 90px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_95_dsm {
    margin-top: 95px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_100_dsm {
    margin-top: 100px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_105_dsm {
    margin-top: 105px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_110_dsm {
    margin-top: 110px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_115_dsm {
    margin-top: 115px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_120_dsm {
    margin-top: 120px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_125_dsm {
    margin-top: 125px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_130_dsm {
    margin-top: 130px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_135_dsm {
    margin-top: 135px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_140_dsm {
    margin-top: 140px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_145_dsm {
    margin-top: 145px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_150_dsm {
    margin-top: 150px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_155_dsm {
    margin-top: 155px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_160_dsm {
    margin-top: 160px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_165_dsm {
    margin-top: 165px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_170_dsm {
    margin-top: 170px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_175_dsm {
    margin-top: 175px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_180_dsm {
    margin-top: 180px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_185_dsm {
    margin-top: 185px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_190_dsm {
    margin-top: 190px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_195_dsm {
    margin-top: 195px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_200_dsm {
    margin-top: 200px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_205_dsm {
    margin-top: 205px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_210_dsm {
    margin-top: 210px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_215_dsm {
    margin-top: 215px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_220_dsm {
    margin-top: 220px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_225_dsm {
    margin-top: 225px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_230_dsm {
    margin-top: 230px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_235_dsm {
    margin-top: 235px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_240_dsm {
    margin-top: 240px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_245_dsm {
    margin-top: 245px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_250_dsm {
    margin-top: 250px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_255_dsm {
    margin-top: 255px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_260_dsm {
    margin-top: 260px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_265_dsm {
    margin-top: 265px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_270_dsm {
    margin-top: 270px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_275_dsm {
    margin-top: 275px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_280_dsm {
    margin-top: 280px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_285_dsm {
    margin-top: 285px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_290_dsm {
    margin-top: 290px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_295_dsm {
    margin-top: 295px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_300_dsm {
    margin-top: 300px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_305_dsm {
    margin-top: 305px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_310_dsm {
    margin-top: 310px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_315_dsm {
    margin-top: 315px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_320_dsm {
    margin-top: 320px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_325_dsm {
    margin-top: 325px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_330_dsm {
    margin-top: 330px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_335_dsm {
    margin-top: 335px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_340_dsm {
    margin-top: 340px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_345_dsm {
    margin-top: 345px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_350_dsm {
    margin-top: 350px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_355_dsm {
    margin-top: 355px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_360_dsm {
    margin-top: 360px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_365_dsm {
    margin-top: 365px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_370_dsm {
    margin-top: 370px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_375_dsm {
    margin-top: 375px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_380_dsm {
    margin-top: 380px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_385_dsm {
    margin-top: 385px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_390_dsm {
    margin-top: 390px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_395_dsm {
    margin-top: 395px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_400_dsm {
    margin-top: 400px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_t_0_tb {
    margin-top: 0px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_t_5_tb {
    margin-top: 5px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_t_10_tb {
    margin-top: 10px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_t_15_tb {
    margin-top: 15px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_t_20_tb {
    margin-top: 20px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_t_25_tb {
    margin-top: 25px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_t_30_tb {
    margin-top: 30px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_t_35_tb {
    margin-top: 35px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_t_40_tb {
    margin-top: 40px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_t_45_tb {
    margin-top: 45px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_t_50_tb {
    margin-top: 50px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_t_55_tb {
    margin-top: 55px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_t_60_tb {
    margin-top: 60px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_t_65_tb {
    margin-top: 65px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_t_70_tb {
    margin-top: 70px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_t_75_tb {
    margin-top: 75px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_t_80_tb {
    margin-top: 80px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_t_85_tb {
    margin-top: 85px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_t_90_tb {
    margin-top: 90px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_t_95_tb {
    margin-top: 95px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_t_100_tb {
    margin-top: 100px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_t_105_tb {
    margin-top: 105px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_t_110_tb {
    margin-top: 110px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_t_115_tb {
    margin-top: 115px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_t_120_tb {
    margin-top: 120px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_t_125_tb {
    margin-top: 125px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_t_130_tb {
    margin-top: 130px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_t_135_tb {
    margin-top: 135px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_t_140_tb {
    margin-top: 140px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_t_145_tb {
    margin-top: 145px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_t_150_tb {
    margin-top: 150px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_t_155_tb {
    margin-top: 155px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_t_160_tb {
    margin-top: 160px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_t_165_tb {
    margin-top: 165px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_t_170_tb {
    margin-top: 170px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_t_175_tb {
    margin-top: 175px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_t_180_tb {
    margin-top: 180px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_t_185_tb {
    margin-top: 185px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_t_190_tb {
    margin-top: 190px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_t_195_tb {
    margin-top: 195px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_t_200_tb {
    margin-top: 200px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_t_205_tb {
    margin-top: 205px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_t_210_tb {
    margin-top: 210px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_t_215_tb {
    margin-top: 215px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_t_220_tb {
    margin-top: 220px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_t_225_tb {
    margin-top: 225px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_t_230_tb {
    margin-top: 230px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_t_235_tb {
    margin-top: 235px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_t_240_tb {
    margin-top: 240px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_t_245_tb {
    margin-top: 245px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_t_250_tb {
    margin-top: 250px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_t_255_tb {
    margin-top: 255px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_t_260_tb {
    margin-top: 260px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_t_265_tb {
    margin-top: 265px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_t_270_tb {
    margin-top: 270px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_t_275_tb {
    margin-top: 275px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_t_280_tb {
    margin-top: 280px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_t_285_tb {
    margin-top: 285px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_t_290_tb {
    margin-top: 290px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_t_295_tb {
    margin-top: 295px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_t_300_tb {
    margin-top: 300px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_t_305_tb {
    margin-top: 305px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_t_310_tb {
    margin-top: 310px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_t_315_tb {
    margin-top: 315px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_t_320_tb {
    margin-top: 320px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_t_325_tb {
    margin-top: 325px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_t_330_tb {
    margin-top: 330px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_t_335_tb {
    margin-top: 335px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_t_340_tb {
    margin-top: 340px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_t_345_tb {
    margin-top: 345px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_t_350_tb {
    margin-top: 350px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_t_355_tb {
    margin-top: 355px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_t_360_tb {
    margin-top: 360px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_t_365_tb {
    margin-top: 365px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_t_370_tb {
    margin-top: 370px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_t_375_tb {
    margin-top: 375px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_t_380_tb {
    margin-top: 380px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_t_385_tb {
    margin-top: 385px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_t_390_tb {
    margin-top: 390px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_t_395_tb {
    margin-top: 395px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_t_400_tb {
    margin-top: 400px !important; } }

@media only screen and (max-width: 640px) {
  ._m_t_0_mb {
    margin-top: 0px !important; } }

@media only screen and (max-width: 640px) {
  ._m_t_5_mb {
    margin-top: 5px !important; } }

@media only screen and (max-width: 640px) {
  ._m_t_10_mb {
    margin-top: 10px !important; } }

@media only screen and (max-width: 640px) {
  ._m_t_15_mb {
    margin-top: 15px !important; } }

@media only screen and (max-width: 640px) {
  ._m_t_20_mb {
    margin-top: 20px !important; } }

@media only screen and (max-width: 640px) {
  ._m_t_25_mb {
    margin-top: 25px !important; } }

@media only screen and (max-width: 640px) {
  ._m_t_30_mb {
    margin-top: 30px !important; } }

@media only screen and (max-width: 640px) {
  ._m_t_35_mb {
    margin-top: 35px !important; } }

@media only screen and (max-width: 640px) {
  ._m_t_40_mb {
    margin-top: 40px !important; } }

@media only screen and (max-width: 640px) {
  ._m_t_45_mb {
    margin-top: 45px !important; } }

@media only screen and (max-width: 640px) {
  ._m_t_50_mb {
    margin-top: 50px !important; } }

@media only screen and (max-width: 640px) {
  ._m_t_55_mb {
    margin-top: 55px !important; } }

@media only screen and (max-width: 640px) {
  ._m_t_60_mb {
    margin-top: 60px !important; } }

@media only screen and (max-width: 640px) {
  ._m_t_65_mb {
    margin-top: 65px !important; } }

@media only screen and (max-width: 640px) {
  ._m_t_70_mb {
    margin-top: 70px !important; } }

@media only screen and (max-width: 640px) {
  ._m_t_75_mb {
    margin-top: 75px !important; } }

@media only screen and (max-width: 640px) {
  ._m_t_80_mb {
    margin-top: 80px !important; } }

@media only screen and (max-width: 640px) {
  ._m_t_85_mb {
    margin-top: 85px !important; } }

@media only screen and (max-width: 640px) {
  ._m_t_90_mb {
    margin-top: 90px !important; } }

@media only screen and (max-width: 640px) {
  ._m_t_95_mb {
    margin-top: 95px !important; } }

@media only screen and (max-width: 640px) {
  ._m_t_100_mb {
    margin-top: 100px !important; } }

@media only screen and (max-width: 640px) {
  ._m_t_105_mb {
    margin-top: 105px !important; } }

@media only screen and (max-width: 640px) {
  ._m_t_110_mb {
    margin-top: 110px !important; } }

@media only screen and (max-width: 640px) {
  ._m_t_115_mb {
    margin-top: 115px !important; } }

@media only screen and (max-width: 640px) {
  ._m_t_120_mb {
    margin-top: 120px !important; } }

@media only screen and (max-width: 640px) {
  ._m_t_125_mb {
    margin-top: 125px !important; } }

@media only screen and (max-width: 640px) {
  ._m_t_130_mb {
    margin-top: 130px !important; } }

@media only screen and (max-width: 640px) {
  ._m_t_135_mb {
    margin-top: 135px !important; } }

@media only screen and (max-width: 640px) {
  ._m_t_140_mb {
    margin-top: 140px !important; } }

@media only screen and (max-width: 640px) {
  ._m_t_145_mb {
    margin-top: 145px !important; } }

@media only screen and (max-width: 640px) {
  ._m_t_150_mb {
    margin-top: 150px !important; } }

@media only screen and (max-width: 640px) {
  ._m_t_155_mb {
    margin-top: 155px !important; } }

@media only screen and (max-width: 640px) {
  ._m_t_160_mb {
    margin-top: 160px !important; } }

@media only screen and (max-width: 640px) {
  ._m_t_165_mb {
    margin-top: 165px !important; } }

@media only screen and (max-width: 640px) {
  ._m_t_170_mb {
    margin-top: 170px !important; } }

@media only screen and (max-width: 640px) {
  ._m_t_175_mb {
    margin-top: 175px !important; } }

@media only screen and (max-width: 640px) {
  ._m_t_180_mb {
    margin-top: 180px !important; } }

@media only screen and (max-width: 640px) {
  ._m_t_185_mb {
    margin-top: 185px !important; } }

@media only screen and (max-width: 640px) {
  ._m_t_190_mb {
    margin-top: 190px !important; } }

@media only screen and (max-width: 640px) {
  ._m_t_195_mb {
    margin-top: 195px !important; } }

@media only screen and (max-width: 640px) {
  ._m_t_200_mb {
    margin-top: 200px !important; } }

@media only screen and (max-width: 640px) {
  ._m_t_205_mb {
    margin-top: 205px !important; } }

@media only screen and (max-width: 640px) {
  ._m_t_210_mb {
    margin-top: 210px !important; } }

@media only screen and (max-width: 640px) {
  ._m_t_215_mb {
    margin-top: 215px !important; } }

@media only screen and (max-width: 640px) {
  ._m_t_220_mb {
    margin-top: 220px !important; } }

@media only screen and (max-width: 640px) {
  ._m_t_225_mb {
    margin-top: 225px !important; } }

@media only screen and (max-width: 640px) {
  ._m_t_230_mb {
    margin-top: 230px !important; } }

@media only screen and (max-width: 640px) {
  ._m_t_235_mb {
    margin-top: 235px !important; } }

@media only screen and (max-width: 640px) {
  ._m_t_240_mb {
    margin-top: 240px !important; } }

@media only screen and (max-width: 640px) {
  ._m_t_245_mb {
    margin-top: 245px !important; } }

@media only screen and (max-width: 640px) {
  ._m_t_250_mb {
    margin-top: 250px !important; } }

@media only screen and (max-width: 640px) {
  ._m_t_255_mb {
    margin-top: 255px !important; } }

@media only screen and (max-width: 640px) {
  ._m_t_260_mb {
    margin-top: 260px !important; } }

@media only screen and (max-width: 640px) {
  ._m_t_265_mb {
    margin-top: 265px !important; } }

@media only screen and (max-width: 640px) {
  ._m_t_270_mb {
    margin-top: 270px !important; } }

@media only screen and (max-width: 640px) {
  ._m_t_275_mb {
    margin-top: 275px !important; } }

@media only screen and (max-width: 640px) {
  ._m_t_280_mb {
    margin-top: 280px !important; } }

@media only screen and (max-width: 640px) {
  ._m_t_285_mb {
    margin-top: 285px !important; } }

@media only screen and (max-width: 640px) {
  ._m_t_290_mb {
    margin-top: 290px !important; } }

@media only screen and (max-width: 640px) {
  ._m_t_295_mb {
    margin-top: 295px !important; } }

@media only screen and (max-width: 640px) {
  ._m_t_300_mb {
    margin-top: 300px !important; } }

@media only screen and (max-width: 640px) {
  ._m_t_305_mb {
    margin-top: 305px !important; } }

@media only screen and (max-width: 640px) {
  ._m_t_310_mb {
    margin-top: 310px !important; } }

@media only screen and (max-width: 640px) {
  ._m_t_315_mb {
    margin-top: 315px !important; } }

@media only screen and (max-width: 640px) {
  ._m_t_320_mb {
    margin-top: 320px !important; } }

@media only screen and (max-width: 640px) {
  ._m_t_325_mb {
    margin-top: 325px !important; } }

@media only screen and (max-width: 640px) {
  ._m_t_330_mb {
    margin-top: 330px !important; } }

@media only screen and (max-width: 640px) {
  ._m_t_335_mb {
    margin-top: 335px !important; } }

@media only screen and (max-width: 640px) {
  ._m_t_340_mb {
    margin-top: 340px !important; } }

@media only screen and (max-width: 640px) {
  ._m_t_345_mb {
    margin-top: 345px !important; } }

@media only screen and (max-width: 640px) {
  ._m_t_350_mb {
    margin-top: 350px !important; } }

@media only screen and (max-width: 640px) {
  ._m_t_355_mb {
    margin-top: 355px !important; } }

@media only screen and (max-width: 640px) {
  ._m_t_360_mb {
    margin-top: 360px !important; } }

@media only screen and (max-width: 640px) {
  ._m_t_365_mb {
    margin-top: 365px !important; } }

@media only screen and (max-width: 640px) {
  ._m_t_370_mb {
    margin-top: 370px !important; } }

@media only screen and (max-width: 640px) {
  ._m_t_375_mb {
    margin-top: 375px !important; } }

@media only screen and (max-width: 640px) {
  ._m_t_380_mb {
    margin-top: 380px !important; } }

@media only screen and (max-width: 640px) {
  ._m_t_385_mb {
    margin-top: 385px !important; } }

@media only screen and (max-width: 640px) {
  ._m_t_390_mb {
    margin-top: 390px !important; } }

@media only screen and (max-width: 640px) {
  ._m_t_395_mb {
    margin-top: 395px !important; } }

@media only screen and (max-width: 640px) {
  ._m_t_400_mb {
    margin-top: 400px !important; } }

._m_b_0 {
  margin-bottom: 0px !important; }

._m_b_5 {
  margin-bottom: 5px !important; }

._m_b_10 {
  margin-bottom: 10px !important; }

._m_b_15 {
  margin-bottom: 15px !important; }

._m_b_20 {
  margin-bottom: 20px !important; }

._m_b_25 {
  margin-bottom: 25px !important; }

._m_b_30 {
  margin-bottom: 30px !important; }

._m_b_35 {
  margin-bottom: 35px !important; }

._m_b_40 {
  margin-bottom: 40px !important; }

._m_b_45 {
  margin-bottom: 45px !important; }

._m_b_50 {
  margin-bottom: 50px !important; }

._m_b_55 {
  margin-bottom: 55px !important; }

._m_b_60 {
  margin-bottom: 60px !important; }

._m_b_65 {
  margin-bottom: 65px !important; }

._m_b_70 {
  margin-bottom: 70px !important; }

._m_b_75 {
  margin-bottom: 75px !important; }

._m_b_80 {
  margin-bottom: 80px !important; }

._m_b_85 {
  margin-bottom: 85px !important; }

._m_b_90 {
  margin-bottom: 90px !important; }

._m_b_95 {
  margin-bottom: 95px !important; }

._m_b_100 {
  margin-bottom: 100px !important; }

._m_b_105 {
  margin-bottom: 105px !important; }

._m_b_110 {
  margin-bottom: 110px !important; }

._m_b_115 {
  margin-bottom: 115px !important; }

._m_b_120 {
  margin-bottom: 120px !important; }

._m_b_125 {
  margin-bottom: 125px !important; }

._m_b_130 {
  margin-bottom: 130px !important; }

._m_b_135 {
  margin-bottom: 135px !important; }

._m_b_140 {
  margin-bottom: 140px !important; }

._m_b_145 {
  margin-bottom: 145px !important; }

._m_b_150 {
  margin-bottom: 150px !important; }

._m_b_155 {
  margin-bottom: 155px !important; }

._m_b_160 {
  margin-bottom: 160px !important; }

._m_b_165 {
  margin-bottom: 165px !important; }

._m_b_170 {
  margin-bottom: 170px !important; }

._m_b_175 {
  margin-bottom: 175px !important; }

._m_b_180 {
  margin-bottom: 180px !important; }

._m_b_185 {
  margin-bottom: 185px !important; }

._m_b_190 {
  margin-bottom: 190px !important; }

._m_b_195 {
  margin-bottom: 195px !important; }

._m_b_200 {
  margin-bottom: 200px !important; }

._m_b_205 {
  margin-bottom: 205px !important; }

._m_b_210 {
  margin-bottom: 210px !important; }

._m_b_215 {
  margin-bottom: 215px !important; }

._m_b_220 {
  margin-bottom: 220px !important; }

._m_b_225 {
  margin-bottom: 225px !important; }

._m_b_230 {
  margin-bottom: 230px !important; }

._m_b_235 {
  margin-bottom: 235px !important; }

._m_b_240 {
  margin-bottom: 240px !important; }

._m_b_245 {
  margin-bottom: 245px !important; }

._m_b_250 {
  margin-bottom: 250px !important; }

._m_b_255 {
  margin-bottom: 255px !important; }

._m_b_260 {
  margin-bottom: 260px !important; }

._m_b_265 {
  margin-bottom: 265px !important; }

._m_b_270 {
  margin-bottom: 270px !important; }

._m_b_275 {
  margin-bottom: 275px !important; }

._m_b_280 {
  margin-bottom: 280px !important; }

._m_b_285 {
  margin-bottom: 285px !important; }

._m_b_290 {
  margin-bottom: 290px !important; }

._m_b_295 {
  margin-bottom: 295px !important; }

._m_b_300 {
  margin-bottom: 300px !important; }

._m_b_305 {
  margin-bottom: 305px !important; }

._m_b_310 {
  margin-bottom: 310px !important; }

._m_b_315 {
  margin-bottom: 315px !important; }

._m_b_320 {
  margin-bottom: 320px !important; }

._m_b_325 {
  margin-bottom: 325px !important; }

._m_b_330 {
  margin-bottom: 330px !important; }

._m_b_335 {
  margin-bottom: 335px !important; }

._m_b_340 {
  margin-bottom: 340px !important; }

._m_b_345 {
  margin-bottom: 345px !important; }

._m_b_350 {
  margin-bottom: 350px !important; }

._m_b_355 {
  margin-bottom: 355px !important; }

._m_b_360 {
  margin-bottom: 360px !important; }

._m_b_365 {
  margin-bottom: 365px !important; }

._m_b_370 {
  margin-bottom: 370px !important; }

._m_b_375 {
  margin-bottom: 375px !important; }

._m_b_380 {
  margin-bottom: 380px !important; }

._m_b_385 {
  margin-bottom: 385px !important; }

._m_b_390 {
  margin-bottom: 390px !important; }

._m_b_395 {
  margin-bottom: 395px !important; }

._m_b_400 {
  margin-bottom: 400px !important; }

@media only screen and (max-width: 1250px) {
  ._m_t_0_dsm {
    margin-bottom: 0px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_5_dsm {
    margin-bottom: 5px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_10_dsm {
    margin-bottom: 10px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_15_dsm {
    margin-bottom: 15px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_20_dsm {
    margin-bottom: 20px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_25_dsm {
    margin-bottom: 25px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_30_dsm {
    margin-bottom: 30px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_35_dsm {
    margin-bottom: 35px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_40_dsm {
    margin-bottom: 40px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_45_dsm {
    margin-bottom: 45px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_50_dsm {
    margin-bottom: 50px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_55_dsm {
    margin-bottom: 55px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_60_dsm {
    margin-bottom: 60px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_65_dsm {
    margin-bottom: 65px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_70_dsm {
    margin-bottom: 70px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_75_dsm {
    margin-bottom: 75px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_80_dsm {
    margin-bottom: 80px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_85_dsm {
    margin-bottom: 85px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_90_dsm {
    margin-bottom: 90px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_95_dsm {
    margin-bottom: 95px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_100_dsm {
    margin-bottom: 100px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_105_dsm {
    margin-bottom: 105px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_110_dsm {
    margin-bottom: 110px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_115_dsm {
    margin-bottom: 115px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_120_dsm {
    margin-bottom: 120px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_125_dsm {
    margin-bottom: 125px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_130_dsm {
    margin-bottom: 130px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_135_dsm {
    margin-bottom: 135px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_140_dsm {
    margin-bottom: 140px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_145_dsm {
    margin-bottom: 145px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_150_dsm {
    margin-bottom: 150px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_155_dsm {
    margin-bottom: 155px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_160_dsm {
    margin-bottom: 160px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_165_dsm {
    margin-bottom: 165px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_170_dsm {
    margin-bottom: 170px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_175_dsm {
    margin-bottom: 175px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_180_dsm {
    margin-bottom: 180px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_185_dsm {
    margin-bottom: 185px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_190_dsm {
    margin-bottom: 190px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_195_dsm {
    margin-bottom: 195px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_200_dsm {
    margin-bottom: 200px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_205_dsm {
    margin-bottom: 205px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_210_dsm {
    margin-bottom: 210px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_215_dsm {
    margin-bottom: 215px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_220_dsm {
    margin-bottom: 220px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_225_dsm {
    margin-bottom: 225px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_230_dsm {
    margin-bottom: 230px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_235_dsm {
    margin-bottom: 235px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_240_dsm {
    margin-bottom: 240px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_245_dsm {
    margin-bottom: 245px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_250_dsm {
    margin-bottom: 250px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_255_dsm {
    margin-bottom: 255px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_260_dsm {
    margin-bottom: 260px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_265_dsm {
    margin-bottom: 265px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_270_dsm {
    margin-bottom: 270px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_275_dsm {
    margin-bottom: 275px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_280_dsm {
    margin-bottom: 280px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_285_dsm {
    margin-bottom: 285px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_290_dsm {
    margin-bottom: 290px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_295_dsm {
    margin-bottom: 295px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_300_dsm {
    margin-bottom: 300px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_305_dsm {
    margin-bottom: 305px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_310_dsm {
    margin-bottom: 310px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_315_dsm {
    margin-bottom: 315px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_320_dsm {
    margin-bottom: 320px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_325_dsm {
    margin-bottom: 325px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_330_dsm {
    margin-bottom: 330px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_335_dsm {
    margin-bottom: 335px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_340_dsm {
    margin-bottom: 340px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_345_dsm {
    margin-bottom: 345px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_350_dsm {
    margin-bottom: 350px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_355_dsm {
    margin-bottom: 355px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_360_dsm {
    margin-bottom: 360px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_365_dsm {
    margin-bottom: 365px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_370_dsm {
    margin-bottom: 370px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_375_dsm {
    margin-bottom: 375px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_380_dsm {
    margin-bottom: 380px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_385_dsm {
    margin-bottom: 385px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_390_dsm {
    margin-bottom: 390px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_395_dsm {
    margin-bottom: 395px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_400_dsm {
    margin-bottom: 400px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_b_0_tb {
    margin-bottom: 0px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_b_5_tb {
    margin-bottom: 5px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_b_10_tb {
    margin-bottom: 10px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_b_15_tb {
    margin-bottom: 15px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_b_20_tb {
    margin-bottom: 20px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_b_25_tb {
    margin-bottom: 25px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_b_30_tb {
    margin-bottom: 30px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_b_35_tb {
    margin-bottom: 35px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_b_40_tb {
    margin-bottom: 40px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_b_45_tb {
    margin-bottom: 45px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_b_50_tb {
    margin-bottom: 50px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_b_55_tb {
    margin-bottom: 55px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_b_60_tb {
    margin-bottom: 60px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_b_65_tb {
    margin-bottom: 65px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_b_70_tb {
    margin-bottom: 70px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_b_75_tb {
    margin-bottom: 75px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_b_80_tb {
    margin-bottom: 80px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_b_85_tb {
    margin-bottom: 85px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_b_90_tb {
    margin-bottom: 90px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_b_95_tb {
    margin-bottom: 95px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_b_100_tb {
    margin-bottom: 100px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_b_105_tb {
    margin-bottom: 105px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_b_110_tb {
    margin-bottom: 110px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_b_115_tb {
    margin-bottom: 115px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_b_120_tb {
    margin-bottom: 120px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_b_125_tb {
    margin-bottom: 125px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_b_130_tb {
    margin-bottom: 130px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_b_135_tb {
    margin-bottom: 135px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_b_140_tb {
    margin-bottom: 140px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_b_145_tb {
    margin-bottom: 145px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_b_150_tb {
    margin-bottom: 150px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_b_155_tb {
    margin-bottom: 155px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_b_160_tb {
    margin-bottom: 160px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_b_165_tb {
    margin-bottom: 165px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_b_170_tb {
    margin-bottom: 170px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_b_175_tb {
    margin-bottom: 175px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_b_180_tb {
    margin-bottom: 180px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_b_185_tb {
    margin-bottom: 185px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_b_190_tb {
    margin-bottom: 190px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_b_195_tb {
    margin-bottom: 195px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_b_200_tb {
    margin-bottom: 200px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_b_205_tb {
    margin-bottom: 205px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_b_210_tb {
    margin-bottom: 210px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_b_215_tb {
    margin-bottom: 215px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_b_220_tb {
    margin-bottom: 220px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_b_225_tb {
    margin-bottom: 225px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_b_230_tb {
    margin-bottom: 230px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_b_235_tb {
    margin-bottom: 235px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_b_240_tb {
    margin-bottom: 240px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_b_245_tb {
    margin-bottom: 245px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_b_250_tb {
    margin-bottom: 250px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_b_255_tb {
    margin-bottom: 255px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_b_260_tb {
    margin-bottom: 260px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_b_265_tb {
    margin-bottom: 265px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_b_270_tb {
    margin-bottom: 270px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_b_275_tb {
    margin-bottom: 275px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_b_280_tb {
    margin-bottom: 280px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_b_285_tb {
    margin-bottom: 285px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_b_290_tb {
    margin-bottom: 290px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_b_295_tb {
    margin-bottom: 295px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_b_300_tb {
    margin-bottom: 300px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_b_305_tb {
    margin-bottom: 305px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_b_310_tb {
    margin-bottom: 310px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_b_315_tb {
    margin-bottom: 315px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_b_320_tb {
    margin-bottom: 320px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_b_325_tb {
    margin-bottom: 325px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_b_330_tb {
    margin-bottom: 330px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_b_335_tb {
    margin-bottom: 335px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_b_340_tb {
    margin-bottom: 340px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_b_345_tb {
    margin-bottom: 345px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_b_350_tb {
    margin-bottom: 350px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_b_355_tb {
    margin-bottom: 355px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_b_360_tb {
    margin-bottom: 360px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_b_365_tb {
    margin-bottom: 365px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_b_370_tb {
    margin-bottom: 370px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_b_375_tb {
    margin-bottom: 375px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_b_380_tb {
    margin-bottom: 380px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_b_385_tb {
    margin-bottom: 385px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_b_390_tb {
    margin-bottom: 390px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_b_395_tb {
    margin-bottom: 395px !important; } }

@media only screen and (max-width: 1230px) {
  ._m_b_400_tb {
    margin-bottom: 400px !important; } }

@media only screen and (max-width: 640px) {
  ._m_b_0_mb {
    margin-bottom: 0px !important; } }

@media only screen and (max-width: 640px) {
  ._m_b_5_mb {
    margin-bottom: 5px !important; } }

@media only screen and (max-width: 640px) {
  ._m_b_10_mb {
    margin-bottom: 10px !important; } }

@media only screen and (max-width: 640px) {
  ._m_b_15_mb {
    margin-bottom: 15px !important; } }

@media only screen and (max-width: 640px) {
  ._m_b_20_mb {
    margin-bottom: 20px !important; } }

@media only screen and (max-width: 640px) {
  ._m_b_25_mb {
    margin-bottom: 25px !important; } }

@media only screen and (max-width: 640px) {
  ._m_b_30_mb {
    margin-bottom: 30px !important; } }

@media only screen and (max-width: 640px) {
  ._m_b_35_mb {
    margin-bottom: 35px !important; } }

@media only screen and (max-width: 640px) {
  ._m_b_40_mb {
    margin-bottom: 40px !important; } }

@media only screen and (max-width: 640px) {
  ._m_b_45_mb {
    margin-bottom: 45px !important; } }

@media only screen and (max-width: 640px) {
  ._m_b_50_mb {
    margin-bottom: 50px !important; } }

@media only screen and (max-width: 640px) {
  ._m_b_55_mb {
    margin-bottom: 55px !important; } }

@media only screen and (max-width: 640px) {
  ._m_b_60_mb {
    margin-bottom: 60px !important; } }

@media only screen and (max-width: 640px) {
  ._m_b_65_mb {
    margin-bottom: 65px !important; } }

@media only screen and (max-width: 640px) {
  ._m_b_70_mb {
    margin-bottom: 70px !important; } }

@media only screen and (max-width: 640px) {
  ._m_b_75_mb {
    margin-bottom: 75px !important; } }

@media only screen and (max-width: 640px) {
  ._m_b_80_mb {
    margin-bottom: 80px !important; } }

@media only screen and (max-width: 640px) {
  ._m_b_85_mb {
    margin-bottom: 85px !important; } }

@media only screen and (max-width: 640px) {
  ._m_b_90_mb {
    margin-bottom: 90px !important; } }

@media only screen and (max-width: 640px) {
  ._m_b_95_mb {
    margin-bottom: 95px !important; } }

@media only screen and (max-width: 640px) {
  ._m_b_100_mb {
    margin-bottom: 100px !important; } }

@media only screen and (max-width: 640px) {
  ._m_b_105_mb {
    margin-bottom: 105px !important; } }

@media only screen and (max-width: 640px) {
  ._m_b_110_mb {
    margin-bottom: 110px !important; } }

@media only screen and (max-width: 640px) {
  ._m_b_115_mb {
    margin-bottom: 115px !important; } }

@media only screen and (max-width: 640px) {
  ._m_b_120_mb {
    margin-bottom: 120px !important; } }

@media only screen and (max-width: 640px) {
  ._m_b_125_mb {
    margin-bottom: 125px !important; } }

@media only screen and (max-width: 640px) {
  ._m_b_130_mb {
    margin-bottom: 130px !important; } }

@media only screen and (max-width: 640px) {
  ._m_b_135_mb {
    margin-bottom: 135px !important; } }

@media only screen and (max-width: 640px) {
  ._m_b_140_mb {
    margin-bottom: 140px !important; } }

@media only screen and (max-width: 640px) {
  ._m_b_145_mb {
    margin-bottom: 145px !important; } }

@media only screen and (max-width: 640px) {
  ._m_b_150_mb {
    margin-bottom: 150px !important; } }

@media only screen and (max-width: 640px) {
  ._m_b_155_mb {
    margin-bottom: 155px !important; } }

@media only screen and (max-width: 640px) {
  ._m_b_160_mb {
    margin-bottom: 160px !important; } }

@media only screen and (max-width: 640px) {
  ._m_b_165_mb {
    margin-bottom: 165px !important; } }

@media only screen and (max-width: 640px) {
  ._m_b_170_mb {
    margin-bottom: 170px !important; } }

@media only screen and (max-width: 640px) {
  ._m_b_175_mb {
    margin-bottom: 175px !important; } }

@media only screen and (max-width: 640px) {
  ._m_b_180_mb {
    margin-bottom: 180px !important; } }

@media only screen and (max-width: 640px) {
  ._m_b_185_mb {
    margin-bottom: 185px !important; } }

@media only screen and (max-width: 640px) {
  ._m_b_190_mb {
    margin-bottom: 190px !important; } }

@media only screen and (max-width: 640px) {
  ._m_b_195_mb {
    margin-bottom: 195px !important; } }

@media only screen and (max-width: 640px) {
  ._m_b_200_mb {
    margin-bottom: 200px !important; } }

@media only screen and (max-width: 640px) {
  ._m_b_205_mb {
    margin-bottom: 205px !important; } }

@media only screen and (max-width: 640px) {
  ._m_b_210_mb {
    margin-bottom: 210px !important; } }

@media only screen and (max-width: 640px) {
  ._m_b_215_mb {
    margin-bottom: 215px !important; } }

@media only screen and (max-width: 640px) {
  ._m_b_220_mb {
    margin-bottom: 220px !important; } }

@media only screen and (max-width: 640px) {
  ._m_b_225_mb {
    margin-bottom: 225px !important; } }

@media only screen and (max-width: 640px) {
  ._m_b_230_mb {
    margin-bottom: 230px !important; } }

@media only screen and (max-width: 640px) {
  ._m_b_235_mb {
    margin-bottom: 235px !important; } }

@media only screen and (max-width: 640px) {
  ._m_b_240_mb {
    margin-bottom: 240px !important; } }

@media only screen and (max-width: 640px) {
  ._m_b_245_mb {
    margin-bottom: 245px !important; } }

@media only screen and (max-width: 640px) {
  ._m_b_250_mb {
    margin-bottom: 250px !important; } }

@media only screen and (max-width: 640px) {
  ._m_b_255_mb {
    margin-bottom: 255px !important; } }

@media only screen and (max-width: 640px) {
  ._m_b_260_mb {
    margin-bottom: 260px !important; } }

@media only screen and (max-width: 640px) {
  ._m_b_265_mb {
    margin-bottom: 265px !important; } }

@media only screen and (max-width: 640px) {
  ._m_b_270_mb {
    margin-bottom: 270px !important; } }

@media only screen and (max-width: 640px) {
  ._m_b_275_mb {
    margin-bottom: 275px !important; } }

@media only screen and (max-width: 640px) {
  ._m_b_280_mb {
    margin-bottom: 280px !important; } }

@media only screen and (max-width: 640px) {
  ._m_b_285_mb {
    margin-bottom: 285px !important; } }

@media only screen and (max-width: 640px) {
  ._m_b_290_mb {
    margin-bottom: 290px !important; } }

@media only screen and (max-width: 640px) {
  ._m_b_295_mb {
    margin-bottom: 295px !important; } }

@media only screen and (max-width: 640px) {
  ._m_b_300_mb {
    margin-bottom: 300px !important; } }

@media only screen and (max-width: 640px) {
  ._m_b_305_mb {
    margin-bottom: 305px !important; } }

@media only screen and (max-width: 640px) {
  ._m_b_310_mb {
    margin-bottom: 310px !important; } }

@media only screen and (max-width: 640px) {
  ._m_b_315_mb {
    margin-bottom: 315px !important; } }

@media only screen and (max-width: 640px) {
  ._m_b_320_mb {
    margin-bottom: 320px !important; } }

@media only screen and (max-width: 640px) {
  ._m_b_325_mb {
    margin-bottom: 325px !important; } }

@media only screen and (max-width: 640px) {
  ._m_b_330_mb {
    margin-bottom: 330px !important; } }

@media only screen and (max-width: 640px) {
  ._m_b_335_mb {
    margin-bottom: 335px !important; } }

@media only screen and (max-width: 640px) {
  ._m_b_340_mb {
    margin-bottom: 340px !important; } }

@media only screen and (max-width: 640px) {
  ._m_b_345_mb {
    margin-bottom: 345px !important; } }

@media only screen and (max-width: 640px) {
  ._m_b_350_mb {
    margin-bottom: 350px !important; } }

@media only screen and (max-width: 640px) {
  ._m_b_355_mb {
    margin-bottom: 355px !important; } }

@media only screen and (max-width: 640px) {
  ._m_b_360_mb {
    margin-bottom: 360px !important; } }

@media only screen and (max-width: 640px) {
  ._m_b_365_mb {
    margin-bottom: 365px !important; } }

@media only screen and (max-width: 640px) {
  ._m_b_370_mb {
    margin-bottom: 370px !important; } }

@media only screen and (max-width: 640px) {
  ._m_b_375_mb {
    margin-bottom: 375px !important; } }

@media only screen and (max-width: 640px) {
  ._m_b_380_mb {
    margin-bottom: 380px !important; } }

@media only screen and (max-width: 640px) {
  ._m_b_385_mb {
    margin-bottom: 385px !important; } }

@media only screen and (max-width: 640px) {
  ._m_b_390_mb {
    margin-bottom: 390px !important; } }

@media only screen and (max-width: 640px) {
  ._m_b_395_mb {
    margin-bottom: 395px !important; } }

@media only screen and (max-width: 640px) {
  ._m_b_400_mb {
    margin-bottom: 400px !important; } }

._p_t_0 {
  padding-top: 0px !important; }

._p_t_5 {
  padding-top: 5px !important; }

._p_t_10 {
  padding-top: 10px !important; }

._p_t_15 {
  padding-top: 15px !important; }

._p_t_20 {
  padding-top: 20px !important; }

._p_t_25 {
  padding-top: 25px !important; }

._p_t_30 {
  padding-top: 30px !important; }

._p_t_35 {
  padding-top: 35px !important; }

._p_t_40 {
  padding-top: 40px !important; }

._p_t_45 {
  padding-top: 45px !important; }

._p_t_50 {
  padding-top: 50px !important; }

._p_t_55 {
  padding-top: 55px !important; }

._p_t_60 {
  padding-top: 60px !important; }

._p_t_65 {
  padding-top: 65px !important; }

._p_t_70 {
  padding-top: 70px !important; }

._p_t_75 {
  padding-top: 75px !important; }

._p_t_80 {
  padding-top: 80px !important; }

._p_t_85 {
  padding-top: 85px !important; }

._p_t_90 {
  padding-top: 90px !important; }

._p_t_95 {
  padding-top: 95px !important; }

._p_t_100 {
  padding-top: 100px !important; }

._p_t_105 {
  padding-top: 105px !important; }

._p_t_110 {
  padding-top: 110px !important; }

._p_t_115 {
  padding-top: 115px !important; }

._p_t_120 {
  padding-top: 120px !important; }

._p_t_125 {
  padding-top: 125px !important; }

._p_t_130 {
  padding-top: 130px !important; }

._p_t_135 {
  padding-top: 135px !important; }

._p_t_140 {
  padding-top: 140px !important; }

._p_t_145 {
  padding-top: 145px !important; }

._p_t_150 {
  padding-top: 150px !important; }

._p_t_155 {
  padding-top: 155px !important; }

._p_t_160 {
  padding-top: 160px !important; }

._p_t_165 {
  padding-top: 165px !important; }

._p_t_170 {
  padding-top: 170px !important; }

._p_t_175 {
  padding-top: 175px !important; }

._p_t_180 {
  padding-top: 180px !important; }

._p_t_185 {
  padding-top: 185px !important; }

._p_t_190 {
  padding-top: 190px !important; }

._p_t_195 {
  padding-top: 195px !important; }

._p_t_200 {
  padding-top: 200px !important; }

._p_t_205 {
  padding-top: 205px !important; }

._p_t_210 {
  padding-top: 210px !important; }

._p_t_215 {
  padding-top: 215px !important; }

._p_t_220 {
  padding-top: 220px !important; }

._p_t_225 {
  padding-top: 225px !important; }

._p_t_230 {
  padding-top: 230px !important; }

._p_t_235 {
  padding-top: 235px !important; }

._p_t_240 {
  padding-top: 240px !important; }

._p_t_245 {
  padding-top: 245px !important; }

._p_t_250 {
  padding-top: 250px !important; }

._p_t_255 {
  padding-top: 255px !important; }

._p_t_260 {
  padding-top: 260px !important; }

._p_t_265 {
  padding-top: 265px !important; }

._p_t_270 {
  padding-top: 270px !important; }

._p_t_275 {
  padding-top: 275px !important; }

._p_t_280 {
  padding-top: 280px !important; }

._p_t_285 {
  padding-top: 285px !important; }

._p_t_290 {
  padding-top: 290px !important; }

._p_t_295 {
  padding-top: 295px !important; }

._p_t_300 {
  padding-top: 300px !important; }

._p_t_305 {
  padding-top: 305px !important; }

._p_t_310 {
  padding-top: 310px !important; }

._p_t_315 {
  padding-top: 315px !important; }

._p_t_320 {
  padding-top: 320px !important; }

._p_t_325 {
  padding-top: 325px !important; }

._p_t_330 {
  padding-top: 330px !important; }

._p_t_335 {
  padding-top: 335px !important; }

._p_t_340 {
  padding-top: 340px !important; }

._p_t_345 {
  padding-top: 345px !important; }

._p_t_350 {
  padding-top: 350px !important; }

._p_t_355 {
  padding-top: 355px !important; }

._p_t_360 {
  padding-top: 360px !important; }

._p_t_365 {
  padding-top: 365px !important; }

._p_t_370 {
  padding-top: 370px !important; }

._p_t_375 {
  padding-top: 375px !important; }

._p_t_380 {
  padding-top: 380px !important; }

._p_t_385 {
  padding-top: 385px !important; }

._p_t_390 {
  padding-top: 390px !important; }

._p_t_395 {
  padding-top: 395px !important; }

._p_t_400 {
  padding-top: 400px !important; }

@media only screen and (max-width: 1250px) {
  ._m_t_0_dsm {
    padding-top: 0px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_5_dsm {
    padding-top: 5px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_10_dsm {
    padding-top: 10px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_15_dsm {
    padding-top: 15px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_20_dsm {
    padding-top: 20px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_25_dsm {
    padding-top: 25px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_30_dsm {
    padding-top: 30px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_35_dsm {
    padding-top: 35px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_40_dsm {
    padding-top: 40px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_45_dsm {
    padding-top: 45px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_50_dsm {
    padding-top: 50px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_55_dsm {
    padding-top: 55px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_60_dsm {
    padding-top: 60px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_65_dsm {
    padding-top: 65px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_70_dsm {
    padding-top: 70px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_75_dsm {
    padding-top: 75px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_80_dsm {
    padding-top: 80px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_85_dsm {
    padding-top: 85px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_90_dsm {
    padding-top: 90px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_95_dsm {
    padding-top: 95px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_100_dsm {
    padding-top: 100px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_105_dsm {
    padding-top: 105px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_110_dsm {
    padding-top: 110px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_115_dsm {
    padding-top: 115px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_120_dsm {
    padding-top: 120px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_125_dsm {
    padding-top: 125px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_130_dsm {
    padding-top: 130px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_135_dsm {
    padding-top: 135px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_140_dsm {
    padding-top: 140px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_145_dsm {
    padding-top: 145px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_150_dsm {
    padding-top: 150px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_155_dsm {
    padding-top: 155px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_160_dsm {
    padding-top: 160px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_165_dsm {
    padding-top: 165px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_170_dsm {
    padding-top: 170px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_175_dsm {
    padding-top: 175px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_180_dsm {
    padding-top: 180px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_185_dsm {
    padding-top: 185px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_190_dsm {
    padding-top: 190px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_195_dsm {
    padding-top: 195px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_200_dsm {
    padding-top: 200px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_205_dsm {
    padding-top: 205px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_210_dsm {
    padding-top: 210px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_215_dsm {
    padding-top: 215px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_220_dsm {
    padding-top: 220px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_225_dsm {
    padding-top: 225px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_230_dsm {
    padding-top: 230px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_235_dsm {
    padding-top: 235px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_240_dsm {
    padding-top: 240px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_245_dsm {
    padding-top: 245px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_250_dsm {
    padding-top: 250px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_255_dsm {
    padding-top: 255px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_260_dsm {
    padding-top: 260px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_265_dsm {
    padding-top: 265px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_270_dsm {
    padding-top: 270px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_275_dsm {
    padding-top: 275px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_280_dsm {
    padding-top: 280px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_285_dsm {
    padding-top: 285px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_290_dsm {
    padding-top: 290px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_295_dsm {
    padding-top: 295px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_300_dsm {
    padding-top: 300px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_305_dsm {
    padding-top: 305px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_310_dsm {
    padding-top: 310px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_315_dsm {
    padding-top: 315px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_320_dsm {
    padding-top: 320px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_325_dsm {
    padding-top: 325px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_330_dsm {
    padding-top: 330px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_335_dsm {
    padding-top: 335px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_340_dsm {
    padding-top: 340px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_345_dsm {
    padding-top: 345px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_350_dsm {
    padding-top: 350px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_355_dsm {
    padding-top: 355px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_360_dsm {
    padding-top: 360px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_365_dsm {
    padding-top: 365px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_370_dsm {
    padding-top: 370px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_375_dsm {
    padding-top: 375px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_380_dsm {
    padding-top: 380px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_385_dsm {
    padding-top: 385px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_390_dsm {
    padding-top: 390px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_395_dsm {
    padding-top: 395px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_400_dsm {
    padding-top: 400px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_t_0_tb {
    padding-top: 0px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_t_5_tb {
    padding-top: 5px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_t_10_tb {
    padding-top: 10px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_t_15_tb {
    padding-top: 15px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_t_20_tb {
    padding-top: 20px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_t_25_tb {
    padding-top: 25px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_t_30_tb {
    padding-top: 30px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_t_35_tb {
    padding-top: 35px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_t_40_tb {
    padding-top: 40px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_t_45_tb {
    padding-top: 45px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_t_50_tb {
    padding-top: 50px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_t_55_tb {
    padding-top: 55px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_t_60_tb {
    padding-top: 60px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_t_65_tb {
    padding-top: 65px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_t_70_tb {
    padding-top: 70px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_t_75_tb {
    padding-top: 75px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_t_80_tb {
    padding-top: 80px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_t_85_tb {
    padding-top: 85px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_t_90_tb {
    padding-top: 90px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_t_95_tb {
    padding-top: 95px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_t_100_tb {
    padding-top: 100px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_t_105_tb {
    padding-top: 105px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_t_110_tb {
    padding-top: 110px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_t_115_tb {
    padding-top: 115px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_t_120_tb {
    padding-top: 120px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_t_125_tb {
    padding-top: 125px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_t_130_tb {
    padding-top: 130px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_t_135_tb {
    padding-top: 135px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_t_140_tb {
    padding-top: 140px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_t_145_tb {
    padding-top: 145px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_t_150_tb {
    padding-top: 150px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_t_155_tb {
    padding-top: 155px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_t_160_tb {
    padding-top: 160px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_t_165_tb {
    padding-top: 165px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_t_170_tb {
    padding-top: 170px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_t_175_tb {
    padding-top: 175px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_t_180_tb {
    padding-top: 180px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_t_185_tb {
    padding-top: 185px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_t_190_tb {
    padding-top: 190px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_t_195_tb {
    padding-top: 195px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_t_200_tb {
    padding-top: 200px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_t_205_tb {
    padding-top: 205px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_t_210_tb {
    padding-top: 210px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_t_215_tb {
    padding-top: 215px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_t_220_tb {
    padding-top: 220px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_t_225_tb {
    padding-top: 225px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_t_230_tb {
    padding-top: 230px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_t_235_tb {
    padding-top: 235px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_t_240_tb {
    padding-top: 240px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_t_245_tb {
    padding-top: 245px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_t_250_tb {
    padding-top: 250px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_t_255_tb {
    padding-top: 255px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_t_260_tb {
    padding-top: 260px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_t_265_tb {
    padding-top: 265px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_t_270_tb {
    padding-top: 270px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_t_275_tb {
    padding-top: 275px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_t_280_tb {
    padding-top: 280px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_t_285_tb {
    padding-top: 285px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_t_290_tb {
    padding-top: 290px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_t_295_tb {
    padding-top: 295px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_t_300_tb {
    padding-top: 300px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_t_305_tb {
    padding-top: 305px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_t_310_tb {
    padding-top: 310px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_t_315_tb {
    padding-top: 315px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_t_320_tb {
    padding-top: 320px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_t_325_tb {
    padding-top: 325px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_t_330_tb {
    padding-top: 330px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_t_335_tb {
    padding-top: 335px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_t_340_tb {
    padding-top: 340px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_t_345_tb {
    padding-top: 345px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_t_350_tb {
    padding-top: 350px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_t_355_tb {
    padding-top: 355px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_t_360_tb {
    padding-top: 360px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_t_365_tb {
    padding-top: 365px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_t_370_tb {
    padding-top: 370px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_t_375_tb {
    padding-top: 375px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_t_380_tb {
    padding-top: 380px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_t_385_tb {
    padding-top: 385px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_t_390_tb {
    padding-top: 390px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_t_395_tb {
    padding-top: 395px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_t_400_tb {
    padding-top: 400px !important; } }

@media only screen and (max-width: 640px) {
  ._p_t_0_mb {
    padding-top: 0px !important; } }

@media only screen and (max-width: 640px) {
  ._p_t_5_mb {
    padding-top: 5px !important; } }

@media only screen and (max-width: 640px) {
  ._p_t_10_mb {
    padding-top: 10px !important; } }

@media only screen and (max-width: 640px) {
  ._p_t_15_mb {
    padding-top: 15px !important; } }

@media only screen and (max-width: 640px) {
  ._p_t_20_mb {
    padding-top: 20px !important; } }

@media only screen and (max-width: 640px) {
  ._p_t_25_mb {
    padding-top: 25px !important; } }

@media only screen and (max-width: 640px) {
  ._p_t_30_mb {
    padding-top: 30px !important; } }

@media only screen and (max-width: 640px) {
  ._p_t_35_mb {
    padding-top: 35px !important; } }

@media only screen and (max-width: 640px) {
  ._p_t_40_mb {
    padding-top: 40px !important; } }

@media only screen and (max-width: 640px) {
  ._p_t_45_mb {
    padding-top: 45px !important; } }

@media only screen and (max-width: 640px) {
  ._p_t_50_mb {
    padding-top: 50px !important; } }

@media only screen and (max-width: 640px) {
  ._p_t_55_mb {
    padding-top: 55px !important; } }

@media only screen and (max-width: 640px) {
  ._p_t_60_mb {
    padding-top: 60px !important; } }

@media only screen and (max-width: 640px) {
  ._p_t_65_mb {
    padding-top: 65px !important; } }

@media only screen and (max-width: 640px) {
  ._p_t_70_mb {
    padding-top: 70px !important; } }

@media only screen and (max-width: 640px) {
  ._p_t_75_mb {
    padding-top: 75px !important; } }

@media only screen and (max-width: 640px) {
  ._p_t_80_mb {
    padding-top: 80px !important; } }

@media only screen and (max-width: 640px) {
  ._p_t_85_mb {
    padding-top: 85px !important; } }

@media only screen and (max-width: 640px) {
  ._p_t_90_mb {
    padding-top: 90px !important; } }

@media only screen and (max-width: 640px) {
  ._p_t_95_mb {
    padding-top: 95px !important; } }

@media only screen and (max-width: 640px) {
  ._p_t_100_mb {
    padding-top: 100px !important; } }

@media only screen and (max-width: 640px) {
  ._p_t_105_mb {
    padding-top: 105px !important; } }

@media only screen and (max-width: 640px) {
  ._p_t_110_mb {
    padding-top: 110px !important; } }

@media only screen and (max-width: 640px) {
  ._p_t_115_mb {
    padding-top: 115px !important; } }

@media only screen and (max-width: 640px) {
  ._p_t_120_mb {
    padding-top: 120px !important; } }

@media only screen and (max-width: 640px) {
  ._p_t_125_mb {
    padding-top: 125px !important; } }

@media only screen and (max-width: 640px) {
  ._p_t_130_mb {
    padding-top: 130px !important; } }

@media only screen and (max-width: 640px) {
  ._p_t_135_mb {
    padding-top: 135px !important; } }

@media only screen and (max-width: 640px) {
  ._p_t_140_mb {
    padding-top: 140px !important; } }

@media only screen and (max-width: 640px) {
  ._p_t_145_mb {
    padding-top: 145px !important; } }

@media only screen and (max-width: 640px) {
  ._p_t_150_mb {
    padding-top: 150px !important; } }

@media only screen and (max-width: 640px) {
  ._p_t_155_mb {
    padding-top: 155px !important; } }

@media only screen and (max-width: 640px) {
  ._p_t_160_mb {
    padding-top: 160px !important; } }

@media only screen and (max-width: 640px) {
  ._p_t_165_mb {
    padding-top: 165px !important; } }

@media only screen and (max-width: 640px) {
  ._p_t_170_mb {
    padding-top: 170px !important; } }

@media only screen and (max-width: 640px) {
  ._p_t_175_mb {
    padding-top: 175px !important; } }

@media only screen and (max-width: 640px) {
  ._p_t_180_mb {
    padding-top: 180px !important; } }

@media only screen and (max-width: 640px) {
  ._p_t_185_mb {
    padding-top: 185px !important; } }

@media only screen and (max-width: 640px) {
  ._p_t_190_mb {
    padding-top: 190px !important; } }

@media only screen and (max-width: 640px) {
  ._p_t_195_mb {
    padding-top: 195px !important; } }

@media only screen and (max-width: 640px) {
  ._p_t_200_mb {
    padding-top: 200px !important; } }

@media only screen and (max-width: 640px) {
  ._p_t_205_mb {
    padding-top: 205px !important; } }

@media only screen and (max-width: 640px) {
  ._p_t_210_mb {
    padding-top: 210px !important; } }

@media only screen and (max-width: 640px) {
  ._p_t_215_mb {
    padding-top: 215px !important; } }

@media only screen and (max-width: 640px) {
  ._p_t_220_mb {
    padding-top: 220px !important; } }

@media only screen and (max-width: 640px) {
  ._p_t_225_mb {
    padding-top: 225px !important; } }

@media only screen and (max-width: 640px) {
  ._p_t_230_mb {
    padding-top: 230px !important; } }

@media only screen and (max-width: 640px) {
  ._p_t_235_mb {
    padding-top: 235px !important; } }

@media only screen and (max-width: 640px) {
  ._p_t_240_mb {
    padding-top: 240px !important; } }

@media only screen and (max-width: 640px) {
  ._p_t_245_mb {
    padding-top: 245px !important; } }

@media only screen and (max-width: 640px) {
  ._p_t_250_mb {
    padding-top: 250px !important; } }

@media only screen and (max-width: 640px) {
  ._p_t_255_mb {
    padding-top: 255px !important; } }

@media only screen and (max-width: 640px) {
  ._p_t_260_mb {
    padding-top: 260px !important; } }

@media only screen and (max-width: 640px) {
  ._p_t_265_mb {
    padding-top: 265px !important; } }

@media only screen and (max-width: 640px) {
  ._p_t_270_mb {
    padding-top: 270px !important; } }

@media only screen and (max-width: 640px) {
  ._p_t_275_mb {
    padding-top: 275px !important; } }

@media only screen and (max-width: 640px) {
  ._p_t_280_mb {
    padding-top: 280px !important; } }

@media only screen and (max-width: 640px) {
  ._p_t_285_mb {
    padding-top: 285px !important; } }

@media only screen and (max-width: 640px) {
  ._p_t_290_mb {
    padding-top: 290px !important; } }

@media only screen and (max-width: 640px) {
  ._p_t_295_mb {
    padding-top: 295px !important; } }

@media only screen and (max-width: 640px) {
  ._p_t_300_mb {
    padding-top: 300px !important; } }

@media only screen and (max-width: 640px) {
  ._p_t_305_mb {
    padding-top: 305px !important; } }

@media only screen and (max-width: 640px) {
  ._p_t_310_mb {
    padding-top: 310px !important; } }

@media only screen and (max-width: 640px) {
  ._p_t_315_mb {
    padding-top: 315px !important; } }

@media only screen and (max-width: 640px) {
  ._p_t_320_mb {
    padding-top: 320px !important; } }

@media only screen and (max-width: 640px) {
  ._p_t_325_mb {
    padding-top: 325px !important; } }

@media only screen and (max-width: 640px) {
  ._p_t_330_mb {
    padding-top: 330px !important; } }

@media only screen and (max-width: 640px) {
  ._p_t_335_mb {
    padding-top: 335px !important; } }

@media only screen and (max-width: 640px) {
  ._p_t_340_mb {
    padding-top: 340px !important; } }

@media only screen and (max-width: 640px) {
  ._p_t_345_mb {
    padding-top: 345px !important; } }

@media only screen and (max-width: 640px) {
  ._p_t_350_mb {
    padding-top: 350px !important; } }

@media only screen and (max-width: 640px) {
  ._p_t_355_mb {
    padding-top: 355px !important; } }

@media only screen and (max-width: 640px) {
  ._p_t_360_mb {
    padding-top: 360px !important; } }

@media only screen and (max-width: 640px) {
  ._p_t_365_mb {
    padding-top: 365px !important; } }

@media only screen and (max-width: 640px) {
  ._p_t_370_mb {
    padding-top: 370px !important; } }

@media only screen and (max-width: 640px) {
  ._p_t_375_mb {
    padding-top: 375px !important; } }

@media only screen and (max-width: 640px) {
  ._p_t_380_mb {
    padding-top: 380px !important; } }

@media only screen and (max-width: 640px) {
  ._p_t_385_mb {
    padding-top: 385px !important; } }

@media only screen and (max-width: 640px) {
  ._p_t_390_mb {
    padding-top: 390px !important; } }

@media only screen and (max-width: 640px) {
  ._p_t_395_mb {
    padding-top: 395px !important; } }

@media only screen and (max-width: 640px) {
  ._p_t_400_mb {
    padding-top: 400px !important; } }

._p_b_0 {
  padding-bottom: 0px !important; }

._p_b_5 {
  padding-bottom: 5px !important; }

._p_b_10 {
  padding-bottom: 10px !important; }

._p_b_15 {
  padding-bottom: 15px !important; }

._p_b_20 {
  padding-bottom: 20px !important; }

._p_b_25 {
  padding-bottom: 25px !important; }

._p_b_30 {
  padding-bottom: 30px !important; }

._p_b_35 {
  padding-bottom: 35px !important; }

._p_b_40 {
  padding-bottom: 40px !important; }

._p_b_45 {
  padding-bottom: 45px !important; }

._p_b_50 {
  padding-bottom: 50px !important; }

._p_b_55 {
  padding-bottom: 55px !important; }

._p_b_60 {
  padding-bottom: 60px !important; }

._p_b_65 {
  padding-bottom: 65px !important; }

._p_b_70 {
  padding-bottom: 70px !important; }

._p_b_75 {
  padding-bottom: 75px !important; }

._p_b_80 {
  padding-bottom: 80px !important; }

._p_b_85 {
  padding-bottom: 85px !important; }

._p_b_90 {
  padding-bottom: 90px !important; }

._p_b_95 {
  padding-bottom: 95px !important; }

._p_b_100 {
  padding-bottom: 100px !important; }

._p_b_105 {
  padding-bottom: 105px !important; }

._p_b_110 {
  padding-bottom: 110px !important; }

._p_b_115 {
  padding-bottom: 115px !important; }

._p_b_120 {
  padding-bottom: 120px !important; }

._p_b_125 {
  padding-bottom: 125px !important; }

._p_b_130 {
  padding-bottom: 130px !important; }

._p_b_135 {
  padding-bottom: 135px !important; }

._p_b_140 {
  padding-bottom: 140px !important; }

._p_b_145 {
  padding-bottom: 145px !important; }

._p_b_150 {
  padding-bottom: 150px !important; }

._p_b_155 {
  padding-bottom: 155px !important; }

._p_b_160 {
  padding-bottom: 160px !important; }

._p_b_165 {
  padding-bottom: 165px !important; }

._p_b_170 {
  padding-bottom: 170px !important; }

._p_b_175 {
  padding-bottom: 175px !important; }

._p_b_180 {
  padding-bottom: 180px !important; }

._p_b_185 {
  padding-bottom: 185px !important; }

._p_b_190 {
  padding-bottom: 190px !important; }

._p_b_195 {
  padding-bottom: 195px !important; }

._p_b_200 {
  padding-bottom: 200px !important; }

._p_b_205 {
  padding-bottom: 205px !important; }

._p_b_210 {
  padding-bottom: 210px !important; }

._p_b_215 {
  padding-bottom: 215px !important; }

._p_b_220 {
  padding-bottom: 220px !important; }

._p_b_225 {
  padding-bottom: 225px !important; }

._p_b_230 {
  padding-bottom: 230px !important; }

._p_b_235 {
  padding-bottom: 235px !important; }

._p_b_240 {
  padding-bottom: 240px !important; }

._p_b_245 {
  padding-bottom: 245px !important; }

._p_b_250 {
  padding-bottom: 250px !important; }

._p_b_255 {
  padding-bottom: 255px !important; }

._p_b_260 {
  padding-bottom: 260px !important; }

._p_b_265 {
  padding-bottom: 265px !important; }

._p_b_270 {
  padding-bottom: 270px !important; }

._p_b_275 {
  padding-bottom: 275px !important; }

._p_b_280 {
  padding-bottom: 280px !important; }

._p_b_285 {
  padding-bottom: 285px !important; }

._p_b_290 {
  padding-bottom: 290px !important; }

._p_b_295 {
  padding-bottom: 295px !important; }

._p_b_300 {
  padding-bottom: 300px !important; }

._p_b_305 {
  padding-bottom: 305px !important; }

._p_b_310 {
  padding-bottom: 310px !important; }

._p_b_315 {
  padding-bottom: 315px !important; }

._p_b_320 {
  padding-bottom: 320px !important; }

._p_b_325 {
  padding-bottom: 325px !important; }

._p_b_330 {
  padding-bottom: 330px !important; }

._p_b_335 {
  padding-bottom: 335px !important; }

._p_b_340 {
  padding-bottom: 340px !important; }

._p_b_345 {
  padding-bottom: 345px !important; }

._p_b_350 {
  padding-bottom: 350px !important; }

._p_b_355 {
  padding-bottom: 355px !important; }

._p_b_360 {
  padding-bottom: 360px !important; }

._p_b_365 {
  padding-bottom: 365px !important; }

._p_b_370 {
  padding-bottom: 370px !important; }

._p_b_375 {
  padding-bottom: 375px !important; }

._p_b_380 {
  padding-bottom: 380px !important; }

._p_b_385 {
  padding-bottom: 385px !important; }

._p_b_390 {
  padding-bottom: 390px !important; }

._p_b_395 {
  padding-bottom: 395px !important; }

._p_b_400 {
  padding-bottom: 400px !important; }

@media only screen and (max-width: 1250px) {
  ._m_t_0_dsm {
    padding-bottom: 0px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_5_dsm {
    padding-bottom: 5px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_10_dsm {
    padding-bottom: 10px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_15_dsm {
    padding-bottom: 15px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_20_dsm {
    padding-bottom: 20px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_25_dsm {
    padding-bottom: 25px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_30_dsm {
    padding-bottom: 30px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_35_dsm {
    padding-bottom: 35px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_40_dsm {
    padding-bottom: 40px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_45_dsm {
    padding-bottom: 45px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_50_dsm {
    padding-bottom: 50px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_55_dsm {
    padding-bottom: 55px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_60_dsm {
    padding-bottom: 60px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_65_dsm {
    padding-bottom: 65px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_70_dsm {
    padding-bottom: 70px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_75_dsm {
    padding-bottom: 75px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_80_dsm {
    padding-bottom: 80px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_85_dsm {
    padding-bottom: 85px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_90_dsm {
    padding-bottom: 90px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_95_dsm {
    padding-bottom: 95px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_100_dsm {
    padding-bottom: 100px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_105_dsm {
    padding-bottom: 105px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_110_dsm {
    padding-bottom: 110px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_115_dsm {
    padding-bottom: 115px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_120_dsm {
    padding-bottom: 120px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_125_dsm {
    padding-bottom: 125px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_130_dsm {
    padding-bottom: 130px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_135_dsm {
    padding-bottom: 135px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_140_dsm {
    padding-bottom: 140px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_145_dsm {
    padding-bottom: 145px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_150_dsm {
    padding-bottom: 150px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_155_dsm {
    padding-bottom: 155px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_160_dsm {
    padding-bottom: 160px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_165_dsm {
    padding-bottom: 165px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_170_dsm {
    padding-bottom: 170px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_175_dsm {
    padding-bottom: 175px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_180_dsm {
    padding-bottom: 180px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_185_dsm {
    padding-bottom: 185px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_190_dsm {
    padding-bottom: 190px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_195_dsm {
    padding-bottom: 195px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_200_dsm {
    padding-bottom: 200px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_205_dsm {
    padding-bottom: 205px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_210_dsm {
    padding-bottom: 210px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_215_dsm {
    padding-bottom: 215px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_220_dsm {
    padding-bottom: 220px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_225_dsm {
    padding-bottom: 225px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_230_dsm {
    padding-bottom: 230px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_235_dsm {
    padding-bottom: 235px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_240_dsm {
    padding-bottom: 240px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_245_dsm {
    padding-bottom: 245px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_250_dsm {
    padding-bottom: 250px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_255_dsm {
    padding-bottom: 255px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_260_dsm {
    padding-bottom: 260px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_265_dsm {
    padding-bottom: 265px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_270_dsm {
    padding-bottom: 270px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_275_dsm {
    padding-bottom: 275px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_280_dsm {
    padding-bottom: 280px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_285_dsm {
    padding-bottom: 285px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_290_dsm {
    padding-bottom: 290px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_295_dsm {
    padding-bottom: 295px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_300_dsm {
    padding-bottom: 300px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_305_dsm {
    padding-bottom: 305px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_310_dsm {
    padding-bottom: 310px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_315_dsm {
    padding-bottom: 315px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_320_dsm {
    padding-bottom: 320px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_325_dsm {
    padding-bottom: 325px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_330_dsm {
    padding-bottom: 330px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_335_dsm {
    padding-bottom: 335px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_340_dsm {
    padding-bottom: 340px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_345_dsm {
    padding-bottom: 345px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_350_dsm {
    padding-bottom: 350px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_355_dsm {
    padding-bottom: 355px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_360_dsm {
    padding-bottom: 360px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_365_dsm {
    padding-bottom: 365px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_370_dsm {
    padding-bottom: 370px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_375_dsm {
    padding-bottom: 375px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_380_dsm {
    padding-bottom: 380px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_385_dsm {
    padding-bottom: 385px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_390_dsm {
    padding-bottom: 390px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_395_dsm {
    padding-bottom: 395px !important; } }

@media only screen and (max-width: 1250px) {
  ._m_t_400_dsm {
    padding-bottom: 400px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_b_0_tb {
    padding-bottom: 0px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_b_5_tb {
    padding-bottom: 5px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_b_10_tb {
    padding-bottom: 10px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_b_15_tb {
    padding-bottom: 15px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_b_20_tb {
    padding-bottom: 20px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_b_25_tb {
    padding-bottom: 25px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_b_30_tb {
    padding-bottom: 30px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_b_35_tb {
    padding-bottom: 35px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_b_40_tb {
    padding-bottom: 40px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_b_45_tb {
    padding-bottom: 45px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_b_50_tb {
    padding-bottom: 50px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_b_55_tb {
    padding-bottom: 55px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_b_60_tb {
    padding-bottom: 60px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_b_65_tb {
    padding-bottom: 65px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_b_70_tb {
    padding-bottom: 70px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_b_75_tb {
    padding-bottom: 75px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_b_80_tb {
    padding-bottom: 80px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_b_85_tb {
    padding-bottom: 85px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_b_90_tb {
    padding-bottom: 90px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_b_95_tb {
    padding-bottom: 95px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_b_100_tb {
    padding-bottom: 100px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_b_105_tb {
    padding-bottom: 105px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_b_110_tb {
    padding-bottom: 110px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_b_115_tb {
    padding-bottom: 115px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_b_120_tb {
    padding-bottom: 120px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_b_125_tb {
    padding-bottom: 125px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_b_130_tb {
    padding-bottom: 130px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_b_135_tb {
    padding-bottom: 135px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_b_140_tb {
    padding-bottom: 140px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_b_145_tb {
    padding-bottom: 145px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_b_150_tb {
    padding-bottom: 150px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_b_155_tb {
    padding-bottom: 155px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_b_160_tb {
    padding-bottom: 160px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_b_165_tb {
    padding-bottom: 165px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_b_170_tb {
    padding-bottom: 170px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_b_175_tb {
    padding-bottom: 175px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_b_180_tb {
    padding-bottom: 180px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_b_185_tb {
    padding-bottom: 185px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_b_190_tb {
    padding-bottom: 190px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_b_195_tb {
    padding-bottom: 195px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_b_200_tb {
    padding-bottom: 200px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_b_205_tb {
    padding-bottom: 205px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_b_210_tb {
    padding-bottom: 210px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_b_215_tb {
    padding-bottom: 215px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_b_220_tb {
    padding-bottom: 220px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_b_225_tb {
    padding-bottom: 225px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_b_230_tb {
    padding-bottom: 230px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_b_235_tb {
    padding-bottom: 235px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_b_240_tb {
    padding-bottom: 240px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_b_245_tb {
    padding-bottom: 245px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_b_250_tb {
    padding-bottom: 250px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_b_255_tb {
    padding-bottom: 255px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_b_260_tb {
    padding-bottom: 260px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_b_265_tb {
    padding-bottom: 265px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_b_270_tb {
    padding-bottom: 270px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_b_275_tb {
    padding-bottom: 275px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_b_280_tb {
    padding-bottom: 280px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_b_285_tb {
    padding-bottom: 285px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_b_290_tb {
    padding-bottom: 290px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_b_295_tb {
    padding-bottom: 295px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_b_300_tb {
    padding-bottom: 300px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_b_305_tb {
    padding-bottom: 305px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_b_310_tb {
    padding-bottom: 310px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_b_315_tb {
    padding-bottom: 315px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_b_320_tb {
    padding-bottom: 320px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_b_325_tb {
    padding-bottom: 325px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_b_330_tb {
    padding-bottom: 330px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_b_335_tb {
    padding-bottom: 335px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_b_340_tb {
    padding-bottom: 340px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_b_345_tb {
    padding-bottom: 345px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_b_350_tb {
    padding-bottom: 350px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_b_355_tb {
    padding-bottom: 355px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_b_360_tb {
    padding-bottom: 360px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_b_365_tb {
    padding-bottom: 365px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_b_370_tb {
    padding-bottom: 370px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_b_375_tb {
    padding-bottom: 375px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_b_380_tb {
    padding-bottom: 380px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_b_385_tb {
    padding-bottom: 385px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_b_390_tb {
    padding-bottom: 390px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_b_395_tb {
    padding-bottom: 395px !important; } }

@media only screen and (max-width: 1230px) {
  ._p_b_400_tb {
    padding-bottom: 400px !important; } }

@media only screen and (max-width: 640px) {
  ._p_b_0_mb {
    padding-bottom: 0px !important; } }

@media only screen and (max-width: 640px) {
  ._p_b_5_mb {
    padding-bottom: 5px !important; } }

@media only screen and (max-width: 640px) {
  ._p_b_10_mb {
    padding-bottom: 10px !important; } }

@media only screen and (max-width: 640px) {
  ._p_b_15_mb {
    padding-bottom: 15px !important; } }

@media only screen and (max-width: 640px) {
  ._p_b_20_mb {
    padding-bottom: 20px !important; } }

@media only screen and (max-width: 640px) {
  ._p_b_25_mb {
    padding-bottom: 25px !important; } }

@media only screen and (max-width: 640px) {
  ._p_b_30_mb {
    padding-bottom: 30px !important; } }

@media only screen and (max-width: 640px) {
  ._p_b_35_mb {
    padding-bottom: 35px !important; } }

@media only screen and (max-width: 640px) {
  ._p_b_40_mb {
    padding-bottom: 40px !important; } }

@media only screen and (max-width: 640px) {
  ._p_b_45_mb {
    padding-bottom: 45px !important; } }

@media only screen and (max-width: 640px) {
  ._p_b_50_mb {
    padding-bottom: 50px !important; } }

@media only screen and (max-width: 640px) {
  ._p_b_55_mb {
    padding-bottom: 55px !important; } }

@media only screen and (max-width: 640px) {
  ._p_b_60_mb {
    padding-bottom: 60px !important; } }

@media only screen and (max-width: 640px) {
  ._p_b_65_mb {
    padding-bottom: 65px !important; } }

@media only screen and (max-width: 640px) {
  ._p_b_70_mb {
    padding-bottom: 70px !important; } }

@media only screen and (max-width: 640px) {
  ._p_b_75_mb {
    padding-bottom: 75px !important; } }

@media only screen and (max-width: 640px) {
  ._p_b_80_mb {
    padding-bottom: 80px !important; } }

@media only screen and (max-width: 640px) {
  ._p_b_85_mb {
    padding-bottom: 85px !important; } }

@media only screen and (max-width: 640px) {
  ._p_b_90_mb {
    padding-bottom: 90px !important; } }

@media only screen and (max-width: 640px) {
  ._p_b_95_mb {
    padding-bottom: 95px !important; } }

@media only screen and (max-width: 640px) {
  ._p_b_100_mb {
    padding-bottom: 100px !important; } }

@media only screen and (max-width: 640px) {
  ._p_b_105_mb {
    padding-bottom: 105px !important; } }

@media only screen and (max-width: 640px) {
  ._p_b_110_mb {
    padding-bottom: 110px !important; } }

@media only screen and (max-width: 640px) {
  ._p_b_115_mb {
    padding-bottom: 115px !important; } }

@media only screen and (max-width: 640px) {
  ._p_b_120_mb {
    padding-bottom: 120px !important; } }

@media only screen and (max-width: 640px) {
  ._p_b_125_mb {
    padding-bottom: 125px !important; } }

@media only screen and (max-width: 640px) {
  ._p_b_130_mb {
    padding-bottom: 130px !important; } }

@media only screen and (max-width: 640px) {
  ._p_b_135_mb {
    padding-bottom: 135px !important; } }

@media only screen and (max-width: 640px) {
  ._p_b_140_mb {
    padding-bottom: 140px !important; } }

@media only screen and (max-width: 640px) {
  ._p_b_145_mb {
    padding-bottom: 145px !important; } }

@media only screen and (max-width: 640px) {
  ._p_b_150_mb {
    padding-bottom: 150px !important; } }

@media only screen and (max-width: 640px) {
  ._p_b_155_mb {
    padding-bottom: 155px !important; } }

@media only screen and (max-width: 640px) {
  ._p_b_160_mb {
    padding-bottom: 160px !important; } }

@media only screen and (max-width: 640px) {
  ._p_b_165_mb {
    padding-bottom: 165px !important; } }

@media only screen and (max-width: 640px) {
  ._p_b_170_mb {
    padding-bottom: 170px !important; } }

@media only screen and (max-width: 640px) {
  ._p_b_175_mb {
    padding-bottom: 175px !important; } }

@media only screen and (max-width: 640px) {
  ._p_b_180_mb {
    padding-bottom: 180px !important; } }

@media only screen and (max-width: 640px) {
  ._p_b_185_mb {
    padding-bottom: 185px !important; } }

@media only screen and (max-width: 640px) {
  ._p_b_190_mb {
    padding-bottom: 190px !important; } }

@media only screen and (max-width: 640px) {
  ._p_b_195_mb {
    padding-bottom: 195px !important; } }

@media only screen and (max-width: 640px) {
  ._p_b_200_mb {
    padding-bottom: 200px !important; } }

@media only screen and (max-width: 640px) {
  ._p_b_205_mb {
    padding-bottom: 205px !important; } }

@media only screen and (max-width: 640px) {
  ._p_b_210_mb {
    padding-bottom: 210px !important; } }

@media only screen and (max-width: 640px) {
  ._p_b_215_mb {
    padding-bottom: 215px !important; } }

@media only screen and (max-width: 640px) {
  ._p_b_220_mb {
    padding-bottom: 220px !important; } }

@media only screen and (max-width: 640px) {
  ._p_b_225_mb {
    padding-bottom: 225px !important; } }

@media only screen and (max-width: 640px) {
  ._p_b_230_mb {
    padding-bottom: 230px !important; } }

@media only screen and (max-width: 640px) {
  ._p_b_235_mb {
    padding-bottom: 235px !important; } }

@media only screen and (max-width: 640px) {
  ._p_b_240_mb {
    padding-bottom: 240px !important; } }

@media only screen and (max-width: 640px) {
  ._p_b_245_mb {
    padding-bottom: 245px !important; } }

@media only screen and (max-width: 640px) {
  ._p_b_250_mb {
    padding-bottom: 250px !important; } }

@media only screen and (max-width: 640px) {
  ._p_b_255_mb {
    padding-bottom: 255px !important; } }

@media only screen and (max-width: 640px) {
  ._p_b_260_mb {
    padding-bottom: 260px !important; } }

@media only screen and (max-width: 640px) {
  ._p_b_265_mb {
    padding-bottom: 265px !important; } }

@media only screen and (max-width: 640px) {
  ._p_b_270_mb {
    padding-bottom: 270px !important; } }

@media only screen and (max-width: 640px) {
  ._p_b_275_mb {
    padding-bottom: 275px !important; } }

@media only screen and (max-width: 640px) {
  ._p_b_280_mb {
    padding-bottom: 280px !important; } }

@media only screen and (max-width: 640px) {
  ._p_b_285_mb {
    padding-bottom: 285px !important; } }

@media only screen and (max-width: 640px) {
  ._p_b_290_mb {
    padding-bottom: 290px !important; } }

@media only screen and (max-width: 640px) {
  ._p_b_295_mb {
    padding-bottom: 295px !important; } }

@media only screen and (max-width: 640px) {
  ._p_b_300_mb {
    padding-bottom: 300px !important; } }

@media only screen and (max-width: 640px) {
  ._p_b_305_mb {
    padding-bottom: 305px !important; } }

@media only screen and (max-width: 640px) {
  ._p_b_310_mb {
    padding-bottom: 310px !important; } }

@media only screen and (max-width: 640px) {
  ._p_b_315_mb {
    padding-bottom: 315px !important; } }

@media only screen and (max-width: 640px) {
  ._p_b_320_mb {
    padding-bottom: 320px !important; } }

@media only screen and (max-width: 640px) {
  ._p_b_325_mb {
    padding-bottom: 325px !important; } }

@media only screen and (max-width: 640px) {
  ._p_b_330_mb {
    padding-bottom: 330px !important; } }

@media only screen and (max-width: 640px) {
  ._p_b_335_mb {
    padding-bottom: 335px !important; } }

@media only screen and (max-width: 640px) {
  ._p_b_340_mb {
    padding-bottom: 340px !important; } }

@media only screen and (max-width: 640px) {
  ._p_b_345_mb {
    padding-bottom: 345px !important; } }

@media only screen and (max-width: 640px) {
  ._p_b_350_mb {
    padding-bottom: 350px !important; } }

@media only screen and (max-width: 640px) {
  ._p_b_355_mb {
    padding-bottom: 355px !important; } }

@media only screen and (max-width: 640px) {
  ._p_b_360_mb {
    padding-bottom: 360px !important; } }

@media only screen and (max-width: 640px) {
  ._p_b_365_mb {
    padding-bottom: 365px !important; } }

@media only screen and (max-width: 640px) {
  ._p_b_370_mb {
    padding-bottom: 370px !important; } }

@media only screen and (max-width: 640px) {
  ._p_b_375_mb {
    padding-bottom: 375px !important; } }

@media only screen and (max-width: 640px) {
  ._p_b_380_mb {
    padding-bottom: 380px !important; } }

@media only screen and (max-width: 640px) {
  ._p_b_385_mb {
    padding-bottom: 385px !important; } }

@media only screen and (max-width: 640px) {
  ._p_b_390_mb {
    padding-bottom: 390px !important; } }

@media only screen and (max-width: 640px) {
  ._p_b_395_mb {
    padding-bottom: 395px !important; } }

@media only screen and (max-width: 640px) {
  ._p_b_400_mb {
    padding-bottom: 400px !important; } }

/* project */
.l-cell {
  display: table;
  height: 100%;
  width: 100%;
  table-layout: fixed; }
  .l-cell__item {
    display: table-cell;
    vertical-align: top; }
  .l-cell__item_major {
    width: 100%; }
  .l-cell__item_middle {
    vertical-align: middle; }
  @media only screen and (max-width: 640px) {
    .l-cell__item_top_mb {
      vertical-align: top; } }

.l-content-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  transition: 0.2s filter; }
  ._modal_active .l-content-wrapper {
    filter: blur(3px); }
  .l-content-wrapper__item_major {
    flex-grow: 1; }
  .l-content-wrapper__item_shrink_none {
    flex-shrink: 0; }

.l-flex {
  display: flex;
  flex-wrap: wrap; }
  .l-flex_nowrap {
    flex-wrap: nowrap; }
  .l-flex_valign_center {
    align-items: center; }
  @media only screen and (max-width: 640px) {
    .l-flex_valign_center_mb {
      align-items: center; } }
  .l-flex_valign_center_top_mb {
    align-items: center; }
    @media only screen and (max-width: 640px) {
      .l-flex_valign_center_top_mb {
        align-items: flex-start; } }
  .l-flex_right {
    justify-content: flex-end; }
  .l-flex_center {
    justify-content: center; }
  .l-flex_space_between {
    justify-content: space-between; }
  .l-flex_space_around {
    justify-content: space-around; }
  .l-flex_col {
    flex-direction: column; }
  .l-flex > .l-flex__item_stretch {
    flex-grow: 1; }
  .l-flex > .l-flex__item_major {
    flex-shrink: 0; }
  @media only screen and (max-width: 1230px) {
    .l-flex > .l-flex__item_equal_none_tb {
      flex: 0 1 auto; } }
  .l-flex_equal {
    flex-wrap: nowrap; }
    .l-flex_equal > .l-flex_equal__item {
      flex: 1 1 100%;
      overflow: hidden; }
  .l-flex_equal_with_width > .l-flex__item {
    flex-grow: 1;
    flex-basis: 0;
    width: 0; }

.l-indent {
  margin: -10px -15px; }
  .l-indent_sm {
    margin: -5px -10px; }
  .l-indent__item {
    padding: 10px 15px; }
  .l-indent_sm .l-indent__item {
    padding: 5px 10px; }

.l-inline {
  font-size: 0 !important;
  letter-spacing: 0 !important; }
  .l-inline__item {
    display: inline-block;
    vertical-align: top;
    font-size: 1.6rem;
    letter-spacing: 0.06em; }
  .l-inline__item_recovery {
    font-size: 1.6rem;
    letter-spacing: 0.06em; }

.l-justify {
  font-size: 0 !important;
  letter-spacing: 0 !important;
  text-align: justify;
  text-align-last: justify;
  text-justify: newspaper; }
  .l-justify::after {
    content: '';
    display: inline-block;
    width: 100%;
    visibility: hidden;
    overflow: hidden; }
  .l-justify__item {
    display: inline-block;
    vertical-align: top;
    font-size: 1.6rem;
    letter-spacing: 0.06em;
    text-align: left;
    text-align-last: auto;
    text-justify: auto; }
  .l-justify__item_recovery {
    font-size: 1.6rem;
    letter-spacing: 0.06em;
    text-align: left;
    text-align-last: auto;
    text-justify: auto; }

.l-row {
  display: table;
  table-layout: fixed;
  width: 100%;
  height: 100%; }
  .l-row__item {
    display: table-row; }
  .l-row__item_major {
    height: 100%; }

.i-btn-loading {
  z-index: 9;
  display: block;
  opacity: 0;
  width: 60px;
  height: 56px;
  background: url("../../media/img/loader_light.svg") center/contain no-repeat;
  display: block;
  position: absolute !important;
  top: 50%;
  left: 50%;
  white-space: nowrap;
  transform: translate(-50%, -50%);
  transition: opacity 0.6s 0s; }
  .i-btn-loading_dark {
    background-image: url("../../media/img/loader_dark.svg"); }
  .i-btn-loading__bind {
    position: relative;
    transition: color 0.6s 0.4s; }
    .i-btn-loading__bind_active {
      pointer-events: none;
      color: transparent !important;
      transition-delay: 0s; }
  .i-btn-loading__bind_active .i-btn-loading {
    opacity: 1;
    transition-delay: 0.4s; }

.b-about {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: -130px;
  margin-bottom: 160px;
  padding-top: 130px; }
  @media only screen and (max-width: 1250px) {
    .b-about {
      margin-bottom: 110px; } }
  .b-about_last {
    margin-bottom: 0; }
  .b-about::before {
    content: '';
    position: absolute;
    top: 123px;
    left: calc( 50% - 618px);
    width: 38px;
    height: 38px;
    background: url("../../media/img/b-about/bg.png") center/cover no-repeat; }
    @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
      .b-about::before {
        background-image: url("../../media/img/b-about/bg@2x.png"); } }
  .b-about__content {
    position: relative;
    z-index: 1; }
  .b-about__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: -40px -15px; }
    @media only screen and (max-width: 1250px) {
      .b-about__list {
        margin: -35px -15px; } }
    @media only screen and (max-width: 640px) {
      .b-about__list {
        margin: -30px -10px; } }
  .b-about__list-item {
    position: relative;
    max-width: 470px;
    margin: 40px 15px;
    padding-left: 100px; }
    @media only screen and (max-width: 1250px) {
      .b-about__list-item {
        margin: 35px 15px; } }
    @media only screen and (max-width: 640px) {
      .b-about__list-item {
        margin: 30px 10px;
        padding-left: 95px; } }
    .b-about__list-item::before {
      content: '';
      position: absolute;
      left: -25px;
      top: -19px;
      width: 120px;
      height: 125px;
      background: top right / contain no-repeat; }
    .b-about__list-item_code::before {
      background-image: url("../../media/img/b-about/icon_1.png"); }
      @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
        .b-about__list-item_code::before {
          background-image: url("../../media/img/b-about/icon_1@2x.png"); } }
    .b-about__list-item_test::before {
      background-image: url("../../media/img/b-about/icon_2.png"); }
      @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
        .b-about__list-item_test::before {
          background-image: url("../../media/img/b-about/icon_2@2x.png"); } }
    .b-about__list-item_design::before {
      background-image: url("../../media/img/b-about/icon_3.png"); }
      @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
        .b-about__list-item_design::before {
          background-image: url("../../media/img/b-about/icon_3@2x.png"); } }
    .b-about__list-item_validation::before {
      background-image: url("../../media/img/b-about/icon_4.png"); }
      @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
        .b-about__list-item_validation::before {
          background-image: url("../../media/img/b-about/icon_4@2x.png"); } }
  .b-about__list-text {
    margin: -12px 0;
    color: #1A2133;
    font-size: 15px;
    line-height: 35px;
    letter-spacing: .04em; }

.b-box {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  margin: 60px auto;
  border-radius: 30px;
  background-color: #fff; }
  @media only screen and (max-width: 1230px) {
    .b-box {
      margin: 40px auto; } }
  .b-box_without_outside_space_horiz {
    margin-left: 0;
    margin-right: 0; }
  .b-box_without_outside_space_vert {
    margin-top: 0;
    margin-bottom: 0; }
  .b-box_without_outside_space {
    margin: 0; }
  .b-box_xl {
    width: 1460px; }
  .b-box_md {
    width: 570px; }
  .b-box_sm {
    width: 445px; }
  @media only screen and (max-width: 640px) {
    .b-box_sm_mb {
      width: 445px; } }
  @media only screen and (max-width: 1460px) {
    .b-box_xl:not(.b-box_with_outside_space_mb) {
      margin: 0; } }
  @media only screen and (max-width: 570px) {
    .b-box_md:not(.b-box_with_outside_space_mb) {
      margin: 0; } }
  @media only screen and (max-width: 445px) {
    .b-box_sm:not(.b-box_with_outside_space_mb) {
      margin: 0; } }
  .b-box__body {
    padding: 50px 40px; }
    @media only screen and (max-width: 1230px) {
      .b-box__body {
        padding: 50px 30px; } }
    @media only screen and (max-width: 640px) {
      .b-box__body {
        padding: 50px 30px; } }
    .b-box__body_without_inside_space {
      padding: 0 !important; }
  @media only screen and (max-width: 1460px) {
    .b-box_xl .b-box__body {
      padding-left: 30px;
      padding-right: 30px; } }
  @media only screen and (max-width: 1460px) {
    .b-box_xl .b-box__body_without_inside_space_mb {
      padding: 0; } }
  @media only screen and (max-width: 570px) {
    .b-box_md .b-box__body {
      padding-left: 30px;
      padding-right: 30px; } }
  @media only screen and (max-width: 570px) {
    .b-box_md .b-box__body_without_inside_space_mb {
      padding: 0; } }
  @media only screen and (max-width: 445px) {
    .b-box_sm .b-box__body {
      padding-left: 30px;
      padding-right: 30px; } }
  @media only screen and (max-width: 445px) {
    .b-box_sm .b-box__body_without_inside_space_mb {
      padding: 0; } }
  .b-box__body-item {
    margin-bottom: 50px; }
    .b-box__body-item_md {
      margin-bottom: 50px; }
    .b-box__body-item_last {
      margin-bottom: 0; }
    .b-box__body-item_space {
      padding: 50px 40px; }
    .b-box__body-item_top {
      border-radius: 30px 30px 0 0; }
    .b-box__body-item_bottom {
      border-radius: 0 0 30px 30px; }
    .b-box__body-item_without_inside_space {
      width: calc( 100% + 60px);
      margin: -50px -30px; }
    .b-box__body-item_without_inside_space_vert {
      margin-top: -50px;
      margin-bottom: -50px; }
    .b-box__body-item_without_inside_space_top {
      margin-top: -50px; }
    .b-box__body-item_without_inside_space_bottom {
      margin-bottom: -50px; }
    .b-box__body-item_without_inside_space_horiz {
      width: calc( 100% + 60px);
      margin-left: -30px;
      margin-right: -30px; }
  @media only screen and (max-width: 1460px) {
    .b-box_xl .b-box__body-item_space {
      padding: 50px 30px; } }
  @media only screen and (max-width: 1460px) {
    .b-box_xl .b-box__body-item_top {
      border-radius: 0; } }
  @media only screen and (max-width: 1460px) {
    .b-box_xl .b-box__body-item_bottom {
      border-radius: 0; } }
  @media only screen and (max-width: 1460px) {
    .b-box_xl .b-box__body-item_without_inside_space {
      margin: 50px 20px; } }
  @media only screen and (max-width: 1460px) {
    .b-box_xl .b-box__body-item_without_inside_space_top {
      border-radius: 0; } }
  @media only screen and (max-width: 1460px) {
    .b-box_xl .b-box__body-item_without_inside_space_bottom {
      border-radius: 0; } }
  @media only screen and (max-width: 1460px) {
    .b-box_xl .b-box__body-item_without_inside_space_horiz {
      width: calc( 100% + 40px);
      margin-left: -20px;
      margin-right: -20px; } }
  @media only screen and (max-width: 570px) {
    .b-box_md .b-box__body-item_space {
      padding: 50px 30px; } }
  @media only screen and (max-width: 570px) {
    .b-box_md .b-box__body-item_top {
      border-radius: 0; } }
  @media only screen and (max-width: 570px) {
    .b-box_md .b-box__body-item_bottom {
      border-radius: 0; } }
  @media only screen and (max-width: 570px) {
    .b-box_md .b-box__body-item_without_inside_space {
      margin: 50px 20px; } }
  @media only screen and (max-width: 570px) {
    .b-box_md .b-box__body-item_without_inside_space_top {
      border-radius: 0; } }
  @media only screen and (max-width: 570px) {
    .b-box_md .b-box__body-item_without_inside_space_bottom {
      border-radius: 0; } }
  @media only screen and (max-width: 570px) {
    .b-box_md .b-box__body-item_without_inside_space_horiz {
      width: calc( 100% + 40px);
      margin-left: -20px;
      margin-right: -20px; } }
  @media only screen and (max-width: 445px) {
    .b-box_sm .b-box__body-item_space {
      padding: 50px 30px; } }
  @media only screen and (max-width: 445px) {
    .b-box_sm .b-box__body-item_top {
      border-radius: 0; } }
  @media only screen and (max-width: 445px) {
    .b-box_sm .b-box__body-item_bottom {
      border-radius: 0; } }
  @media only screen and (max-width: 445px) {
    .b-box_sm .b-box__body-item_without_inside_space {
      margin: 50px 20px; } }
  @media only screen and (max-width: 445px) {
    .b-box_sm .b-box__body-item_without_inside_space_top {
      border-radius: 0; } }
  @media only screen and (max-width: 445px) {
    .b-box_sm .b-box__body-item_without_inside_space_bottom {
      border-radius: 0; } }
  @media only screen and (max-width: 445px) {
    .b-box_sm .b-box__body-item_without_inside_space_horiz {
      width: calc( 100% + 40px);
      margin-left: -20px;
      margin-right: -20px; } }

.b-bg-video {
  position: absolute;
  top: 0;
  left: 0;
  opacity: .1;
  width: 100%;
  height: 100%;
  object-fit: cover; }

.b-collaboration {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 260px; }
  @media only screen and (max-width: 1250px) {
    .b-collaboration {
      flex-direction: column-reverse;
      margin-bottom: 110px; } }
  @media only screen and (max-width: 640px) {
    .b-collaboration {
      margin-bottom: 70px; } }
  .b-collaboration::before, .b-collaboration::after {
    content: '';
    position: absolute;
    pointer-events: none; }
  .b-collaboration::before {
    top: -264px;
    left: 50%;
    width: 100%;
    min-width: 1600px;
    height: 422px;
    background: url("../../media/img/b-collaboration/bg-min.png") center/100% 100% no-repeat;
    transform: translateX(-50%); }
    @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
      .b-collaboration::before {
        background-image: url("../../media/img/b-collaboration/bg@2x-min.png"); } }
  .b-collaboration::after {
    content: '';
    position: absolute;
    top: -93px;
    left: calc( 50% - 688px);
    width: 533px;
    height: 459px;
    background: url("../../media/img/b-collaboration/bg_2-min.png") center/cover no-repeat;
    pointer-events: none; }
    @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
      .b-collaboration::after {
        background-image: url("../../media/img/b-collaboration/bg_2@2x-min.png"); } }
  .b-collaboration__device {
    content: '';
    position: absolute;
    z-index: 1;
    top: calc( 50% - 269px);
    right: calc( 50% - 630px);
    width: 736px;
    height: 580px;
    object-fit: cover; }
    @media only screen and (max-width: 1250px) {
      .b-collaboration__device {
        position: relative;
        top: auto;
        right: auto;
        width: 660px;
        max-width: 100%;
        height: auto;
        margin: 0 auto -100px; } }
    @media only screen and (max-width: 640px) {
      .b-collaboration__device {
        margin-bottom: -14.5vw; } }
  .b-collaboration__content {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    max-width: 420px; }
    @media only screen and (max-width: 1250px) {
      .b-collaboration__content {
        max-width: 570px;
        margin: 0 auto 50px;
        text-align: center; } }

.b-contact {
  position: relative;
  padding: 220px 60px 30px;
  background: linear-gradient(31.76deg, #3B56D7 13.3%, #5B79F5 82.7%); }
  @media only screen and (max-width: 640px) {
    .b-contact {
      padding: 180px 20px 20px; } }
  .b-contact::before, .b-contact::after {
    content: '';
    position: absolute;
    z-index: 1; }
  .b-contact::before {
    top: -195px;
    left: 50%;
    width: 100%;
    min-width: 1600px;
    height: 365px;
    background: url("../../media/img/b-contact/bg.png") center/100% 100% no-repeat;
    transform: translateX(-50%); }
    @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
      .b-contact::before {
        background-image: url("../../media/img/b-contact/bg@2x.png"); } }
  .b-contact::after {
    top: -203px;
    left: calc( 50% - 673px);
    width: 1187px;
    height: 197px;
    background: url("../../media/img/b-contact/bg_2.png") center/cover no-repeat; }
    @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
      .b-contact::after {
        background-image: url("../../media/img/b-contact/bg_2@2x.png"); } }
  .b-contact__content {
    position: relative;
    z-index: 1;
    max-width: 680px;
    margin: 0 auto 70px; }
    @media only screen and (max-width: 1250px) {
      .b-contact__content {
        margin-bottom: 60px;
        text-align: center; } }
    @media only screen and (max-width: 640px) {
      .b-contact__content {
        margin-bottom: 50px; } }
    .b-contact__content_last {
      margin-bottom: 0; }
  .b-contact__form {
    max-width: 450px;
    margin: 0 auto; }
  .b-contact__heading {
    display: flex;
    flex-direction: column;
    margin-bottom: 55px;
    text-align: center; }
    @media only screen and (max-width: 640px) {
      .b-contact__heading {
        margin-bottom: 45px; } }
  .b-contact__text {
    margin: -12px 0;
    color: rgba(255, 255, 255, 0.7);
    font-size: 15px;
    line-height: 35px;
    letter-spacing: .04em; }
    @media only screen and (max-width: 640px) {
      .b-contact__text {
        margin: -10px 0 -11px;
        font-size: 14px;
        line-height: 32px; } }
  .b-contact__copyright {
    position: relative;
    z-index: 1;
    margin: -9px 0 -8px;
    color: rgba(255, 255, 255, 0.4);
    font-size: 13px;
    line-height: 26px;
    letter-spacing: .04em;
    text-align: center; }

.b-devops {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 160px; }
  @media only screen and (max-width: 1250px) {
    .b-devops {
      flex-direction: column-reverse;
      margin-bottom: 110px; } }
  @media only screen and (max-width: 640px) {
    .b-devops {
      margin-bottom: 70px; } }
  .b-devops::before, .b-devops::after {
    content: '';
    position: absolute;
    pointer-events: none; }
    @media only screen and (max-width: 640px) {
      .b-devops::before, .b-devops::after {
        display: none; } }
  .b-devops::before {
    top: -805px;
    left: 50%;
    width: 100%;
    min-width: 1600px;
    height: 1565px;
    background: url("../../media/img/b-devops/bg-min.png") center/100% 100% no-repeat;
    transform: translateX(-50%); }
    @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
      .b-devops::before {
        background-image: url("../../media/img/b-devops/bg@2x-min.png"); } }
    @media only screen and (max-width: 1250px) {
      .b-devops::before {
        top: -530px; } }
  .b-devops::after {
    top: -96px;
    left: calc( 50% - 715px);
    width: 1454px;
    height: 293px;
    background: url("../../media/img/b-devops/bg_2-min.png") center/cover no-repeat; }
    @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
      .b-devops::after {
        background-image: url("../../media/img/b-devops/bg_2@2x-min.png"); } }
  .b-devops__device {
    content: '';
    position: absolute;
    z-index: 1;
    top: calc( 50% - 185px);
    left: calc( 50% - 26px);
    width: 618px;
    height: 354px;
    object-fit: cover; }
    @media only screen and (max-width: 1250px) {
      .b-devops__device {
        position: relative;
        top: auto;
        left: auto;
        width: 540px;
        max-width: 90%;
        height: auto;
        margin: 0 auto -45px; } }
    @media only screen and (max-width: 640px) {
      .b-devops__device {
        margin-bottom: -7vw; } }
  .b-devops__content {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    max-width: 420px; }
    @media only screen and (max-width: 1250px) {
      .b-devops__content {
        max-width: 570px;
        margin: 0 auto 50px;
        text-align: center; } }

.b-header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 45px 50px;
  transition: .2s all; }
  @media only screen and (max-width: 1230px) {
    .b-header {
      padding: 30px 20px; } }
  @media only screen and (max-width: 870px) {
    .b-header {
      justify-content: center;
      padding: 30px 20px; } }
  .b-header_active {
    padding: 30px 50px;
    background-color: #fff;
    box-shadow: 0 0 50px rgba(10, 36, 102, 0.12); }
    @media only screen and (max-width: 1230px) {
      .b-header_active {
        padding: 25px 30px; } }
    @media only screen and (max-width: 870px) {
      .b-header_active {
        padding: 25px 20px; } }
  @media only screen and (max-width: 870px) {
    .b-header__item_main {
      display: none; } }
  .b-header__logo {
    margin: -8px 0 -9px;
    color: #fff;
    font-size: 22px;
    line-height: 32px;
    letter-spacing: .16em;
    font-weight: 800;
    text-transform: uppercase;
    transition: .2s color; }
    @media only screen and (max-width: 870px) {
      .b-header__logo {
        margin: -7px 0 -8px;
        font-size: 18px;
        line-height: 28px;
        letter-spacing: .12em; } }
  .b-header_active .b-header__logo {
    color: #1A2133; }
  .b-header__nav {
    display: flex;
    align-items: center;
    margin: -15px -25px; }
    @media only screen and (max-width: 1230px) {
      .b-header__nav {
        margin: -15px; } }
  .b-header__nav-item {
    margin: 15px 25px; }
    @media only screen and (max-width: 1230px) {
      .b-header__nav-item {
        margin: 15px; } }
    .b-header__nav-item_btn {
      display: flex; }
  .b-header__link {
    display: block;
    margin: -3px 0 -4px;
    color: #fff;
    font-size: 14px;
    line-height: 17px;
    font-weight: 700;
    letter-spacing: .06em;
    text-transform: uppercase;
    transition: .2s color; }
    @media only screen and (max-width: 1230px) {
      .b-header__link {
        margin: -4px 0 -3px;
        font-size: 13px;
        line-height: 16px; } }
  .b-header_active .b-header__link {
    color: #1A2133; }
  .b-header__btn {
    margin: -21px 0px; }
    @media only screen and (max-width: 1230px) {
      .b-header__btn {
        width: auto;
        margin: -18px 0px;
        padding: 13px 15px 12px; } }
    @media only screen and (max-width: 870px) {
      .b-header__btn {
        margin: 0; } }
  .b-header_active .b-header__btn {
    background-color: #415DDD;
    box-shadow: none;
    color: #fff; }
    .b-header_active .b-header__btn:hover {
      background: #2b4bd9; }
    .b-header_active .b-header__btn:active {
      background: #2441c7; }

.b-heading {
  margin-bottom: 45px; }
  @media only screen and (max-width: 640px) {
    .b-heading {
      margin-bottom: 40px; } }
  .b-heading_with_hr {
    position: relative;
    margin-bottom: 40px;
    padding-bottom: 43px; }
    @media only screen and (max-width: 640px) {
      .b-heading_with_hr {
        margin-bottom: 35px;
        padding-bottom: 33px; } }
    .b-heading_with_hr::before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 60px;
      height: 3px;
      background: linear-gradient(90deg, #4460E0, #37A6F1); }
      @media only screen and (max-width: 1250px) {
        .b-heading_with_hr::before {
          left: calc( 50% - 30px); } }
  .b-heading_m_b_md {
    margin-bottom: 60px; }
    @media only screen and (max-width: 640px) {
      .b-heading_m_b_md {
        margin-bottom: 40px; } }
  .b-heading_m_b_lg {
    margin-bottom: 80px; }
    @media only screen and (max-width: 1250px) {
      .b-heading_m_b_lg {
        margin-bottom: 70px; } }
    @media only screen and (max-width: 640px) {
      .b-heading_m_b_lg {
        margin-bottom: 60px; } }
  .b-heading_right {
    text-align: right; }
    @media only screen and (max-width: 1250px) {
      .b-heading_right {
        text-align: center; } }
  .b-heading_center {
    text-align: center; }
  .b-heading_right.b-heading_with_hr::before {
    left: auto;
    right: 0; }
    @media only screen and (max-width: 1250px) {
      .b-heading_right.b-heading_with_hr::before {
        left: calc( 50% - 30px);
        right: auto; } }
  .b-heading_center.b-heading_with_hr::before {
    left: calc( 50% - 30px); }
  .b-heading__text {
    margin: -14px 0 -13px;
    color: #1A2133;
    font-size: 44px;
    line-height: 58px;
    font-weight: 800;
    letter-spacing: .06em; }
    @media only screen and (max-width: 1250px) {
      .b-heading__text {
        margin: -13px 0;
        font-size: 40px;
        line-height: 54px; } }
    @media only screen and (max-width: 640px) {
      .b-heading__text {
        margin: -7px 0 -8px;
        font-size: 30px;
        line-height: 36px;
        letter-spacing: .04em; } }
  .b-heading_light .b-heading__text {
    color: #fff; }

.b-heading-2 {
  margin-bottom: 40px; }
  .b-heading-2_last {
    margin-bottom: 0; }
  .b-heading-2__title {
    margin: -2px 0 21px;
    color: #1A2133;
    font-size: 22px;
    line-height: 32px;
    font-weight: 700;
    letter-spacing: .04em; }
    .b-heading-2__title_last {
      margin-bottom: -9px; }
  .b-heading-2__subtitle {
    margin: -9px 0 -10px;
    color: #838CA3;
    font-size: 15px;
    line-height: 30px;
    letter-spacing: .04em; }

.b-hero {
  position: relative;
  min-height: 743px;
  margin-bottom: 90px;
  padding: 180px 60px 60px;
  background: linear-gradient(31.76deg, #3B56D7 13.3%, #5B79F5 82.7%);
  text-align: center; }
  @media only screen and (max-width: 1250px) {
    .b-hero {
      min-height: 700px;
      margin-bottom: 50px;
      padding: 150px 20px 120px; } }
  @media only screen and (max-width: 640px) {
    .b-hero {
      display: flex;
      align-items: center;
      min-height: 610px;
      padding: 110px 20px 170px; } }
  @media only screen and (max-width: 360px) {
    .b-hero {
      min-height: 660px;
      padding-top: 115px; } }
  .b-hero::before, .b-hero::after {
    content: '';
    position: absolute;
    z-index: 1; }
  .b-hero::before {
    top: calc( 100% - 115px);
    left: 50%;
    width: 100%;
    min-width: 1600px;
    height: 271px;
    background: url("../../media/img/b-hero/bg.png") center/100% 100% no-repeat;
    transform: translateX(-50%); }
    @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
      .b-hero::before {
        background-image: url("../../media/img/b-hero/bg@2x.png"); } }
  .b-hero::after {
    top: calc( 100% - 52px);
    left: calc( 50% - 310px);
    width: 978px;
    height: 173px;
    background: url("../../media/img/b-hero/bg_2-min.png") center/cover no-repeat; }
    @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
      .b-hero::after {
        background-image: url("../../media/img/b-hero/bg_2-min@2x.png"); } }
  .b-hero_last {
    margin-bottom: 0; }
  .b-hero__content {
    position: relative;
    z-index: 1;
    max-width: 680px;
    margin: 0 auto; }
    .b-hero__content::before {
      content: '';
      position: absolute;
      top: calc( 100% + 40px);
      left: calc( 50% - 7px);
      width: 14px;
      height: 27px;
      background: url("../../media/img/b-hero/arrow.svg") center/contain no-repeat;
      animation: arrowAnimate 2s infinite; }
      @media only screen and (max-width: 640px) {
        .b-hero__content::before {
          top: calc( 100% + 20px); } }
  .b-hero__heading {
    margin: -16px 0 30px;
    color: #fff;
    font-size: 70px;
    line-height: 80px;
    letter-spacing: .02em; }
    @media only screen and (max-width: 1250px) {
      .b-hero__heading {
        margin: -14px 0 26px;
        font-size: 60px;
        line-height: 70px; } }
    @media only screen and (max-width: 640px) {
      .b-hero__heading {
        margin: -12px 0 24px;
        font-size: 32px;
        line-height: 45px; } }
    .b-hero__heading_bold {
      font-weight: 600; }
    .b-hero__heading_last {
      margin-bottom: -15px; }
      @media only screen and (max-width: 1250px) {
        .b-hero__heading_last {
          margin-bottom: -14px; } }
      @media only screen and (max-width: 640px) {
        .b-hero__heading_last {
          margin-bottom: -11px; } }
  .b-hero__text {
    margin: -12px 0 37px;
    color: rgba(255, 255, 255, 0.7);
    font-size: 16px;
    line-height: 36px;
    font-weight: 500;
    letter-spacing: .02em; }
    @media only screen and (max-width: 1250px) {
      .b-hero__text {
        margin-bottom: 32px; } }
    @media only screen and (max-width: 640px) {
      .b-hero__text {
        margin: -10px 0 29px;
        font-size: 14px;
        line-height: 32px;
        font-weight: 400;
        letter-spacing: .04em; } }
    .b-hero__text_last {
      margin-bottom: -13px; }
      @media only screen and (max-width: 640px) {
        .b-hero__text_last {
          margin-bottom: -11px; } }

@keyframes arrowAnimate {
  0% {
    opacity: 0;
    transform: translateY(-10px); }
  50% {
    opacity: 1; }
  100% {
    opacity: 0;
    transform: translateY(20px); } }

.b-list-blocks {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column; }
  .b-list-blocks__inner {
    display: flex; }
  .b-list-blocks_2 > .b-list-blocks__inner {
    margin: -1px; }
  .b-list-blocks_4 > .b-list-blocks__inner {
    margin: -2px; }
  .b-list-blocks_6 > .b-list-blocks__inner {
    margin: -3px; }
  .b-list-blocks_8 > .b-list-blocks__inner {
    margin: -4px; }
  .b-list-blocks_10 > .b-list-blocks__inner {
    margin: -5px; }
  .b-list-blocks_12 > .b-list-blocks__inner {
    margin: -6px; }
  .b-list-blocks_14 > .b-list-blocks__inner {
    margin: -7px; }
  .b-list-blocks_16 > .b-list-blocks__inner {
    margin: -8px; }
  .b-list-blocks_18 > .b-list-blocks__inner {
    margin: -9px; }
  .b-list-blocks_20 > .b-list-blocks__inner {
    margin: -10px; }
  .b-list-blocks_22 > .b-list-blocks__inner {
    margin: -11px; }
  .b-list-blocks_24 > .b-list-blocks__inner {
    margin: -12px; }
  .b-list-blocks_26 > .b-list-blocks__inner {
    margin: -13px; }
  .b-list-blocks_28 > .b-list-blocks__inner {
    margin: -14px; }
  .b-list-blocks_30 > .b-list-blocks__inner {
    margin: -15px; }
  .b-list-blocks_32 > .b-list-blocks__inner {
    margin: -16px; }
  .b-list-blocks_34 > .b-list-blocks__inner {
    margin: -17px; }
  .b-list-blocks_36 > .b-list-blocks__inner {
    margin: -18px; }
  .b-list-blocks_38 > .b-list-blocks__inner {
    margin: -19px; }
  .b-list-blocks_40 > .b-list-blocks__inner {
    margin: -20px; }
  .b-list-blocks_42 > .b-list-blocks__inner {
    margin: -21px; }
  .b-list-blocks_44 > .b-list-blocks__inner {
    margin: -22px; }
  .b-list-blocks_46 > .b-list-blocks__inner {
    margin: -23px; }
  .b-list-blocks_48 > .b-list-blocks__inner {
    margin: -24px; }
  .b-list-blocks_50 > .b-list-blocks__inner {
    margin: -25px; }
  .b-list-blocks_52 > .b-list-blocks__inner {
    margin: -26px; }
  .b-list-blocks_54 > .b-list-blocks__inner {
    margin: -27px; }
  .b-list-blocks_56 > .b-list-blocks__inner {
    margin: -28px; }
  .b-list-blocks_58 > .b-list-blocks__inner {
    margin: -29px; }
  .b-list-blocks_60 > .b-list-blocks__inner {
    margin: -30px; }
  .b-list-blocks_62 > .b-list-blocks__inner {
    margin: -31px; }
  .b-list-blocks_64 > .b-list-blocks__inner {
    margin: -32px; }
  .b-list-blocks_66 > .b-list-blocks__inner {
    margin: -33px; }
  .b-list-blocks_68 > .b-list-blocks__inner {
    margin: -34px; }
  .b-list-blocks_70 > .b-list-blocks__inner {
    margin: -35px; }
  .b-list-blocks_72 > .b-list-blocks__inner {
    margin: -36px; }
  .b-list-blocks_74 > .b-list-blocks__inner {
    margin: -37px; }
  .b-list-blocks_76 > .b-list-blocks__inner {
    margin: -38px; }
  .b-list-blocks_78 > .b-list-blocks__inner {
    margin: -39px; }
  .b-list-blocks_80 > .b-list-blocks__inner {
    margin: -40px; }
  .b-list-blocks_82 > .b-list-blocks__inner {
    margin: -41px; }
  .b-list-blocks_84 > .b-list-blocks__inner {
    margin: -42px; }
  .b-list-blocks_86 > .b-list-blocks__inner {
    margin: -43px; }
  .b-list-blocks_88 > .b-list-blocks__inner {
    margin: -44px; }
  .b-list-blocks_90 > .b-list-blocks__inner {
    margin: -45px; }
  .b-list-blocks_92 > .b-list-blocks__inner {
    margin: -46px; }
  .b-list-blocks_94 > .b-list-blocks__inner {
    margin: -47px; }
  .b-list-blocks_96 > .b-list-blocks__inner {
    margin: -48px; }
  .b-list-blocks_98 > .b-list-blocks__inner {
    margin: -49px; }
  .b-list-blocks_100 > .b-list-blocks__inner {
    margin: -50px; }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_2_tb > .b-list-blocks__inner {
      margin: -1px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_4_tb > .b-list-blocks__inner {
      margin: -2px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_6_tb > .b-list-blocks__inner {
      margin: -3px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_8_tb > .b-list-blocks__inner {
      margin: -4px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_10_tb > .b-list-blocks__inner {
      margin: -5px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_12_tb > .b-list-blocks__inner {
      margin: -6px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_14_tb > .b-list-blocks__inner {
      margin: -7px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_16_tb > .b-list-blocks__inner {
      margin: -8px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_18_tb > .b-list-blocks__inner {
      margin: -9px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_20_tb > .b-list-blocks__inner {
      margin: -10px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_22_tb > .b-list-blocks__inner {
      margin: -11px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_24_tb > .b-list-blocks__inner {
      margin: -12px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_26_tb > .b-list-blocks__inner {
      margin: -13px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_28_tb > .b-list-blocks__inner {
      margin: -14px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_30_tb > .b-list-blocks__inner {
      margin: -15px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_32_tb > .b-list-blocks__inner {
      margin: -16px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_34_tb > .b-list-blocks__inner {
      margin: -17px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_36_tb > .b-list-blocks__inner {
      margin: -18px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_38_tb > .b-list-blocks__inner {
      margin: -19px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_40_tb > .b-list-blocks__inner {
      margin: -20px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_42_tb > .b-list-blocks__inner {
      margin: -21px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_44_tb > .b-list-blocks__inner {
      margin: -22px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_46_tb > .b-list-blocks__inner {
      margin: -23px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_48_tb > .b-list-blocks__inner {
      margin: -24px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_50_tb > .b-list-blocks__inner {
      margin: -25px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_52_tb > .b-list-blocks__inner {
      margin: -26px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_54_tb > .b-list-blocks__inner {
      margin: -27px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_56_tb > .b-list-blocks__inner {
      margin: -28px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_58_tb > .b-list-blocks__inner {
      margin: -29px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_60_tb > .b-list-blocks__inner {
      margin: -30px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_62_tb > .b-list-blocks__inner {
      margin: -31px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_64_tb > .b-list-blocks__inner {
      margin: -32px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_66_tb > .b-list-blocks__inner {
      margin: -33px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_68_tb > .b-list-blocks__inner {
      margin: -34px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_70_tb > .b-list-blocks__inner {
      margin: -35px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_72_tb > .b-list-blocks__inner {
      margin: -36px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_74_tb > .b-list-blocks__inner {
      margin: -37px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_76_tb > .b-list-blocks__inner {
      margin: -38px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_78_tb > .b-list-blocks__inner {
      margin: -39px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_80_tb > .b-list-blocks__inner {
      margin: -40px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_82_tb > .b-list-blocks__inner {
      margin: -41px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_84_tb > .b-list-blocks__inner {
      margin: -42px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_86_tb > .b-list-blocks__inner {
      margin: -43px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_88_tb > .b-list-blocks__inner {
      margin: -44px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_90_tb > .b-list-blocks__inner {
      margin: -45px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_92_tb > .b-list-blocks__inner {
      margin: -46px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_94_tb > .b-list-blocks__inner {
      margin: -47px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_96_tb > .b-list-blocks__inner {
      margin: -48px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_98_tb > .b-list-blocks__inner {
      margin: -49px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_100_tb > .b-list-blocks__inner {
      margin: -50px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_2_mb > .b-list-blocks__inner {
      margin: -1px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_4_mb > .b-list-blocks__inner {
      margin: -2px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_6_mb > .b-list-blocks__inner {
      margin: -3px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_8_mb > .b-list-blocks__inner {
      margin: -4px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_10_mb > .b-list-blocks__inner {
      margin: -5px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_12_mb > .b-list-blocks__inner {
      margin: -6px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_14_mb > .b-list-blocks__inner {
      margin: -7px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_16_mb > .b-list-blocks__inner {
      margin: -8px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_18_mb > .b-list-blocks__inner {
      margin: -9px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_20_mb > .b-list-blocks__inner {
      margin: -10px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_22_mb > .b-list-blocks__inner {
      margin: -11px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_24_mb > .b-list-blocks__inner {
      margin: -12px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_26_mb > .b-list-blocks__inner {
      margin: -13px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_28_mb > .b-list-blocks__inner {
      margin: -14px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_30_mb > .b-list-blocks__inner {
      margin: -15px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_32_mb > .b-list-blocks__inner {
      margin: -16px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_34_mb > .b-list-blocks__inner {
      margin: -17px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_36_mb > .b-list-blocks__inner {
      margin: -18px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_38_mb > .b-list-blocks__inner {
      margin: -19px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_40_mb > .b-list-blocks__inner {
      margin: -20px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_42_mb > .b-list-blocks__inner {
      margin: -21px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_44_mb > .b-list-blocks__inner {
      margin: -22px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_46_mb > .b-list-blocks__inner {
      margin: -23px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_48_mb > .b-list-blocks__inner {
      margin: -24px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_50_mb > .b-list-blocks__inner {
      margin: -25px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_52_mb > .b-list-blocks__inner {
      margin: -26px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_54_mb > .b-list-blocks__inner {
      margin: -27px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_56_mb > .b-list-blocks__inner {
      margin: -28px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_58_mb > .b-list-blocks__inner {
      margin: -29px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_60_mb > .b-list-blocks__inner {
      margin: -30px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_62_mb > .b-list-blocks__inner {
      margin: -31px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_64_mb > .b-list-blocks__inner {
      margin: -32px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_66_mb > .b-list-blocks__inner {
      margin: -33px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_68_mb > .b-list-blocks__inner {
      margin: -34px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_70_mb > .b-list-blocks__inner {
      margin: -35px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_72_mb > .b-list-blocks__inner {
      margin: -36px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_74_mb > .b-list-blocks__inner {
      margin: -37px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_76_mb > .b-list-blocks__inner {
      margin: -38px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_78_mb > .b-list-blocks__inner {
      margin: -39px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_80_mb > .b-list-blocks__inner {
      margin: -40px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_82_mb > .b-list-blocks__inner {
      margin: -41px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_84_mb > .b-list-blocks__inner {
      margin: -42px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_86_mb > .b-list-blocks__inner {
      margin: -43px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_88_mb > .b-list-blocks__inner {
      margin: -44px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_90_mb > .b-list-blocks__inner {
      margin: -45px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_92_mb > .b-list-blocks__inner {
      margin: -46px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_94_mb > .b-list-blocks__inner {
      margin: -47px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_96_mb > .b-list-blocks__inner {
      margin: -48px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_98_mb > .b-list-blocks__inner {
      margin: -49px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_100_mb > .b-list-blocks__inner {
      margin: -50px; } }
  .b-list-blocks_p > .b-list-blocks__inner > .b-list-blocks__item {
    margin: 0 !important; }
  .b-list-blocks_2 > .b-list-blocks__inner > .b-list-blocks__item {
    margin: 1px; }
  .b-list-blocks_p.b-list-blocks_2 > .b-list-blocks__inner > .b-list-blocks__item {
    padding: 1px; }
  .b-list-blocks_4 > .b-list-blocks__inner > .b-list-blocks__item {
    margin: 2px; }
  .b-list-blocks_p.b-list-blocks_4 > .b-list-blocks__inner > .b-list-blocks__item {
    padding: 2px; }
  .b-list-blocks_6 > .b-list-blocks__inner > .b-list-blocks__item {
    margin: 3px; }
  .b-list-blocks_p.b-list-blocks_6 > .b-list-blocks__inner > .b-list-blocks__item {
    padding: 3px; }
  .b-list-blocks_8 > .b-list-blocks__inner > .b-list-blocks__item {
    margin: 4px; }
  .b-list-blocks_p.b-list-blocks_8 > .b-list-blocks__inner > .b-list-blocks__item {
    padding: 4px; }
  .b-list-blocks_10 > .b-list-blocks__inner > .b-list-blocks__item {
    margin: 5px; }
  .b-list-blocks_p.b-list-blocks_10 > .b-list-blocks__inner > .b-list-blocks__item {
    padding: 5px; }
  .b-list-blocks_12 > .b-list-blocks__inner > .b-list-blocks__item {
    margin: 6px; }
  .b-list-blocks_p.b-list-blocks_12 > .b-list-blocks__inner > .b-list-blocks__item {
    padding: 6px; }
  .b-list-blocks_14 > .b-list-blocks__inner > .b-list-blocks__item {
    margin: 7px; }
  .b-list-blocks_p.b-list-blocks_14 > .b-list-blocks__inner > .b-list-blocks__item {
    padding: 7px; }
  .b-list-blocks_16 > .b-list-blocks__inner > .b-list-blocks__item {
    margin: 8px; }
  .b-list-blocks_p.b-list-blocks_16 > .b-list-blocks__inner > .b-list-blocks__item {
    padding: 8px; }
  .b-list-blocks_18 > .b-list-blocks__inner > .b-list-blocks__item {
    margin: 9px; }
  .b-list-blocks_p.b-list-blocks_18 > .b-list-blocks__inner > .b-list-blocks__item {
    padding: 9px; }
  .b-list-blocks_20 > .b-list-blocks__inner > .b-list-blocks__item {
    margin: 10px; }
  .b-list-blocks_p.b-list-blocks_20 > .b-list-blocks__inner > .b-list-blocks__item {
    padding: 10px; }
  .b-list-blocks_22 > .b-list-blocks__inner > .b-list-blocks__item {
    margin: 11px; }
  .b-list-blocks_p.b-list-blocks_22 > .b-list-blocks__inner > .b-list-blocks__item {
    padding: 11px; }
  .b-list-blocks_24 > .b-list-blocks__inner > .b-list-blocks__item {
    margin: 12px; }
  .b-list-blocks_p.b-list-blocks_24 > .b-list-blocks__inner > .b-list-blocks__item {
    padding: 12px; }
  .b-list-blocks_26 > .b-list-blocks__inner > .b-list-blocks__item {
    margin: 13px; }
  .b-list-blocks_p.b-list-blocks_26 > .b-list-blocks__inner > .b-list-blocks__item {
    padding: 13px; }
  .b-list-blocks_28 > .b-list-blocks__inner > .b-list-blocks__item {
    margin: 14px; }
  .b-list-blocks_p.b-list-blocks_28 > .b-list-blocks__inner > .b-list-blocks__item {
    padding: 14px; }
  .b-list-blocks_30 > .b-list-blocks__inner > .b-list-blocks__item {
    margin: 15px; }
  .b-list-blocks_p.b-list-blocks_30 > .b-list-blocks__inner > .b-list-blocks__item {
    padding: 15px; }
  .b-list-blocks_32 > .b-list-blocks__inner > .b-list-blocks__item {
    margin: 16px; }
  .b-list-blocks_p.b-list-blocks_32 > .b-list-blocks__inner > .b-list-blocks__item {
    padding: 16px; }
  .b-list-blocks_34 > .b-list-blocks__inner > .b-list-blocks__item {
    margin: 17px; }
  .b-list-blocks_p.b-list-blocks_34 > .b-list-blocks__inner > .b-list-blocks__item {
    padding: 17px; }
  .b-list-blocks_36 > .b-list-blocks__inner > .b-list-blocks__item {
    margin: 18px; }
  .b-list-blocks_p.b-list-blocks_36 > .b-list-blocks__inner > .b-list-blocks__item {
    padding: 18px; }
  .b-list-blocks_38 > .b-list-blocks__inner > .b-list-blocks__item {
    margin: 19px; }
  .b-list-blocks_p.b-list-blocks_38 > .b-list-blocks__inner > .b-list-blocks__item {
    padding: 19px; }
  .b-list-blocks_40 > .b-list-blocks__inner > .b-list-blocks__item {
    margin: 20px; }
  .b-list-blocks_p.b-list-blocks_40 > .b-list-blocks__inner > .b-list-blocks__item {
    padding: 20px; }
  .b-list-blocks_42 > .b-list-blocks__inner > .b-list-blocks__item {
    margin: 21px; }
  .b-list-blocks_p.b-list-blocks_42 > .b-list-blocks__inner > .b-list-blocks__item {
    padding: 21px; }
  .b-list-blocks_44 > .b-list-blocks__inner > .b-list-blocks__item {
    margin: 22px; }
  .b-list-blocks_p.b-list-blocks_44 > .b-list-blocks__inner > .b-list-blocks__item {
    padding: 22px; }
  .b-list-blocks_46 > .b-list-blocks__inner > .b-list-blocks__item {
    margin: 23px; }
  .b-list-blocks_p.b-list-blocks_46 > .b-list-blocks__inner > .b-list-blocks__item {
    padding: 23px; }
  .b-list-blocks_48 > .b-list-blocks__inner > .b-list-blocks__item {
    margin: 24px; }
  .b-list-blocks_p.b-list-blocks_48 > .b-list-blocks__inner > .b-list-blocks__item {
    padding: 24px; }
  .b-list-blocks_50 > .b-list-blocks__inner > .b-list-blocks__item {
    margin: 25px; }
  .b-list-blocks_p.b-list-blocks_50 > .b-list-blocks__inner > .b-list-blocks__item {
    padding: 25px; }
  .b-list-blocks_52 > .b-list-blocks__inner > .b-list-blocks__item {
    margin: 26px; }
  .b-list-blocks_p.b-list-blocks_52 > .b-list-blocks__inner > .b-list-blocks__item {
    padding: 26px; }
  .b-list-blocks_54 > .b-list-blocks__inner > .b-list-blocks__item {
    margin: 27px; }
  .b-list-blocks_p.b-list-blocks_54 > .b-list-blocks__inner > .b-list-blocks__item {
    padding: 27px; }
  .b-list-blocks_56 > .b-list-blocks__inner > .b-list-blocks__item {
    margin: 28px; }
  .b-list-blocks_p.b-list-blocks_56 > .b-list-blocks__inner > .b-list-blocks__item {
    padding: 28px; }
  .b-list-blocks_58 > .b-list-blocks__inner > .b-list-blocks__item {
    margin: 29px; }
  .b-list-blocks_p.b-list-blocks_58 > .b-list-blocks__inner > .b-list-blocks__item {
    padding: 29px; }
  .b-list-blocks_60 > .b-list-blocks__inner > .b-list-blocks__item {
    margin: 30px; }
  .b-list-blocks_p.b-list-blocks_60 > .b-list-blocks__inner > .b-list-blocks__item {
    padding: 30px; }
  .b-list-blocks_62 > .b-list-blocks__inner > .b-list-blocks__item {
    margin: 31px; }
  .b-list-blocks_p.b-list-blocks_62 > .b-list-blocks__inner > .b-list-blocks__item {
    padding: 31px; }
  .b-list-blocks_64 > .b-list-blocks__inner > .b-list-blocks__item {
    margin: 32px; }
  .b-list-blocks_p.b-list-blocks_64 > .b-list-blocks__inner > .b-list-blocks__item {
    padding: 32px; }
  .b-list-blocks_66 > .b-list-blocks__inner > .b-list-blocks__item {
    margin: 33px; }
  .b-list-blocks_p.b-list-blocks_66 > .b-list-blocks__inner > .b-list-blocks__item {
    padding: 33px; }
  .b-list-blocks_68 > .b-list-blocks__inner > .b-list-blocks__item {
    margin: 34px; }
  .b-list-blocks_p.b-list-blocks_68 > .b-list-blocks__inner > .b-list-blocks__item {
    padding: 34px; }
  .b-list-blocks_70 > .b-list-blocks__inner > .b-list-blocks__item {
    margin: 35px; }
  .b-list-blocks_p.b-list-blocks_70 > .b-list-blocks__inner > .b-list-blocks__item {
    padding: 35px; }
  .b-list-blocks_72 > .b-list-blocks__inner > .b-list-blocks__item {
    margin: 36px; }
  .b-list-blocks_p.b-list-blocks_72 > .b-list-blocks__inner > .b-list-blocks__item {
    padding: 36px; }
  .b-list-blocks_74 > .b-list-blocks__inner > .b-list-blocks__item {
    margin: 37px; }
  .b-list-blocks_p.b-list-blocks_74 > .b-list-blocks__inner > .b-list-blocks__item {
    padding: 37px; }
  .b-list-blocks_76 > .b-list-blocks__inner > .b-list-blocks__item {
    margin: 38px; }
  .b-list-blocks_p.b-list-blocks_76 > .b-list-blocks__inner > .b-list-blocks__item {
    padding: 38px; }
  .b-list-blocks_78 > .b-list-blocks__inner > .b-list-blocks__item {
    margin: 39px; }
  .b-list-blocks_p.b-list-blocks_78 > .b-list-blocks__inner > .b-list-blocks__item {
    padding: 39px; }
  .b-list-blocks_80 > .b-list-blocks__inner > .b-list-blocks__item {
    margin: 40px; }
  .b-list-blocks_p.b-list-blocks_80 > .b-list-blocks__inner > .b-list-blocks__item {
    padding: 40px; }
  .b-list-blocks_82 > .b-list-blocks__inner > .b-list-blocks__item {
    margin: 41px; }
  .b-list-blocks_p.b-list-blocks_82 > .b-list-blocks__inner > .b-list-blocks__item {
    padding: 41px; }
  .b-list-blocks_84 > .b-list-blocks__inner > .b-list-blocks__item {
    margin: 42px; }
  .b-list-blocks_p.b-list-blocks_84 > .b-list-blocks__inner > .b-list-blocks__item {
    padding: 42px; }
  .b-list-blocks_86 > .b-list-blocks__inner > .b-list-blocks__item {
    margin: 43px; }
  .b-list-blocks_p.b-list-blocks_86 > .b-list-blocks__inner > .b-list-blocks__item {
    padding: 43px; }
  .b-list-blocks_88 > .b-list-blocks__inner > .b-list-blocks__item {
    margin: 44px; }
  .b-list-blocks_p.b-list-blocks_88 > .b-list-blocks__inner > .b-list-blocks__item {
    padding: 44px; }
  .b-list-blocks_90 > .b-list-blocks__inner > .b-list-blocks__item {
    margin: 45px; }
  .b-list-blocks_p.b-list-blocks_90 > .b-list-blocks__inner > .b-list-blocks__item {
    padding: 45px; }
  .b-list-blocks_92 > .b-list-blocks__inner > .b-list-blocks__item {
    margin: 46px; }
  .b-list-blocks_p.b-list-blocks_92 > .b-list-blocks__inner > .b-list-blocks__item {
    padding: 46px; }
  .b-list-blocks_94 > .b-list-blocks__inner > .b-list-blocks__item {
    margin: 47px; }
  .b-list-blocks_p.b-list-blocks_94 > .b-list-blocks__inner > .b-list-blocks__item {
    padding: 47px; }
  .b-list-blocks_96 > .b-list-blocks__inner > .b-list-blocks__item {
    margin: 48px; }
  .b-list-blocks_p.b-list-blocks_96 > .b-list-blocks__inner > .b-list-blocks__item {
    padding: 48px; }
  .b-list-blocks_98 > .b-list-blocks__inner > .b-list-blocks__item {
    margin: 49px; }
  .b-list-blocks_p.b-list-blocks_98 > .b-list-blocks__inner > .b-list-blocks__item {
    padding: 49px; }
  .b-list-blocks_100 > .b-list-blocks__inner > .b-list-blocks__item {
    margin: 50px; }
  .b-list-blocks_p.b-list-blocks_100 > .b-list-blocks__inner > .b-list-blocks__item {
    padding: 50px; }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_2_tb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 1px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_p.b-list-blocks_2_tb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 1px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_4_tb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 2px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_p.b-list-blocks_4_tb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 2px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_6_tb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 3px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_p.b-list-blocks_6_tb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 3px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_8_tb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 4px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_p.b-list-blocks_8_tb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 4px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_10_tb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 5px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_p.b-list-blocks_10_tb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 5px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_12_tb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 6px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_p.b-list-blocks_12_tb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 6px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_14_tb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 7px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_p.b-list-blocks_14_tb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 7px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_16_tb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 8px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_p.b-list-blocks_16_tb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 8px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_18_tb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 9px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_p.b-list-blocks_18_tb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 9px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_20_tb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 10px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_p.b-list-blocks_20_tb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 10px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_22_tb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 11px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_p.b-list-blocks_22_tb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 11px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_24_tb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 12px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_p.b-list-blocks_24_tb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 12px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_26_tb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 13px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_p.b-list-blocks_26_tb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 13px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_28_tb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 14px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_p.b-list-blocks_28_tb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 14px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_30_tb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 15px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_p.b-list-blocks_30_tb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 15px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_32_tb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 16px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_p.b-list-blocks_32_tb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 16px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_34_tb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 17px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_p.b-list-blocks_34_tb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 17px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_36_tb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 18px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_p.b-list-blocks_36_tb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 18px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_38_tb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 19px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_p.b-list-blocks_38_tb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 19px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_40_tb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 20px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_p.b-list-blocks_40_tb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 20px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_42_tb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 21px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_p.b-list-blocks_42_tb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 21px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_44_tb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 22px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_p.b-list-blocks_44_tb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 22px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_46_tb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 23px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_p.b-list-blocks_46_tb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 23px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_48_tb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 24px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_p.b-list-blocks_48_tb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 24px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_50_tb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 25px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_p.b-list-blocks_50_tb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 25px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_52_tb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 26px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_p.b-list-blocks_52_tb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 26px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_54_tb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 27px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_p.b-list-blocks_54_tb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 27px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_56_tb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 28px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_p.b-list-blocks_56_tb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 28px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_58_tb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 29px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_p.b-list-blocks_58_tb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 29px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_60_tb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 30px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_p.b-list-blocks_60_tb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 30px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_62_tb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 31px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_p.b-list-blocks_62_tb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 31px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_64_tb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 32px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_p.b-list-blocks_64_tb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 32px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_66_tb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 33px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_p.b-list-blocks_66_tb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 33px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_68_tb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 34px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_p.b-list-blocks_68_tb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 34px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_70_tb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 35px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_p.b-list-blocks_70_tb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 35px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_72_tb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 36px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_p.b-list-blocks_72_tb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 36px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_74_tb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 37px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_p.b-list-blocks_74_tb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 37px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_76_tb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 38px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_p.b-list-blocks_76_tb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 38px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_78_tb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 39px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_p.b-list-blocks_78_tb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 39px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_80_tb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 40px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_p.b-list-blocks_80_tb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 40px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_82_tb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 41px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_p.b-list-blocks_82_tb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 41px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_84_tb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 42px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_p.b-list-blocks_84_tb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 42px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_86_tb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 43px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_p.b-list-blocks_86_tb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 43px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_88_tb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 44px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_p.b-list-blocks_88_tb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 44px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_90_tb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 45px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_p.b-list-blocks_90_tb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 45px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_92_tb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 46px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_p.b-list-blocks_92_tb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 46px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_94_tb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 47px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_p.b-list-blocks_94_tb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 47px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_96_tb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 48px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_p.b-list-blocks_96_tb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 48px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_98_tb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 49px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_p.b-list-blocks_98_tb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 49px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_100_tb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 50px; } }
  @media only screen and (max-width: 1230px) {
    .b-list-blocks_p.b-list-blocks_100_tb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 50px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_2_mb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 1px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_p.b-list-blocks_2_mb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 1px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_4_mb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 2px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_p.b-list-blocks_4_mb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 2px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_6_mb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 3px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_p.b-list-blocks_6_mb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 3px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_8_mb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 4px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_p.b-list-blocks_8_mb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 4px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_10_mb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 5px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_p.b-list-blocks_10_mb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 5px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_12_mb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 6px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_p.b-list-blocks_12_mb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 6px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_14_mb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 7px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_p.b-list-blocks_14_mb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 7px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_16_mb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 8px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_p.b-list-blocks_16_mb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 8px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_18_mb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 9px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_p.b-list-blocks_18_mb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 9px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_20_mb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 10px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_p.b-list-blocks_20_mb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 10px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_22_mb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 11px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_p.b-list-blocks_22_mb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 11px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_24_mb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 12px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_p.b-list-blocks_24_mb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 12px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_26_mb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 13px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_p.b-list-blocks_26_mb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 13px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_28_mb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 14px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_p.b-list-blocks_28_mb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 14px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_30_mb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 15px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_p.b-list-blocks_30_mb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 15px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_32_mb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 16px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_p.b-list-blocks_32_mb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 16px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_34_mb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 17px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_p.b-list-blocks_34_mb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 17px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_36_mb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 18px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_p.b-list-blocks_36_mb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 18px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_38_mb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 19px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_p.b-list-blocks_38_mb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 19px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_40_mb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 20px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_p.b-list-blocks_40_mb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 20px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_42_mb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 21px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_p.b-list-blocks_42_mb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 21px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_44_mb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 22px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_p.b-list-blocks_44_mb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 22px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_46_mb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 23px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_p.b-list-blocks_46_mb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 23px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_48_mb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 24px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_p.b-list-blocks_48_mb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 24px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_50_mb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 25px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_p.b-list-blocks_50_mb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 25px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_52_mb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 26px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_p.b-list-blocks_52_mb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 26px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_54_mb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 27px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_p.b-list-blocks_54_mb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 27px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_56_mb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 28px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_p.b-list-blocks_56_mb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 28px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_58_mb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 29px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_p.b-list-blocks_58_mb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 29px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_60_mb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 30px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_p.b-list-blocks_60_mb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 30px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_62_mb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 31px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_p.b-list-blocks_62_mb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 31px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_64_mb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 32px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_p.b-list-blocks_64_mb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 32px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_66_mb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 33px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_p.b-list-blocks_66_mb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 33px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_68_mb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 34px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_p.b-list-blocks_68_mb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 34px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_70_mb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 35px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_p.b-list-blocks_70_mb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 35px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_72_mb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 36px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_p.b-list-blocks_72_mb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 36px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_74_mb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 37px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_p.b-list-blocks_74_mb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 37px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_76_mb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 38px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_p.b-list-blocks_76_mb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 38px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_78_mb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 39px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_p.b-list-blocks_78_mb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 39px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_80_mb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 40px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_p.b-list-blocks_80_mb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 40px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_82_mb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 41px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_p.b-list-blocks_82_mb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 41px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_84_mb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 42px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_p.b-list-blocks_84_mb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 42px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_86_mb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 43px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_p.b-list-blocks_86_mb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 43px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_88_mb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 44px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_p.b-list-blocks_88_mb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 44px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_90_mb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 45px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_p.b-list-blocks_90_mb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 45px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_92_mb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 46px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_p.b-list-blocks_92_mb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 46px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_94_mb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 47px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_p.b-list-blocks_94_mb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 47px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_96_mb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 48px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_p.b-list-blocks_96_mb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 48px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_98_mb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 49px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_p.b-list-blocks_98_mb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 49px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_100_mb > .b-list-blocks__inner > .b-list-blocks__item {
      margin: 50px; } }
  @media only screen and (max-width: 640px) {
    .b-list-blocks_p.b-list-blocks_100_mb > .b-list-blocks__inner > .b-list-blocks__item {
      padding: 50px; } }

.b-machine-learning {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 260px;
  text-align: right; }
  @media only screen and (max-width: 1250px) {
    .b-machine-learning {
      flex-direction: column-reverse;
      margin-bottom: 110px; } }
  @media only screen and (max-width: 640px) {
    .b-machine-learning {
      margin-bottom: 70px; } }
  .b-machine-learning::before {
    content: '';
    position: absolute;
    top: 28px;
    left: calc( 50% - 716px);
    width: 1421px;
    height: 336px;
    background: url("../../media/img/b-machine-learning/bg-min.png") center/cover no-repeat;
    pointer-events: none; }
    @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
      .b-machine-learning::before {
        background-image: url("../../media/img/b-machine-learning/bg@2x-min.png"); } }
    @media only screen and (max-width: 1250px) {
      .b-machine-learning::before {
        display: none; } }
  .b-machine-learning__device {
    content: '';
    position: absolute;
    z-index: 1;
    top: calc( 50% - 229px);
    left: calc( 50% - 595px);
    width: 630px;
    height: 481px;
    object-fit: cover; }
    @media only screen and (max-width: 1250px) {
      .b-machine-learning__device {
        position: relative;
        top: auto;
        left: auto;
        max-width: 90%;
        height: auto;
        margin: -5px auto -15px; } }
    @media only screen and (max-width: 640px) {
      .b-machine-learning__device {
        margin: 0 auto; } }
  .b-machine-learning__content {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    max-width: 470px;
    margin-left: auto; }
    @media only screen and (max-width: 1250px) {
      .b-machine-learning__content {
        max-width: 570px;
        margin: 0 auto 50px;
        text-align: center; } }

.b-mobile {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 290px;
  text-align: right; }
  @media only screen and (max-width: 1250px) {
    .b-mobile {
      flex-direction: column-reverse;
      margin-bottom: 130px; } }
  @media only screen and (max-width: 640px) {
    .b-mobile {
      margin-bottom: 70px; } }
  .b-mobile::before, .b-mobile::after {
    content: '';
    position: absolute;
    pointer-events: none; }
  .b-mobile::before {
    bottom: -275px;
    left: 50%;
    width: 100%;
    min-width: 1600px;
    height: 1041px;
    background: url("../../media/img/b-mobile/bg-min.png") center/100% 100% no-repeat;
    transform: translateX(-50%); }
    @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
      .b-mobile::before {
        background-image: url("../../media/img/b-mobile/bg@2x-min.png"); } }
  .b-mobile::after {
    top: -97px;
    left: calc( 50% - 707px);
    width: 1414px;
    height: 430px;
    background: url("../../media/img/b-mobile/bg_2-min.png") center/cover no-repeat; }
    @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
      .b-mobile::after {
        background-image: url("../../media/img/b-mobile/bg_2@2x-min.png"); } }
    @media only screen and (max-width: 1250px) {
      .b-mobile::after {
        top: -162px; } }
  .b-mobile__device {
    content: '';
    position: absolute;
    z-index: 1;
    top: calc( 50% - 253px);
    left: calc( 50% - 660px);
    width: 811px;
    height: 584px;
    object-fit: cover; }
    @media only screen and (max-width: 1250px) {
      .b-mobile__device {
        position: relative;
        top: auto;
        left: auto;
        width: 720px;
        max-width: 100%;
        height: auto;
        margin: 0 auto -100px; } }
    @media only screen and (max-width: 640px) {
      .b-mobile__device {
        margin-bottom: -13vw; } }
  .b-mobile__content {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    max-width: 420px;
    margin-left: auto; }
    @media only screen and (max-width: 1250px) {
      .b-mobile__content {
        margin: 0 auto 50px;
        text-align: center; } }

.b-modal {
  position: fixed;
  top: -100%;
  left: -100%;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  transition-duration: 0.2s;
  transition-property: all, opacity;
  transition-timing-function: step-end, ease-out;
  -webkit-overflow-scrolling: touch; }
  .b-modal_active {
    visibility: visible;
    top: 0;
    left: 0;
    opacity: 1;
    transition-timing-function: step-start, ease-out; }
  .b-modal__overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(26, 33, 51, 0.6);
    cursor: pointer; }
  @media only screen and (max-width: 1460px) {
    .b-modal_xl .b-modal__overlay {
      background-color: #fff !important; } }
  @media only screen and (max-width: 1460px) {
    .b-modal_xl .b-modal__overlay.b-modal__close {
      pointer-events: none; } }
  @media only screen and (max-width: 570px) {
    .b-modal_md .b-modal__overlay {
      background-color: #fff !important; } }
  @media only screen and (max-width: 570px) {
    .b-modal_md .b-modal__overlay.b-modal__close {
      pointer-events: none; } }
  @media only screen and (max-width: 445px) {
    .b-modal_sm .b-modal__overlay {
      background-color: #fff !important; } }
  @media only screen and (max-width: 445px) {
    .b-modal_sm .b-modal__overlay.b-modal__close {
      pointer-events: none; } }
  .b-modal__close {
    cursor: pointer; }
  .b-modal__inner {
    position: relative;
    display: table;
    table-layout: fixed;
    width: 100%;
    height: 100%; }
  .b-modal__inner-item {
    display: table-cell;
    vertical-align: middle; }
    .b-modal__inner-item_top {
      vertical-align: top; }
  @media only screen and (max-width: 1460px) {
    .b-modal_xl .b-modal__inner-item {
      vertical-align: top !important; } }
  @media only screen and (max-width: 570px) {
    .b-modal_md .b-modal__inner-item {
      vertical-align: top !important; } }
  @media only screen and (max-width: 640px) {
    .b-modal_sm .b-modal__inner-item {
      vertical-align: top !important; } }
  .b-modal__box-wrapper {
    margin: 0 auto;
    padding: 40px; }
    .b-modal__box-wrapper_without_inside_space_horiz {
      padding-left: 0;
      padding-right: 0; }
  @media only screen and (max-width: 1230px) {
    .b-modal_xl .b-modal__box-wrapper {
      padding: 40px 20px; } }
  @media only screen and (max-width: 1460px) {
    .b-modal_xl .b-modal__box-wrapper {
      padding: 0; } }
  @media only screen and (max-width: 1230px) {
    .b-modal_md .b-modal__box-wrapper {
      padding: 40px 20px; } }
  @media only screen and (max-width: 570px) {
    .b-modal_md .b-modal__box-wrapper {
      padding: 0; } }
  @media only screen and (max-width: 640px) {
    .b-modal_sm .b-modal__box-wrapper {
      padding: 40px 0; } }
  @media only screen and (max-width: 445px) {
    .b-modal_sm .b-modal__box-wrapper {
      padding: 0; } }
  @media only screen and (max-width: 1460px) {
    .b-modal_xl.b-modal_without_header .b-modal__box-wrapper {
      padding-top: 0; } }
  @media only screen and (max-width: 570px) {
    .b-modal_md.b-modal_without_header .b-modal__box-wrapper {
      padding-top: 0; } }
  @media only screen and (max-width: 445px) {
    .b-modal_sm.b-modal_without_header .b-modal__box-wrapper {
      padding-top: 0; } }
  .b-modal__box {
    position: relative;
    z-index: 1; }

.b-services {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: -130px;
  margin-bottom: 180px;
  padding-top: 130px; }
  @media only screen and (max-width: 1250px) {
    .b-services {
      margin-bottom: 60px; } }
  @media only screen and (max-width: 640px) {
    .b-services {
      margin-bottom: 20px; } }
  .b-services_last {
    margin-bottom: 0; }
  .b-services::before, .b-services::after {
    content: '';
    position: absolute;
    pointer-events: none; }
  .b-services::before {
    top: 375px;
    left: 50%;
    width: 100%;
    min-width: 1600px;
    height: 422px;
    background: url("../../media/img/b-services/bg-min.png") center/100% 100% no-repeat;
    transform: translateX(-50%); }
    @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
      .b-services::before {
        background-image: url("../../media/img/b-services/bg@2x-min.png"); } }
  .b-services::after {
    top: 99px;
    left: calc( 50% - 657px);
    width: 1334px;
    height: 559px;
    background: url("../../media/img/b-services/bg_2-min.png") center/cover no-repeat; }
    @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
      .b-services::after {
        background-image: url("../../media/img/b-services/bg_2@2x-min.png"); } }
  .b-services__content {
    position: relative;
    z-index: 1; }
  .b-services__heading {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 80px;
    text-align: center; }
    .b-services__heading::before {
      content: '';
      position: absolute;
      top: -150px;
      right: 0;
      width: 180px;
      height: 360px;
      background: url("../../media/img/b-services/bg.png") center/contain no-repeat; }
      @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
        .b-services__heading::before {
          background-image: url("../../media/img/b-services/bg@2x.png"); } }
  .b-services__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: -15px; }
    @media only screen and (max-width: 1250px) {
      .b-services__list {
        margin: -10px; } }
  .b-services__list-item {
    padding: 15px; }
    @media only screen and (max-width: 1250px) {
      .b-services__list-item {
        padding: 10px; } }
    @media only screen and (max-width: 640px) {
      .b-services__list-item {
        max-width: 100%; } }
  .b-services__box {
    position: relative;
    width: 470px;
    max-width: 100%;
    min-height: 100%;
    border-radius: 40px;
    padding: 45px 20px 40px 70px;
    background: #fff; }
    @media only screen and (max-width: 640px) {
      .b-services__box {
        padding-left: 20px; } }
    .b-services__box::before, .b-services__box::after {
      content: '';
      position: absolute; }
    .b-services__box::before {
      left: 50%;
      bottom: 0;
      z-index: -1;
      width: calc( 100% - 50px);
      height: 250px;
      border-radius: 40px;
      background: #fff;
      box-shadow: 0 40px 70px rgba(10, 36, 102, 0.07);
      transform: translateX(-50%); }
    .b-services__box::after {
      left: 38px;
      top: 27px;
      width: 120px;
      height: 135px;
      background: top right / contain no-repeat; }
      @media only screen and (max-width: 640px) {
        .b-services__box::after {
          left: -12px; } }
    .b-services__box_team::after {
      background-image: url("../../media/img/b-services/icon_1.png"); }
      @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
        .b-services__box_team::after {
          background-image: url("../../media/img/b-services/icon_1@2x.png"); } }
    .b-services__box_design::after {
      background-image: url("../../media/img/b-services/icon_2.png"); }
      @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
        .b-services__box_design::after {
          background-image: url("../../media/img/b-services/icon_2@2x.png"); } }
    .b-services__box_development::after {
      background-image: url("../../media/img/b-services/icon_3.png"); }
      @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
        .b-services__box_development::after {
          background-image: url("../../media/img/b-services/icon_3@2x.png"); } }
    .b-services__box_company::after {
      background-image: url("../../media/img/b-services/icon_4.png"); }
      @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
        .b-services__box_company::after {
          background-image: url("../../media/img/b-services/icon_4@2x.png"); } }
    .b-services__box_growth::after {
      background-image: url("../../media/img/b-services/icon_5.png"); }
      @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
        .b-services__box_growth::after {
          background-image: url("../../media/img/b-services/icon_5@2x.png"); } }
    .b-services__box_applications::after {
      background-image: url("../../media/img/b-services/icon_6.png"); }
      @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
        .b-services__box_applications::after {
          background-image: url("../../media/img/b-services/icon_6@2x.png"); } }
  .b-services__box-heading {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    padding-left: 90px; }
    .b-services__box-heading_last {
      margin-bottom: 0; }
  .b-services__box-icon {
    position: absolute;
    top: 12px;
    left: 10px;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: #fff; }
    .b-services__box-icon::before {
      content: '';
      position: absolute;
      left: -10px;
      bottom: 0;
      width: 55px;
      height: 55px;
      border-radius: 50%;
      background: #fff;
      box-shadow: 0px 40px 60px rgba(10, 36, 102, 0.25); }
    .b-services__box-icon::after {
      position: absolute;
      color: #1A2133; }
    .b-services__box-icon_team::after {
      top: -1px;
      left: 5px;
      font-size: 17px; }
    .b-services__box-icon_design::after {
      top: -6px;
      left: 4px;
      font-size: 27px; }
    .b-services__box-icon_development::after {
      top: 0px;
      left: 4px;
      font-size: 15px; }
    .b-services__box-icon_company::after {
      top: -5px;
      left: 9px;
      font-size: 23px; }
    .b-services__box-icon_growth::after {
      top: -3px;
      left: 7px;
      font-size: 21px; }
    .b-services__box-icon_applications::after {
      top: -3px;
      left: 7px;
      font-size: 21px; }
  .b-services__box-heading-text {
    margin: -8px 0 -9px;
    color: #1A2133;
    font-size: 16px;
    line-height: 28px;
    font-weight: 600;
    letter-spacing: .04em; }
  .b-services__box-text {
    margin: -12px 0;
    color: #1A2133;
    font-size: 15px;
    line-height: 35px;
    letter-spacing: .04em; }

.b-testimonials {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: -130px;
  margin-bottom: 240px;
  padding-top: 130px; }
  @media only screen and (max-width: 1250px) {
    .b-testimonials {
      margin-bottom: 110px; } }
  @media only screen and (max-width: 640px) {
    .b-testimonials {
      margin-bottom: 70px; } }
  .b-testimonials::before, .b-testimonials::after {
    content: '';
    position: absolute;
    pointer-events: none; }
  .b-testimonials::before {
    top: -30px;
    left: 50%;
    width: 100%;
    min-width: 1600px;
    height: 316px;
    background: url("../../media/img/b-testimonials/bg-min.png") center/100% 100% no-repeat;
    transform: translateX(-50%); }
    @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
      .b-testimonials::before {
        background-image: url("../../media/img/b-testimonials/bg@2x-min.png"); } }
  .b-testimonials::after {
    top: 76px;
    left: calc( 50% - 763px);
    width: 1311px;
    height: 647px;
    background: url("../../media/img/b-testimonials/bg_2-min.png") center/cover no-repeat; }
    @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
      .b-testimonials::after {
        background-image: url("../../media/img/b-testimonials/bg_2@2x-min.png"); } }
  .b-testimonials__box {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 440px;
    min-height: 100%;
    border-radius: 30px;
    padding: 30px 30px 20px;
    background: #fff; }
    @media only screen and (max-width: 640px) {
      .b-testimonials__box {
        border-radius: 20px;
        padding: 20px;
        text-align: center; } }
    .b-testimonials__box::before {
      content: '';
      position: absolute;
      left: 30px;
      bottom: 0;
      z-index: -1;
      width: calc( 100% - 60px);
      height: calc( 100% - 30px);
      border-radius: 30px;
      background: #fff;
      box-shadow: 0 20px 80px rgba(10, 36, 102, 0.12); }
  .b-testimonials__box-main {
    flex-grow: 1; }
  .b-testimonials__box-photo {
    width: 100px;
    height: 100px;
    border-radius: 50%; }
  .b-testimonials__box-heading {
    margin: -5px 0 -6px;
    color: #1A2133;
    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
    letter-spacing: .04em; }
  .b-testimonials__box-subheading {
    margin: -6px 0;
    color: #838CA3;
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
    font-style: italic;
    letter-spacing: .02em; }
  .b-testimonials__box-text {
    margin: -8px 0;
    color: #838CA3;
    font-size: 13px;
    line-height: 25px;
    font-weight: 400; }
  .b-testimonials__box-minor-note {
    margin: -6px 0;
    opacity: .5;
    color: #838CA3;
    font-size: 14px;
    line-height: 22px;
    font-weight: 600;
    font-style: italic;
    text-align: right;
    letter-spacing: .02em; }
    @media only screen and (max-width: 640px) {
      .b-testimonials__box-minor-note {
        text-align: center; } }
  .b-testimonials__box-logo {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    min-width: 26px; }
    @media only screen and (max-width: 640px) {
      .b-testimonials__box-logo {
        justify-content: center; } }

.b-text {
  margin: -10px 0 -11px;
  color: #1A2133;
  font-size: 16px;
  line-height: 32px;
  letter-spacing: 0; }
  @media only screen and (max-width: 640px) {
    .b-text {
      margin: -11px 0 -12px;
      font-size: 14px;
      line-height: 34px; } }

.b-about {
	position: relative; // for &::before

	display: flex; // to collapse margins inside
	flex-direction: column;

	margin-top: -130px;
	margin-bottom: 160px;
	padding-top: 130px; // for the anchor

	@include md-desk_sm {
		margin-bottom: 110px; }

	&_last {
		margin-bottom: 0; }

	&::before {
		content: '';

		position: absolute;
		top: 123px;
		left: calc( 50% - 618px );

		width: 38px;
		height: 38px;

		background: url( '#{$img}/b-about/bg.png' ) center / cover no-repeat;

		@include md-retina {
			background-image: url( '#{$img}/b-about/bg@2x.png' ); } }

	&__content {
		position: relative;
		z-index: 1; } // to be over &&::before and &&::after

	&__list {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;

		margin: ( -40px ) ( -$p_horiz-col );

		@include md-desk_sm {
			margin: ( -35px ) ( -$p_horiz-col ); }

		@include md-mobile {
			margin: ( -30px ) ( -$p_horiz-col_tb ); } }

	&__list-item {
		position: relative; // for &__list-icon

		max-width: 470px;

		margin: 40px $p_horiz-col;
		padding-left: 100px;

		@include md-desk_sm {
			margin: 35px $p_horiz-col; }

		@include md-mobile {
			margin: 30px $p_horiz-col_tb;
			padding-left: 95px; }

		&::before {
			content: '';

			position: absolute;
			left: -25px;
			top: -19px;

			width: 120px;
			height: 125px;

			background: top right / contain no-repeat; }

		&_code::before {
			background-image: url( '#{$img}/b-about/icon_1.png' );

			@include md-retina {
				background-image: url( '#{$img}/b-about/icon_1@2x.png' ); } }

		&_test::before {
			background-image: url( '#{$img}/b-about/icon_2.png' );

			@include md-retina {
				background-image: url( '#{$img}/b-about/icon_2@2x.png' ); } }

		&_design::before {
			background-image: url( '#{$img}/b-about/icon_3.png' );

			@include md-retina {
				background-image: url( '#{$img}/b-about/icon_3@2x.png' ); } }

		&_validation::before {
			background-image: url( '#{$img}/b-about/icon_4.png' );

			@include md-retina {
				background-image: url( '#{$img}/b-about/icon_4@2x.png' ); } } }

	&__list-text {
		margin: -12px 0;

		color: $grey-darker;
		font-size: 15px;
		line-height: 35px;
		letter-spacing: .04em; } }

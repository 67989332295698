.i-btn-loading {
	$amin_dur:	.6s;

	z-index: 9;

	display: block; // tag span

	opacity: 0;

	width: 60px;
	height: 56px;

	background: url( '#{$img}/loader_light.svg' ) center / contain no-repeat;

	@include center-tsf();

	transition: opacity $amin_dur 0s;

	&_dark {
		background-image: url( '#{$img}/loader_dark.svg' ); }

	&__bind {
		position: relative;
		transition: color $amin_dur ( $amin_dur / 3 * 2 );

		&_active {
			pointer-events: none;
			color: transparent !important;
			transition-delay: 0s; } }

	&__bind_active & {
		opacity: 1;
		transition-delay: ( $amin_dur / 3 * 2 ); } }

.b-header {
	position: fixed;
	top: 0;
	left: 0;
	z-index: $z_i-b-header;

	display: flex;
	align-items: center;
	justify-content: space-between;

	width: 100%;

	padding: 45px 50px;

	transition: .2s all;

	@include md-tablet {
		padding: 30px 20px; }

	@media only screen and ( max-width: 870px ) {
		justify-content: center;
		padding: 30px 20px; }

	&_active {
		padding: 30px 50px;
		background-color: $white;
		box-shadow: 0 0 50px rgba( $b_sh_c, .12 );

		@include md-tablet {
			padding: 25px 30px; }

		@media only screen and ( max-width: 870px ) {
			padding: 25px 20px; } }

	&__item {

		&_main {

			@media only screen and ( max-width: 870px ) {
				display: none; } } }

	&__logo {
		margin: -8px 0 -9px;

		color: $white;
		font-size: 22px;
		line-height: 32px;
		letter-spacing: .16em;
		font-weight: 800;

		text-transform: uppercase;

		transition: .2s color;

		@media only screen and ( max-width: 870px ) {
			margin: -7px 0 -8px;

			font-size: 18px;
			line-height: 28px;
			letter-spacing: .12em; } }

	&_active &__logo {
		color: $grey-darker; }

	&__nav {
		display: flex;
		align-items: center;
		margin: -15px -25px;

		@include md-tablet {
			margin: -15px; } }

	&__nav-item {
		margin: 15px 25px;

		@include md-tablet {
			margin: 15px; }

		&_btn {
			display: flex; } }

	&__link {
		display: block;

		margin: -3px 0 -4px;

		color: $white;
		font-size: 14px;
		line-height: 17px;
		font-weight: 700;
		letter-spacing: .06em;

		text-transform: uppercase;

		transition: .2s color;

		@include md-tablet {
			margin: -4px 0 -3px;
			font-size: 13px;
			line-height: 16px; } }

	&_active &__link {
		color: $grey-darker; }

	&__btn {
		margin: -21px 0px;

		@include md-tablet {
			width: auto;
			margin: -18px 0px;
			padding: 13px 15px 12px; }

		@media only screen and ( max-width: 870px ) {
			margin: 0; } }

	&_active &__btn {
		background-color: $brand-primary;
		box-shadow: none;
		color: $white;

		&:hover {
			background: darken( $brand-primary, $per-btn_hover ); }

		&:active {
			background: darken( $brand-primary, $per-btn_hover + $per-btn_active ); } } }

.b-collaboration {
	position: relative; // for &::before and &::after

	display: flex; // to collapse margins inside
	flex-direction: column;

	margin-bottom: 260px;

	@include md-desk_sm {
		flex-direction: column-reverse;
		margin-bottom: 110px; }

	@include md-mobile {
		margin-bottom: 70px; }

	&::before,
	&::after {
		content: '';
		position: absolute;
		pointer-events: none; }

	&::before {
		top: -264px;
		left: 50%;

		width: 100%;
		min-width: 1600px;
		height: 422px;

		background: url( '#{$img}/b-collaboration/bg-min.png' ) center / 100% 100% no-repeat;

		transform: translateX( -50% );

		@include md-retina {
			background-image: url( '#{$img}/b-collaboration/bg@2x-min.png' ); } }

	&::after {
		content: '';

		position: absolute;
		top: -93px;
		left: calc( 50% - 688px );

		width: 533px;
		height: 459px;

		background: url( '#{$img}/b-collaboration/bg_2-min.png' ) center / cover no-repeat;

		pointer-events: none;

		@include md-retina {
			background-image: url( '#{$img}/b-collaboration/bg_2@2x-min.png' ); } }

	&__device {
		content: '';

		position: absolute;
		z-index: 1; // to be over &&::before and &&::after

		top: calc( 50% - 269px );
		right: calc( 50% - 630px );

		width: 736px;
		height: 580px;

		object-fit: cover;

		@include md-desk_sm {
			position: relative;
			top: auto;
			right: auto;

			width: 660px;
			max-width: 100%;
			height: auto;

			margin: 0 auto -100px; }

		@include md-mobile {
			margin-bottom: -14.5vw; } }

	&__content {
		position: relative;
		z-index: 1; // to be over &&::before

		display: flex; // to collapse margins inside
		flex-direction: column;

		max-width: 420px;

		@include md-desk_sm {
			max-width: 570px;
			margin: 0 auto 50px;
			text-align: center; } } }

.b-heading {
	margin-bottom: 45px;

	@include md-mobile {
		margin-bottom: 40px; }

	&_with_hr {
		position: relative; // for &::before
		margin-bottom: 40px;
		padding-bottom: 43px;

		@include md-mobile {
			margin-bottom: 35px;
			padding-bottom: 33px; }

		&::before {
			content: '';

			position: absolute;
			left: 0;
			bottom: 0;

			width: 60px;
			height: 3px;

			background: linear-gradient( 90deg, #4460E0, #37A6F1 );

			@include md-desk_sm {
				left: calc( 50% - 30px ); } } }

	&_m_b_md {
		margin-bottom: 60px;

		@include md-mobile {
			margin-bottom: 40px; } }

	&_m_b_lg {
		margin-bottom: 80px;

		@include md-desk_sm {
			margin-bottom: 70px; }

		@include md-mobile {
			margin-bottom: 60px; } }

	&_right {
		text-align: right;

		@include md-desk_sm {
			text-align: center; } }

	&_center {
		text-align: center; }

	&_right#{&}_with_hr::before {
		left: auto;
		right: 0;

		@include md-desk_sm {
			left: calc( 50% - 30px );
			right: auto; } }

	&_center#{&}_with_hr::before {
		left: calc( 50% - 30px ); }

	&__text {
		margin: -14px 0 -13px;

		color: $grey-darker;
		font-size: 44px;
		line-height: 58px;
		font-weight: 800;
		letter-spacing: .06em;

		@include md-desk_sm {
			margin: -13px 0;
			font-size: 40px;
			line-height: 54px; }

		@include md-mobile {
			margin: -7px 0 -8px;

			font-size: 30px;
			line-height: 36px;
			letter-spacing: .04em; } }

	&_light &__text {
		color: $white; } }

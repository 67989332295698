.b-hero {
	position: relative; // for &::before, &::after and &__video
	min-height: 743px;

	margin-bottom: 90px;
	padding: 180px 60px 60px;

	background: linear-gradient(31.76deg, #3B56D7 13.3%, #5B79F5 82.7%);

	text-align: center;

	@include md-desk_sm {
		min-height: 700px;
		margin-bottom: 50px;
		padding: 150px 20px 120px; }

	@include md-mobile {
		display: flex;
		align-items: center;

		min-height: 610px;

		padding: 110px 20px 170px; }

	@media only screen and ( max-width: 360px ) {
		min-height: 660px;
		padding-top: 115px; }

	&::before,
	&::after {
		content: '';
		position: absolute;
		z-index: 1; } // to be above &__video

	&::before {
		top: calc( 100% - 115px );
		left: 50%;

		width: 100%;
		min-width: 1600px;
		height: 271px;

		background: url( '#{$img}/b-hero/bg.png' ) center / 100% 100% no-repeat;

		transform: translateX( -50% );

		@include md-retina {
			background-image: url( '#{$img}/b-hero/bg@2x.png' ); } }

	&::after {
		top: calc( 100% - 52px );
		left: calc( 50% - 310px );

		width: 978px;
		height: 173px;

		background: url( '#{$img}/b-hero/bg_2-min.png' ) center / cover no-repeat;

		@include md-retina {
			background-image: url( '#{$img}/b-hero/bg_2-min@2x.png' ); } }

	&_last {
		margin-bottom: 0; }

	&__content {
		position: relative;
		z-index: 1; // to be over &::before and &::after

		max-width: 680px;

		margin: 0 auto;

		&::before {
			content: '';

			position: absolute;
			top: calc( 100% + 40px );
			left: calc( 50% - 7px );

			width: 14px;
			height: 27px;

			background: url( '#{$img}/b-hero/arrow.svg' ) center / contain no-repeat;

			animation: arrowAnimate 2s infinite;

			@include md-mobile {
				top: calc( 100% + 20px ); } } }

	&__heading {
		margin: -16px 0 30px;

		color: $white;
		font-size: 70px;
		line-height: 80px;
		letter-spacing: .02em;

		@include md-desk_sm {
			margin: -14px 0 26px;
			font-size: 60px;
			line-height: 70px; }

		@include md-mobile {
			margin: -12px 0 24px;
			font-size: 32px;
			line-height: 45px; }

		&_bold {
			font-weight: 600; }

		&_last {
			margin-bottom: -15px;

			@include md-desk_sm {
				margin-bottom: -14px; }

			@include md-mobile {
				margin-bottom: -11px; } } }

	&__text {
		margin: -12px 0 37px;

		color: rgba( $white, .7 );
		font-size: 16px;
		line-height: 36px;
		font-weight: 500;
		letter-spacing: .02em;

		@include md-desk_sm {
			margin-bottom: 32px; }

		@include md-mobile {
			margin: -10px 0 29px;

			font-size: 14px;
			line-height: 32px;
			font-weight: 400;
			letter-spacing: .04em; }

		&_last {
			margin-bottom: -13px;

			@include md-mobile {
				margin-bottom: -11px; } } }

	@keyframes arrowAnimate {

		0% {
			opacity: 0;
			transform: translateY( -10px ); }

		50% {
			opacity: 1; }

		100% {
			opacity: 0;
			transform: translateY( 20px ); } } }

.b-box {
	display: flex; // to collapse margins inside
	flex-direction: column;

	max-width: 100%;

	margin: $m_vert-b-box auto;
	border-radius: $brd_rd-b-box;

	background-color: $white;

	@include md-tablet {
		margin: $m_vert-b-box_tb auto; }

	&_without_outside_space_horiz {
		margin-left: 0;
		margin-right: 0; }

	&_without_outside_space_vert {
		margin-top: 0;
		margin-bottom: 0; }

	&_without_outside_space {
		margin: 0; }

	&_xl {
		width: $w-b-box_xl; }

	&_md {
		width: $w-b-box_md; }

	&_sm {
		width: $w-b-box_sm; }

	&_sm_mb {

		@include md-mobile {
			width: $w-b-box_sm; } }

	@include md-b-box_states( $media: true, $class: ':not(.b-box_with_outside_space_mb)' ) {
		margin: 0; }



	// &__close
	// 	position: absolute
	// 	top: 30px
	// 	right: 30px

	// 	font-size: 0
	// 	line-height: 0

	// 	transform: rotate( 0deg )
	// 	transition: transform .2s

	// 	+md-mobile
	// 		top: 15px
	// 		right: 15px

	// 	@extend .i-cross

	// 	&::after
	// 		color: $grey-dark
	// 		font-size: 28px

	// 		+md-mobile
	// 			font-size: 20px

	// 	&:hover
	// 		transform: rotate( 90deg )



	&__body {
		padding: $p_vert-b-box $p_horiz-b-box;

		@include md-tablet {
			padding: $p_vert-b-box_tb $p_horiz-b-box_tb; }

		@include md-mobile {
			padding: $p_vert-b-box_mb $p_horiz-b-box_mb; }

		&_without_inside_space {
			padding: 0 !important; } } // to set the style for all sizes of the box

	&_xl &__body {

		@include md-b-box_xl {
			padding-left: $p_horiz-b-box_mb;
			padding-right: $p_horiz-b-box_mb; }

		&_without_inside_space_mb {

			@include md-b-box_xl {
				padding: 0; } } }

	&_md &__body {

		@include md-b-box_md {
			padding-left: $p_horiz-b-box_mb;
			padding-right: $p_horiz-b-box_mb; }

		&_without_inside_space_mb {

			@include md-b-box_md {
				padding: 0; } } }

	&_sm &__body {

		@include md-b-box_sm {
			padding-left: $p_horiz-b-box_mb;
			padding-right: $p_horiz-b-box_mb; }

		&_without_inside_space_mb {

			@include md-b-box_sm {
				padding: 0; } } }



	&__body-item {
		margin-bottom: $p_vert-b-box;

		&_md {
			margin-bottom: $p_vert-b-box_md; }

		&_last {
			margin-bottom: 0; }

		&_space {
			padding: $p_vert-b-box $p_horiz-b-box; }

		&_top {
			border-radius: $brd_rd-b-box $brd_rd-b-box 0 0; }

		&_bottom {
			border-radius: 0 0 $brd_rd-b-box $brd_rd-b-box; }

		&_without_inside_space {
			width: calc( 100% + #{$m-col * 2} );
			margin: ( -$p_vert-b-box ) ( -$m-col ); }

		&_without_inside_space_vert {
			margin-top: -$p_vert-b-box;
			margin-bottom: -$p_vert-b-box; }

		&_without_inside_space_top {
			margin-top: -$p_vert-b-box; }

		&_without_inside_space_bottom {
			margin-bottom: -$p_vert-b-box; }

		&_without_inside_space_horiz {
			width: calc( 100% + #{$m-col * 2} );
			margin-left: -$m-col;
			margin-right: -$m-col; } }

	&_xl &__body-item {

		&_space {

			@include md-b-box_xl {
				padding: $p_vert-b-box $p_horiz-b-box_mb; } }

		&_top {

			@include md-b-box_xl {
				border-radius: 0; } }

		&_bottom {

			@include md-b-box_xl {
				border-radius: 0; } }

		&_without_inside_space {

			@include md-b-box_xl {
				margin: $p_vert-b-box $m-col_tb; } }

		&_without_inside_space_top {

			@include md-b-box_xl {
				border-radius: 0; } }

		&_without_inside_space_bottom {

			@include md-b-box_xl {
				border-radius: 0; } }

		&_without_inside_space_horiz {

			@include md-b-box_xl {
				width: calc( 100% + #{$m-col_tb * 2} );
				margin-left: -$m-col_tb;
				margin-right: -$m-col_tb; } } }

	&_md &__body-item {

		&_space {

			@include md-b-box_md {
				padding: $p_vert-b-box $p_horiz-b-box_mb; } }

		&_top {

			@include md-b-box_md {
				border-radius: 0; } }

		&_bottom {

			@include md-b-box_md {
				border-radius: 0; } }

		&_without_inside_space {

			@include md-b-box_md {
				margin: $p_vert-b-box $m-col_tb; } }

		&_without_inside_space_top {

			@include md-b-box_md {
				border-radius: 0; } }

		&_without_inside_space_bottom {

			@include md-b-box_md {
				border-radius: 0; } }

		&_without_inside_space_horiz {

			@include md-b-box_md {
				width: calc( 100% + #{$m-col_tb * 2} );
				margin-left: -$m-col_tb;
				margin-right: -$m-col_tb; } } }

	&_sm &__body-item {

		&_space {

			@include md-b-box_sm {
				padding: $p_vert-b-box $p_horiz-b-box_mb; } }

		&_top {

			@include md-b-box_sm {
				border-radius: 0; } }

		&_bottom {

			@include md-b-box_sm {
				border-radius: 0; } }

		&_without_inside_space {

			@include md-b-box_sm {
				margin: $p_vert-b-box $m-col_tb; } }

		&_without_inside_space_top {

			@include md-b-box_sm {
				border-radius: 0; } }

		&_without_inside_space_bottom {

			@include md-b-box_sm {
				border-radius: 0; } }

		&_without_inside_space_horiz {

			@include md-b-box_sm {
				width: calc( 100% + #{$m-col_tb * 2} );
				margin-left: -$m-col_tb;
				margin-right: -$m-col_tb; } } } }
